import Vue from 'vue'

import { projectName } from '@/utils'

const prefixKey = (serviceName = 'common', key = 'list') => {
  const result = [ projectName, 'sortable', serviceName, key ]

  return result.filter(Boolean).join(':')
}

export default {
  namespaced: true,

  state: {
    list: {}
  },

  mutations: {
    set(state, { serviceName = 'common', key = 'list', items = [] }) {
      if (!state.list[serviceName]) {
        Vue.set(state.list, serviceName, {})
      }
      if (!state.list[serviceName][key]) {
        Vue.set(state.list[serviceName], key, [])
      }
      state.list[serviceName][key].splice(0, state.list[serviceName][key].length, ...items)
    }
  },

  actions: {
    set({ commit }, { serviceName, key, items }) {
      if (Array.isArray(items) && items.length) {
        commit('set', {
          serviceName,
          key,
          items
        })
        window.localStorage.setItem(prefixKey(serviceName, key), JSON.stringify(items))
      }
    },

    get({ commit }, { serviceName, key }) {
      try {
        const list = window.localStorage.getItem(prefixKey(serviceName, key))
        if (list) {
          const items = JSON.parse(list)
          if (items) {
            if (Array.isArray(items) && items.length) {
              commit('set', {
                serviceName,
                key,
                items
              })
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  },

  getters: {
    list: state => state.list
  }
}
