import colors from './colors'
import sendings from './sendings'

export default {
  title: 'Site settings',

  titles: {
    settings: 'Site settings',
    reseller: 'Site settings for reseller',
    resellers: 'Resellers',
    auth: 'Authorization',
    frontend: 'Frontend',
    notifications: 'Notifications',
    payments: 'Payments',
    globalBlacklistManager: 'Global Blacklist',
    apisender: 'API Sender',
    integrations: 'Integrations',
    misc: 'Miscellaneous'
  },

  dialogs: {
    headers: {
      notifications: {
        email: {
          smtp: 'E-mail SMTP settings',
          templates: {
            billings: {
              limit: 'Balance limit reached template'
            },
            auth: {
              registration: 'Email template for new users',
              recover: 'Password recovery email template'
            },
            manager: {
              billings: {
                limit: 'Child user\'s balance limit reached template'
              },
              providers: {
                health: 'Providers health template'
              }
            }
          }
        },
        sms: {
          templates: {
            otp: {
              generate: {
                sendernames: {
                  moderation: 'SMS confirmation template'
                }
              }
            }
          }
        }
      }
    }
  },

  subtitles: {
    resellers: {
      domains: 'Domains'
    },

    auth: {
      registration: {
        defaults: 'Registration defaults'
      }
    },

    frontend: {
      colors: 'Site colors',
      documents: 'Public documents',
      jivosite: 'Jivosite settings'
    },

    notifications: {
      enabled: 'Notifications enabled',
      email: 'E-mail notifications',
      sms: 'SMS notifications',
      events: 'Events',
      extras: 'Extras'
    },

    payments: {
      methods: 'Payments methods'
    },

    apisender: {
      settings: {
        readChatSettings: 'Read chat settings',
        sendMessageSettings: 'Send message settings',
        webhookSettings: 'Webhook settings'
      }
    },

    integrations: {
      yclients: {
        applicationCredentials: 'Application credentials'
      }
    }
  },

  labels: {
    chooseReseller: 'Choose reseller',
    privacyPolicy: 'Privacy Policy',
    publicOffer: 'Public Offer',

    resellers: {
      domains: {
        domain: 'Domain',
        user: 'User'
      }
    },

    auth: {
      registration: {
        defaults: {
          allowDuplicates: 'Allow duplicates',
          sendernamesCheck: 'Strict sender names for selected types',
          patternsCheck: 'Strict patterns for selected types',
          balance: 'Default balance',
          tariffs: 'Default tariff',
          routingGroups: 'Default routing groups',
          messageRuleGroups: 'Default message rule groups',
          sendernames: 'Sendernames',
          patterns: 'Patterns'
        }
      }
    },

    frontend: {
      title: 'Site title',
      colors: colors.labels,
      documents: {
        privacyPolicy: 'Privacy Policy',
        publicOffer: 'Public Offer'
      },
      jivosite: 'Jivosite ID'
    },

    notifications: {
      enabled: {
        types: sendings.types
      },
      email: {
        smtp: {
          host: 'Host',
          port: 'Port',
          from: 'From',
          login: 'Login',
          password: 'Password'
        },
        templates: {
          subject: 'Subject',
          body: 'Body',
          manager: {
            subject: 'Subject',
            body: 'Body'
          }
        }
      },
      sms: {
        sendername: 'Sender name',
        senderUserId: 'Sender',
        templates: {
          subject: 'Тема',
          body: 'Тело шаблона',
          manager: {
            subject: 'Тема',
            body: 'Тело шаблона'
          }
        }
      },
      events: {
        billings: {
          limit: {
            host: 'Host',
            port: 'Port',
            from: 'From',
            login: 'Login',
            password: 'Password',
            limit: 'Limit',
            include: {
              overdraft: 'Including overdraft',
              invoice: 'invoice included'
            },
            notInclude: {
              overdraft: 'Excluding overdraft',
              invoice: ''
            }
          }
        },
        providers: {
          health: {
            recipients: 'Provider health notifications recipients'
          }
        }
      },
      extraRecipients: 'Extra notification recipients'
    },

    payments: {
      allowedGlobalMethods: 'Allowed global payment methods'
    },

    globalBlacklistManager: {
      mainListId: 'Main List',
      temporaryAvailableListId: 'Temporary Available List'
    },

    apisender: {
      userApiKey: 'User API key',
      settings: {
        readChatSettings: {
          eventOnIncomingMessage: 'Event on incoming message'
        },
        sendMessageSettings: {
          delayOnSendMessageInMs: 'Delay on send message in ms'
        },
        webhookSettings: {
          webhookUrl: 'Webhook URL',
          webhookOnNewMessage: 'Webhook on new message',
          webhookOnStatusMessage: 'Webhook on status message',
          webhookOnCheckPhoneState: 'Webhook on check phone state',
          webhookOnChangeStateAccount: 'Webhook on change state account',
          webhookOnIncomingCall: 'Webhook on incoming call',
          webhookOnIncomingVideoCall: 'Webhook on incoming video call',
          webhookOnFailedUserProxy: 'Webhook on failed user proxy'
        }
      }
    },

    integrations: {
      yclients: {
        applicationCredentials: {
          id: 'Application ID',
          token: 'Application token'
        }
      }
    },

    misc: {
      rootId: 'Root ID'
    }
  },

  contents: {
    notifications: {
      events: {
        billings: {
          limit: 'Critical balance notification'
        },
        providers: {
          health: {
            recipients: 'Email template for provider status notification'
          }
        }
      },
      email: {
        templates: {
          title: 'Notification templates',
          billings: {
            limit: 'Email template for balance limit notifications'
          },
          auth: {
            registration: 'Email template for new users',
            recover: 'Password recovery notification template'
          },
          manager: {
            title: 'Notification templates for managers',
            billings: {
              limit: 'Email template for child user\'s balance limit notifications'
            },
            providers: {
              health: 'Providers health'
            }
          }
        }
      },
      sms: {
        templates: {
          title: 'SMS templates',
          otp: {
            generate: {
              sendernames: {
                moderation: 'SMS confirmation template'
              }
            }
          }
        }
      }
    },

    payments: {
      methods: 'Payment methods'
    }
  },

  hints: {
    warning: 'You are editing the site settings of the selected user.',

    frontend: {
      colors: colors.hints,
      documents: {
        privacyPolicy: 'File that will be available for end users to read',
        publicOffer: 'File that will be available for end users to read'
      },
      jivosite: 'Enter your personal Jivosite ID to enable embedded support chat'
    },

    notifications: {
      events: {
        billings: {
          limit: {
            title: 'Notification will be sent when balance will reach {amount}',
            onLimit: 'When balance is',
            noLimit: '<not specified>',
            notifyBy: 'notify via',
            noNotifications: '<no method selected>',
            activator: 'We will send you notification when your balance will reach {limit}',
            overdraft: ' + user overdraft',
            invoice: 'Attach invoice for the amount {amount}'
          }
        }
      },
      email: {
        templates: {
          billings: {
            limit: 'Message that will be sent when one of balance limits is reached'
          },
          auth: {
            registration: 'Message that will be sent when user registered',
            recover: 'Message that will be sent to the user when trying to recover the password'
          },
          manager: {
            billings: {
              limit:
                'Message that will be sent to manager and administrator when one of user\'s balance limits is reached'
            },
            providers: {
              health: 'Message that will be sent to manager when provider disconnects for some reason'
            }
          }
        }
      },
      sms: {
        templates: {
          otp: {
            generate: {
              sendernames: {
                moderation: 'A message will be sent upon SMS confirmation of the senders number'
              }
            }
          }
        }
      },
      extraRecipients: 'Notifications on the balance status of all users of the system'
    },

    payments: {
      methods: 'Setup different payment methods using predefined payment system integrations'
    }
  },

  buttons: {
    notifications: {
      email: {
        smtp: 'SMTP connection'
      },
      events: {
        billings: {
          limit: {
            add: 'Add limit',
            invoice: {
              attach: 'Attach invoice',
              detach: 'Detach invoice',
              attached: 'Invoice will be attached for an amount {amount}'
            }
          }
        }
      }
    }
  },

  misc: {
    globalSettings: 'Global settings'
  },

  snackbars: {
    updated: 'Setting successfully updated.'
  },

  patterns: {
    username: 'Username',
    email: 'Email',
    phone: 'Phone',
    recipient: {
      username: 'Username',
      email: 'Email',
      phone: 'Phone'
    },
    url: 'Url',
    domain: 'Domain',
    title: 'Title'
  }
}
