import { colors, permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import tasksTitle from '@/components/services/tasks/title'
import tasksState from '@/components/services/tasks/state'
import tasksType from '@/components/services/billings/tasks/type'
import tasksFrequency from '@/components/services/billings/tasks/item/frequency'
import tasksRunDelayPause from '@/components/services/billings/tasks/item/runDelayPause'
import tasksActionsMenu from '@/components/services/tasks/actions/menu'
import tasksAmount from '@/components/services/billings/tasks/item/amount'
import owner from '@/components/serviceTemplate/item/owner'
import date from '@/components/serviceTemplate/item/date'

function renderTasksType(h) {
  return h(
    tasksType,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderTasksState(h) {
  return h(
    tasksState,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderTasksAmount(h) {
  return h(
    tasksAmount,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderTasksTitle(h) {
  return h(
    tasksTitle,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderOwner(h) {
  if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderCreator(h, trigger) {
  if (this.viewport.breakpoint.xl || trigger) {
    if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
      if (this.value.CreatorId) {
        return h(
          sticker,
          {
            props: {
              value: this.value.CreatorId,
              label: this.getTranslate(`${services.tasks}.labels.creator`),
              icon: 'person',
              color: colors.grey,
              pathToTitle: 'username'
            }
          }
        )
      }
    }
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.billings}-${services.tasks}-info`,
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderTasksType.call(this, h),
      renderTasksState.call(this, h),
      renderTasksAmount.call(this, h),
      renderTasksTitle.call(this, h),
      renderOwner.call(this, h),
      renderCreator.call(this, h)
    ]
  )
}

function renderTasksFrequency(h) {
  return h(
    tasksFrequency,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderRunDelayPause(h) {
  return h(
    tasksRunDelayPause,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderTasksActionsMenu(h) {
  return h(
    tasksActionsMenu,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderActions(h) {
  return h(
    'div',
    {
      class: `${services.billings}-${services.tasks}-actions`
    },
    [
      renderTasksFrequency.call(this, h),
      renderRunDelayPause.call(this, h),
      renderTasksActionsMenu.call(this, h)
    ]
  )
}
function renderCreatedAt(h) {
  if (this.viewport.size.width > 720) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderPanelHeaderContent(h) {
  return h(
    'div',
    {
      class: `${services.billings}-${services.tasks}-panel__header__content`
    },
    [
      renderTasksType.call(this, h),
      renderTasksState.call(this, h),
      renderTasksAmount.call(this, h)
    ]
  )
}
function renderPanelHeader(h) {
  return h(
    'div',
    {
      class: `${services.billings}-${services.tasks}-panel__header`
    },
    [
      renderPanelHeaderContent.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
function renderPanelContent(h) {
  return h(
    'div',
    {
      class: `${services.billings}-${services.tasks}-panel__content`
    },
    [
      renderTasksTitle.call(this, h),
      renderOwner.call(this, h),
      renderCreator.call(this, h, true),
      renderCreatedAt.call(this, h, true),
      renderActions.call(this, h, true)
    ]
  )
}

export default function(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      'div',
      {
        class: {
          'service-template-panel': true,
          [`${services.billings}-${services.tasks}-item`]: true
        }
      },
      [
        renderInfo.call(this, h),
        renderActions.call(this, h),
        renderCreatedAt.call(this, h)
      ]
    )
  } else {
    return h(
      'g-expansion-panel',
      {
        scopedSlots: {
          header: () => renderPanelHeader.call(this, h),
          default: () => renderPanelContent.call(this, h)
        },
        key: this.value.id
      }
    )
  }
}
