import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/companies/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined,
          $scope: [ 'Owner' ]
        }
      },

      get: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      remove: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        },
        redirect: true
      },

      view: 'panels',
      views: [ 'panels' ],

      has: {
        clone: false,
        view: false
      },

      formPaddles: true,
      width: 500
    })
  ]
}
