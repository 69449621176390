import render from './render'

export default {
  name: 'Panel',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = [ 'auto' ]

      if (this.viewport.breakpoint.lgUp) {
        result.push('1fr', 'auto', 'auto')
      }
      if (this.viewport.breakpoint.mdDown && this.viewport.size.width >= 500) {
        result.push('1fr')
      }

      return result.join(' ')
    }
  },

  render
}
