import { get } from 'lodash'

import messages from '@/messages'

import { isString, isStringNotEmpty } from '../validators'

export function getTranslate(path, options = {}) {
  if (isStringNotEmpty(path)) {
    let result = get(messages, [ options.locale || 'en', path ].join('.'))

    if (!isString(result)) {
      console.trace(`[getTranslate] Translation must be a string - ${path}`) // eslint-disable-line

      return path
    }

    if (result === '') {
      return result
    }

    if (isStringNotEmpty(result)) {
      if (options) {
        Object.keys(options).forEach(key => {
          result = result.replace(`\{${key}\}`, options[key]) // eslint-disable-line
        })
      }

      return result
    } else {
      console.trace(`[getTranslate] No translation found for this path - ${path}`) // eslint-disable-line

      return path
    }
  }
}

export default { getTranslate }
