import { serviceName } from '@/components/services/sendernames/utils'

import render from './render'

export default {
  name: 'Info',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = [ '38px', '200px' ]

      if (this.checkPermissions(`advanced.${serviceName}.get`) && this.viewport.breakpoint.smUp) {
        result.push('150px')
      }

      return result.join(' ')
    }
  },

  render
}
