import { PROVIDER_PRICES_MULTIPLIER } from '@sigma-legacy-libs/essentials/lib/constants'

import { getLocaleDateString, getLocaleTimeString, states } from '@/utils'

import ConfirmRemoveButton from '@/components/misc/ConfirmRemoveButton'

import providersPricesImport from './import'

const serviceName = 'providersPrices'

const _ = { get: require('lodash/get') }

const subtract = (a, b, multiplier = PROVIDER_PRICES_MULTIPLIER) => {
  a = Math.round(a * multiplier)
  b = Math.round(b * multiplier)

  let result = (a - b) / multiplier
  if (result > 0) {
    result = '+' + result
  }

  return result
}

function renderState(h) {
  if (this.restData[serviceName].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'faic fjcc pa-5'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  } else {
    return h('g-empty')
  }
}

function renderVersion(h, version) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        rounded: true,
        outline: this.version === version,
        color: this.version === version ? 'primary' : undefined
      },
      on: {
        click: () => {
          this.getVersion(version)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic'
        },
        [
          h(
            'div',
            {
              class: 'fc fjcfe'
            },
            [
              h('span', { class: 'caption' }, getLocaleDateString(version)),
              h('span', { class: 'tiny text--grey' }, getLocaleTimeString(version, { milliseconds: true }))
            ]
          )
        ]
      )
    ]
  )
}

function renderSelectSlot(h, item) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [
      h('div', { class: 'caption' }, getLocaleDateString(item.value)),
      h('div', { class: 'tiny text--grey' }, getLocaleTimeString(item.value, { milliseconds: true }))
    ]
  )
}

function renderVersions(h) {
  if (Array.isArray(this.versions) && this.versions.length) {
    if (this.viewport.breakpoint.mdUp) {
      return this.versions.map(version => {
        return renderVersion.call(this, h, version)
      })
    } else {
      return h(
        'g-select',
        {
          props: {
            value: this.version,
            label: this.getTranslate(`${serviceName}.labels.version`),
            items: this.versions,
            mode: 'outline',
            rounded: true,
            dense: true,
            details: false
          },
          on: {
            input: event => {
              this.version = event
            }
          },
          scopedSlots: {
            selection: ({ item }) => renderSelectSlot.call(this, h, item),
            item: ({ item }) => renderSelectSlot.call(this, h, item)
          }
        }
      )
    }
  }
}

function renderRefreshButton(h) {
  return h(
    'g-button',
    {
      class: 'ml-0',
      props: {
        flat: true,
        icon: 'refresh'
      },
      on: {
        click: () => {
          this.getVersions()
        }
      }
    }
  )
}
function renderRemoveButton(h) {
  if (this.version) {
    return h(
      ConfirmRemoveButton,
      {
        props: {
          flat: true,
          icon: 'remove',
          color: 'error',
          label: this.getTranslate('misc.buttons.remove'),
          callback: () => this.removeVersion()
        }
      }
    )
  }
}

function renderHeader(h, ProviderId) {
  return h(
    'div',
    {
      class: 'faic',
      style: { 'min-height': '54px' }
    },
    [
      h(
        providersPricesImport,
        {
          props: {
            ProviderId,
            disabled: this.versions.length >= 3
          },
          on: {
            finish: () => {
              this.getVersions()
            }
          }
        }
      ),

      renderVersions.call(this, h),

      h('div', { class: 'ff' }),

      renderRemoveButton.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}

function renderFilter(h) {
  return h(
    'div',
    {
      class: 'faic pa-2'
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.restData[serviceName].find.filter.$search,
            label: this.getTranslate('commons.labels.search'),
            suffix: this.restData[serviceName].find.pagination.total,
            loading: this.restData[serviceName].find.state === states.loading,
            mode: 'outline',
            rounded: true,
            dense: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.restData[serviceName].find.filter.$search = event
            }
          }
        }
      )
    ]
  )
}

function renderTdPrice(h, { id, price, previousPrice }) {
  const directives = []
  if (previousPrice && price !== previousPrice) {
    directives.push({
      name: 'g-tooltip',
      options: {
        value: `${subtract(price, previousPrice)}`,
        placement: 'left'
      }
    })
  }

  return h(
    'td',
    {
      class: {
        'providers-prices__cell providers-prices__cell--price': true,
        'providers-prices__cell--price-up': previousPrice && price > previousPrice,
        'providers-prices__cell--price-down': previousPrice && price < previousPrice
      },
      key: id
    },
    [
      h(
        'div',
        {
          class: 'fjcfe'
        },
        [ h('div', { directives }, price) ]
      )
    ]
  )
}

function renderTd(h, content, path) {
  return h(
    'td',
    {
      class: `providers-prices__cell providers-prices__cell--${path}`
    },
    _.get(content, path)
  )
}

function renderPrice(h, price) {
  return h(
    'tr',
    {
      class: 'providers-prices__row'
    },
    [
      renderTd.call(this, h, price, 'mcc'),
      renderTd.call(this, h, price, 'mnc'),
      renderTd.call(this, h, price, 'country'),
      renderTd.call(this, h, price, 'operator'),
      renderTdPrice.call(this, h, price)
    ]
  )
}

function renderPrices(h) {
  if (Array.isArray(this.restData[serviceName].find.data)) {
    if (this.restData[serviceName].find.data.length) {
      return h(
        'div',
        {
          class: 'providers-prices__list',
          on: { scroll: this.onScroll },
          ref: 'list'
        },
        [
          h(
            'table',
            {
              class: 'providers-prices__table'
            },
            this.restData[serviceName].find.data.map(price => {
              return renderPrice.call(this, h, price)
            })
          )
        ]
      )
    } else {
      return renderState.call(this, h)
    }
  }
}

function renderContent(h, ProviderId) {
  return h(
    'div',
    {
      class: 'providers-prices'
    },
    [
      renderHeader.call(this, h, ProviderId),
      h('g-divider'),
      renderFilter.call(this, h),
      renderPrices.call(this, h)
    ]
  )
}

export default function(h, ProviderId) {
  return renderContent.call(this, h, ProviderId)
}
