import { RECURRENT_PAYMENTS_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, getPrice, icons, modes, permissionPresets, services } from '@/utils'
import { recurrentPaymentIcons, recurrentPaymentsStatusColors } from '@/components/services/recurrentPayments/utils'

import date from '@/components/serviceTemplate/item/date'
import sticker from '@/components/misc/sticker'
import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import button from '@/components/button'

function renderSumIcon(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    squircle,
    {
      props: { color: colors.grey },
      slot: 'icon'
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: this.globalSettings.billings.currency,
            color: colors.grey
          }
        }
      )
    ]
  )
}
function renderAmount(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount),
        label: this.viewport.breakpoint.xs ? this.value.Owner.username : this.getTranslate(`${services.recurrentPayments}.labels.amount`),
        borderStyle: 'dashed'
      }
    },
    [ renderSumIcon.call(this, h) ]
  )
}

function renderPeriod(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      sticker,
      {
        props: {
          value: this.result,
          label: this.getTranslate(`${services.recurrentPayments}.labels.type`),
          borderStyle: 'dashed'
        }
      }
    )
  }
}

function renderStatus(h) {
  return h(
    sticker,
    {
      props: {
        value: this.viewport.breakpoint.xs ? undefined : this.status,
        label: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.recurrentPayments}.labels.status`),
        icon: recurrentPaymentIcons.status[this.value.status],
        iconTooltip: this.status,
        color: recurrentPaymentsStatusColors[this.value.status],
        borderStyle: 'dashed'
      }
    }
  )
}

function renderOwner(h) {
  if (this.viewport.breakpoint.smUp && this.isPage) {
    if (this.checkPermissions('advanced.users.get', permissionPresets.true)) {
      return h(
        sticker,
        {
          props: {
            value: this.value.Owner,
            label: this.getTranslate(`${services.recurrentPayments}.labels.owner`),
            pathToTitle: 'username',
            icon: 'person',
            color: colors.grey
          }
        }
      )
    }
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: {
        [`${services.recurrentPayments}__panel-info`]: true,
        [`${services.recurrentPayments}__panel-info--owner`]: !!this.isPage
      }
    },
    [
      renderStatus.call(this, h),
      renderAmount.call(this, h),
      renderPeriod.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderListItem(h, { show = true, permission, accept = permissionPresets.meUp, validation, callback, icon, color, pathToTranslate }) {
  if (this.checkPermissions(permission, accept)) {
    if (show) {
      if (validation) {
        return h(
          'g-list-item',
          {
            props: {
              icon,
              color,
              label: this.getTranslate(pathToTranslate),
              dense: true
            },
            on: {
              click: callback
            }
          }
        )
      }
    }
  }
}

function renderMenu(h) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.update`, permissionPresets.meUp)) {
    return h(
      'g-menu',
      {
        class: { 'hover-child': this.viewport.breakpoint.mdUp },
        props: {
          rounded: true,
          placement: 'bottom-end',
          offsetDistance: 8
        }
      },
      [
        h(
          button,
          {
            props: {
              icon: icons.more_vert,
              mode: modes.flat
            },
            slot: 'activator'
          }
        ),

        h(
          'g-list',
          {
            props: {
              rounded: true,
              dense: true
            }
          },
          [
            renderListItem.call(this, h, {
              show: this.value.OwnerId === this.account.id,
              permission: `advanced.${services.recurrentPayments}.update`,
              validation: ~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status),
              callback: this.repeatRecurrentPayment,
              icon: 'verified_user',
              color: 'success',
              pathToTranslate: `${services.recurrentPayments}.buttons.confirm`
            }),
            renderListItem.call(this, h, {
              permission: 'advanced.payments.get',
              validation: true,
              callback: () => {
                this.$emit('payments', this.value.id)
              },
              icon: 'history',
              pathToTranslate: `${services.recurrentPayments}.buttons.payments`
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${services.recurrentPayments}.update`,
              validation: !~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status),
              callback: this.activateRecurrentPayment,
              icon: this.isPaused ? 'play_arrow' : 'pause',
              color: 'primary',
              pathToTranslate: `${services.recurrentPayments}.buttons.${this.isPaused ? 'resume' : 'pause'}`
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${services.recurrentPayments}.update`,
              validation: this.value.status === RECURRENT_PAYMENTS_STATUSES.block,
              callback: () => {
                this.$emit('unlockRecurrentPayment', this.value.id)
              },
              icon: 'done',
              color: 'success',
              pathToTranslate: 'misc.buttons.unlock'
            }),
            renderListItem.call(this, h, {
              permission: `advanced.${services.recurrentPayments}.update`,
              validation: true,
              callback: () => {
                this.$emit('removeRecurrentPayment', this.value.id)
              },
              icon: 'delete',
              color: 'error',
              pathToTranslate: 'misc.buttons.remove'
            })
          ]
        )
      ]
    )
  }
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        ['service-template-panel']: true,
        [`${services.recurrentPayments}__panel`]: true,
        ['hover-parent hover-parent--opacity']: this.viewport.breakpoint.mdUp
      },
      key: this.value.id
    },
    [
      renderInfo.call(this, h),
      renderMenu.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
