export default {
  title: 'Sender names',
  sendername: 'Sender name',

  titles: {
    new: 'New sender name',
    create: 'Create new sender name',
    users: 'Users',
    comments: 'Comments'
  },

  headers: {
    moderation: 'Moderation',
    sendername: 'Sender name',
    comment: 'Comment'
  },

  labels: {
    name: 'Sender name',
    type: 'Type',
    moderation: 'Moderation',
    owner: 'Owner',
    isActive: 'Activate or deactivate sender name',
    meta: {
      comment: 'Organization and TIN',
      adminComment: 'Administrator comment',
      adminCommentPrivate: 'Private administrator comment',
      resellerComment: 'Reseller comment',
      resellerCommentPrivate: 'Private reseller comment'
    },
    confirmationCode: 'Enter code',
    searchAndAdd: 'Search and add'
  },

  statuses: {
    disabled: 'Not requested',
    requested: 'Requested',
    moderation: 'Moderation',
    approved: 'Approved',
    rejected: 'Rejected',
    pending: 'Pending'
  },

  contents: {
    confirm: {
      remove: 'Delete sender name?'
    }
  },

  hints: {
    meta: {
      comment: 'Minimum 10, maximum 150 characters'
    },
    emptyState: 'No one sender name found',
    confirmationCode: {
      again: 'Request code again',
      timer: 'Repeated request of a code is possible through: {count} sec'
    },
    enterAndAdd: 'Enter the sender names and press ENTER to add it',
    notFound: 'Sender names not found'
  },

  warnings: {
    meta: {
      comment: 'To successfully pass moderation, you need to enter the name of your company name and TIN in the field above.'
    },

    name: {
      sms: 'The name must satisfy the following conditions:<br>- length from 4 to 11 characters,<br>- lowercase or uppercase letters of the latin alphabet [a-z, A-Z],<br>- numbers [0 to 9],<br>- special symbols [!, ?, -, _, .],<br>- round brackets [( )],<br>- must not contain spaces.',
      voice: 'The name must satisfy the following conditions:<br>- numbers only,<br>- from 6 to 15 characters'
    },

    no: {
      names: 'No approved sender names found.<br>You can create a new one by clicking on the "+" button on the right.'
    }
  },

  examples: {
    meta: {
      comment: 'Fill example: LLC "Organization" TIN 1234567890'
    }
  },

  tooltips: {
    create: 'Create new sender name',
    favorites: 'Make the sender name a favorite',
    edit: 'Edit sender name',
    filter: {
      isActive: 'Show deactivated sender names'
    },
    copy: 'Copy name to buffer',
    approve: 'Approve',
    rejectForReason: 'Reject for reason...',
    reject: 'Reject'
  },

  errors: {
    sms: 'Only latin characters or digits allowed, 11 symbols maximum',
    voice: 'Numbers only, 6 to 15 characters'
  },

  buttons: {
    users: 'Users {count}',
    confirmationCode: 'Get Verification Code',
    approve: 'Approve',
    reject: 'Reject',
    rejectForReason: 'Reject for reason',
    copy: 'Copy name',
    favorites: {
      add: 'Add to favorites',
      remove: 'Remove from favorites'
    },
    edit: 'Edit'
  },

  variants: {
    contact: 'Contact us',
    comment: 'Wrong comment',
    length: 'Wrong length',
    common: 'Common name',
    international: 'International name'
  },

  pills: {
    all: 'Available',
    checked: 'Checked'
  },

  snackbars: {
    created: 'Sender name successfully created.',
    updated: 'Sender name successfully updated.',
    removed: 'Sender name successfully removed.',
    confirmationCode: {
      rateLimit: 'Request code only once per minute',
      invalidCode: 'Invalid code',
      success: 'Verification was successful'
    }
  }
}
