import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'TokensDialogs',

  mixins: [
    generateServices({
      name: services.tokens,

      find: false
    })
  ],

  props: {
    id: String,
    type: String,

    show: {
      type: Boolean,
      default: false
    },

    OwnerId: String

  },

  watch: {
    id() {
      this.get()
    }
  },

  mounted() {
    this.get()
  },

  methods: {
    async get() {
      if (this.checkPermissions(`advanced.${services.tokens}.get`, [ 'me', 'reseller', true ])) {
        if (this.id) {
          try {
            await this.rest[services.tokens].get(this.id)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async create() {
      if (this.checkPermissions(`advanced.${services.tokens}.create`, [ 'me', 'reseller', true ])) {
        if (this.restData[services.tokens].create.isValid) {
          try {
            const result = await this.rest[services.tokens].create(Object.assign({ OwnerId: this.OwnerId }, this.restData[services.tokens].create.data))
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async update() {
      if (this.checkPermissions(`advanced.${services.tokens}.update`, [ 'me', 'reseller', true ])) {
        if (this.restData[services.tokens].update.isValid) {
          try {
            const result = await this.rest[services.tokens].update(this.restData[services.tokens].get.data.id, this.restData[services.tokens].get.data)
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async remove() {
      if (this.checkPermissions(`advanced.${services.tokens}.remove`, [ 'me', 'reseller', true ])) {
        if (this.id) {
          try {
            const result = await this.rest[services.tokens].remove(this.id)
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
