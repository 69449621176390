import { CONTACTLIST_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { modes, permissionPresets, services, states } from '@/utils'

import SpacerCell from '@/components/misc/SpacerCell'
import SingleLink from '@/components/misc/SingleLink'
import FileManager from '@/components/file/manager'
import RefreshButton from '@/components/button/refresh'
import ContactsItem from '@/components/services/contacts/item'
import ContactsFilter from '@/components/services/contacts/filter'
import ContactsDialogs from '@/components/services/contacts/dialogs'
import pagination from '@/components/misc/pagination'
import eventsHistoryButton from '@/components/services/eventsHistory/button'
import button from '@/components/button'

function renderDivider(h, trigger) {
  if (trigger) {
    return h('g-divider')
  }

  return h('div')
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderSearchGenerator(h) {
  return h(
    'search-generator',
    {
      scopedSlots: {
        create: () => {
          return h(
            'g-list-item',
            {
              props: {
                color: 'secondary',
                label: this.getTranslate('contactLists.buttons.create'),
                dense: true
              },
              on: {
                click: () => {
                  this.dialogType = 'contactLists.create'
                  this.showDialog = true
                }
              }
            }
          )
        },
        filter: ({ filter }) => {
          return h(
            'g-select',
            {
              props: {
                value: filter.type,
                label: this.getTranslate('contactLists.labels.type'),
                items: CONTACTLIST_TYPES.map(type => {
                  return {
                    title: this.getTranslate(`contactLists.types.${type}`),
                    value: type
                  }
                }),
                clearable: true,
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false
              },
              on: {
                input: event => {
                  filter.type = event
                }
              }
            }
          )
        },
        default: ({ item }) => {
          return h(
            'g-list-item',
            {
              props: {
                active: item.id === this.$route.params.id,
                suffix: item.type === 'blacklist' ? 'not_interested' : undefined,
                to: {
                  name: `${services.contacts}.single`,
                  params: { id: item.id }
                }
              }
            },
            [
              h(
                'div',
                {
                  class: 'fc'
                },
                [
                  h(SpacerCell, { props: { value: item.title } }),

                  h(
                    SingleLink,
                    {
                      class: 'text--grey caption',
                      props: {
                        value: item.Owner,
                        type: 'text'
                      }
                    }
                  )
                ]
              )
            ]
          )
        }
      }
    }
  )
}

function renderEventsHistoryButton(h) {
  if (this.checkPermissions('advanced.apisender.get', permissionPresets.true)) {
    return h(
      eventsHistoryButton,
      {
        props: {
          label: this.viewport.breakpoint.mdUp ? true : false,
          entityId: this.$route.params.id,
          rounded: true
        }
      }
    )
  }
}
function renderRemoveContactListButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('contactLists.buttons.remove') : undefined,
        icon: 'delete',
        color: 'error',
        mode: modes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate('contactLists.tooltips.remove') }
        }
      ],
      on: {
        click: () => {
          this.contactListId = this.restData.contactLists.get.data.id
          this.dialogType = 'contactLists.remove'
          this.showDialog = true
        }
      }
    }
  )
}
function renderAddContactButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate(`${services.contacts}.buttons.add`) : undefined,
        icon: 'exposure_plus_1',
        color: 'primary',
        mode: modes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate(`${services.contacts}.tooltips.add`) }
        }
      ],
      on: {
        click: () => {
          this.contactListId = this.restData.contactLists.get.data.id
          this.dialogType = `${services.contacts}.create`
          this.showDialog = true
        }
      }
    }
  )
}
function renderImportContactsButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.import') : undefined,
        icon: 'cloud_upload',
        mode: modes.flat
      },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.isActive || this.getTranslate('contactLists.tooltips.import') }
        }
      ],
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}
function renderFilterButton(h) {
  return h(
    button,
    {
      props: {
        label: this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.filter') : undefined,
        icon: 'filter_list',
        mode: modes.flat
      },
      on: {
        click: () => {
          this.showFilter = !this.showFilter
        }
      }
    }
  )
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': 'repeat(5, auto)' }
    },
    [
      renderEventsHistoryButton.call(this, h),
      renderRemoveContactListButton.call(this, h),
      renderAddContactButton.call(this, h),
      renderImportContactsButton.call(this, h),
      renderFilterButton.call(this, h)
    ]
  )
}

function renderContactListsTitle(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': 'auto 1fr' }
    },
    [
      h(
        button,
        {
          props: {
            icon: 'edit',
            mode: modes.flat
          },
          on: {
            click: () => {
              this.contactListId = this.restData.contactLists.get.data.id
              this.dialogType = 'contactLists.update'
              this.showDialog = true
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--0'
        },
        [
          h(
            'div',
            {
              class: 'subtitle-1'
            },
            this.restData.contactLists.get.data.title
          ),

          h(
            'div',
            {
              class: 'text--grey caption'
            },
            this.getTranslate(`contactLists.types.${this.restData.contactLists.get.data.type}`)
          )
        ]
      )
    ]
  )
}
function renderContactListsContent(h) {
  if (this.restData.contactLists.get.state === states.ready) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': '1fr auto' }
      },
      [
        renderContactListsTitle.call(this, h),
        renderButtons.call(this, h)
      ]
    )
  } else if (this.restData.contactLists.get.state === states.loading) {
    return renderPreloader.call(this, h)
  }
}
function renderContactListsFilter(h) {
  if (this.showFilter) {
    return h(
      ContactsFilter,
      {
        props: {
          value: this.restData[services.contacts].find.filter
        },
        on: {
          input: event => {
            this.restData[services.contacts].find.filter = event
          }
        }
      }
    )
  }
}
function renderContactsListByViewport(h) {
  const contactsEvents = [ `${services.contacts}.update`, `${services.contacts}.remove` ]
  const on = contactsEvents.reduce((result, event) => {
    result[event] = e => {
      this.contactId = e
      this.dialogType = event
      this.showDialog = true
    }

    return result
  }, {})
  switch (true) {
    case this.viewport.breakpoint.smDown: {
      return h(
        'g-expansion-panels',
        {
          props: { flat: true }
        },
        this.restData[services.contacts].find.data.map(item => {
          return h(
            ContactsItem,
            {
              props: { value: item },
              on
            }
          )
        })
      )
    }

    case this.viewport.breakpoint.mdUp:
    default: {
      return h(
        'g-table',
        {
          props: {
            headers: this.headers,
            hideHead: this.restData[services.contacts].find.pagination.total < 1 || this.viewport.breakpoint.smDown,
            items: this.restData[services.contacts].find.data,
            value: this.restData[services.contacts].find.order,
            noDataText: this.getTranslate('misc.no.data.text')
          },
          on: {
            input: event => {
              this.restData[services.contacts].find.order = event
            }
          },
          scopedSlots: {
            items: ({ item }) => {
              return h(
                ContactsItem,
                {
                  props: { value: item },
                  on
                }
              )
            }
          }
        }
      )
    }
  }
}
function renderContactsList(h) {
  if (this.restData[services.contacts].find.state === states.ready) {
    if (this.restData[services.contacts].find.pagination.total > 0) {
      return renderContactsListByViewport.call(this, h)
    } else {
      return h('g-empty')
    }
  } else if (this.restData[services.contacts].find.state === states.loading) {
    return renderPreloader.call(this, h)
  }
}
function renderPagination(h) {
  const columns = []
  if (this.restData[services.contacts].find.pagination.total > 0) {
    columns.push('auto')
  }
  columns.push('38px')

  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcfe faic pa-2',
      style: { 'grid-template-columns': columns.join(' ') }
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.contacts].find.pagination,
            service: services.contacts
          },
          on: {
            input: event => {
              this.restData[services.contacts].find.pagination = event
            }
          }
        }
      ),

      h(
        RefreshButton,
        {
          props: {
            state: this.restData[services.contacts].find.state,
            shortClickMethod: () => this.rest[services.contacts].find(),
            longClickMethod: () => this.rest[services.contacts].find({}, { noCache: true })
          }
        }
      )
    ]
  )
}
function renderContactsContent(h) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDivider.call(this, h, this.restData[services.contacts].find.pagination.total),
      renderContactsList.call(this, h),
      renderDivider.call(this, h, this.restData[services.contacts].find.pagination.total),
      renderPagination.call(this, h)
    ]
  )
}
function renderContent(h) {
  if (this.$route.params.id) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 ff'
      },
      [
        renderContactListsContent.call(this, h),
        renderContactListsFilter.call(this, h),
        renderContactsContent.call(this, h)
      ]
    )
  }

  return h('div')
}

function renderContactsDialogs(h) {
  return h(
    ContactsDialogs,
    {
      props: {
        id: this.contactId || this.contactListId,
        type: this.dialogType,
        show: this.showDialog,
        ListId: this.restData.contactLists.get.data && this.restData.contactLists.get.data.id
      },
      on: {
        show: event => {
          if (event === false) {
            this.contactId = undefined
            this.contactListId = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    }
  )
}
function renderImportDialogBody(h) {
  switch (this.importContactsResult.state) {
    case 'loading': {
      return [
        h(
          'g-progress',
          {
            class: 'mr-3',
            props: {
              indeterminate: true,
              size: 24
            }
          }
        ),

        this.getTranslate(`${services.contacts}.contents.import.loading`)
      ]
    }
    case 'success': {
      return [
        h(
          'g-icon',
          {
            class: 'mr-3',
            props: {
              value: 'done',
              color: 'success'
            }
          }
        ),

        h(
          'div',
          {
            domProps: { innerHTML: this.getTranslate(`${services.contacts}.contents.import.success`, { count: this.importContactsResult.total }) }
          }
        )
      ]
    }
    case 'error': {
      return [
        h(
          'g-icon',
          {
            class: 'mr-3',
            props: {
              value: 'info',
              color: 'warning'
            }
          }
        ),

        h(
          'div',
          { domProps: { innerHTML: this.getTranslate(`${services.contacts}.contents.import.error`) } }
        )
      ]
    }
  }
}
function renderImportDialogFooter(h) {
  switch (this.importContactsResult.state) {
    case 'success':
    case 'error': {
      return h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showImportContacts = false
                  this.importContactsResult.state = undefined
                  this.importContactsResult.total = 0
                }
              }
            }
          )
        ]
      )
    }
  }
}
function renderImportDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showImportContacts,
        closeOnClick: false,
        closeOnEsc: false,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showImportContacts = event
        }
      }
    },
    [
      h('div', { class: 'faic pa-3' }, renderImportDialogBody.call(this, h)),

      renderImportDialogFooter.call(this, h)
    ]
  )
}

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        show: this.showFileManager,
        flat: true,
        readonly: true,
        embedded: true,
        types: [ 'csv', 'xls', 'xlsx' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.showFileManager = false
          this.showImportContacts = true
          this.importContacts(event.id)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'faifs'
    },
    [
      renderSearchGenerator.call(this, h),
      renderContent.call(this, h),
      renderContactsDialogs.call(this, h),
      renderImportDialog.call(this, h),
      renderFileManager.call(this, h)
    ]
  )
}
