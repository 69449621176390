export default {
  title: 'Get report',

  labels: {
    encoding: 'Encoding',
    delimiter: 'Delimiter',
    includeRefunded: 'Include refunded'
  },

  warnings: {
    beforeRequest: '<b>For report generation, it takes from 10 to 60 minutes.</b>',
    repeatRequest: 'Repeating the request for the same report increases the time for generating.',
    afterRequest: 'After clicking the "Request report" button, the report will continue to be generated on our server. The report will be available after it is generated in the Files section.'
  },

  snackbars: {
    requested: 'Report successfully requested',
    already: {
      requested: 'The report has already been requested. You need to wait until it is generated and will be available in the Files section.'
    }
  }
}
