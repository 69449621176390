import { services } from '@/utils'

import rulesActions from '@/components/misc/rules/actions'
import rulesConditions from '@/components/misc/rules/conditions'
import title from '@/components/services/routes/form/title'

function renderTitleField(h) {
  return h(
    title,
    {
      props: {
        value: this.proxy,
        serviceName: services.messageRules
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderConditions(h) {
  return h(
    rulesConditions,
    {
      props: {
        value: this.proxy.conditions,
        blacklist: [ 'type', 'group' ]
      },
      on: {
        input: event => {
          this.proxy.conditions = event
        }
      }
    }
  )
}

function renderActions(h) {
  return h(
    rulesActions,
    {
      props: {
        value: this.proxy.actions
      },
      on: {
        input: event => {
          this.proxy.actions = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' }
    },
    [
      renderTitleField.call(this, h),
      renderConditions.call(this, h),
      renderActions.call(this, h)
    ]
  )
}
