import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, generateServices, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'UsersTokens',

  mixins: [
    generateServices({
      name: services.tokens,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: permissionPresets.resellerUp,
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined
        }
      },

      get: false,
      create: false,

      update: { manipulateData: false },
      remove: {
        manipulateData: false,
        redirect: false
      }
    })
  ],

  props: {
    OwnerId: String
  },

  data() {
    return {
      tokenId: undefined,
      dialogType: undefined,

      showDialog: false
    }
  },

  mounted() {
    this.restData[services.tokens].find.filter.OwnerId = this.OwnerId
  },

  render
}
