let interval

export default {
  namespaced: true,

  state: {
    paymentStep: undefined,
    date: new Date(),
    findSendingFromRouteParams: false,
    userAgent: navigator.userAgent,
    browser: {
      name: undefined,
      version: undefined
    }
  },

  mutations: {
    setPaymentStep(state, paymentStep) {
      state.paymentStep = paymentStep
    },

    setDate(state, date) {
      state.date = date
    },

    setFindSendingFromRouteParams(state, findSendingFromRouteParams) {
      state.findSendingFromRouteParams = findSendingFromRouteParams
    },

    setBrowser(state, browser) {
      state.browser = browser
    }
  },

  actions: {
    setPaymentStep({ commit }, paymentStep) {
      commit('setPaymentStep', paymentStep)
    },

    startTimeUpdater({ commit }) {
      if (!interval) {
        interval = setInterval(() => {
          commit('setDate', new Date())
        }, 1000)
      }
    },

    setFindSendingFromRouteParams({ commit }, findSendingFromRouteParams) {
      commit('setFindSendingFromRouteParams', findSendingFromRouteParams)
    },

    setBrowser({ commit }) {
      const browserRegExp = new RegExp(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/, 'i')
      const result = browserRegExp.exec(navigator.userAgent) || []

      const browser = {
        name: undefined,
        version: undefined
      }

      if (result && result.length >= 3) {
        browser.name = result[1]
        browser.version = result[2]
      }

      commit('setBrowser', browser)
    }
  },

  getters: {
    paymentStep: state => state.paymentStep,
    date: state => state.date,
    findSendingFromRouteParams: state => state.findSendingFromRouteParams,
    userAgent: state => state.userAgent,
    browser: state => state.browser
  }
}
