import { colors, icons, modes, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'
import list from '@/components/services/payments/list'
import form from '@/components/services/recurrentPayments/form'
import RecurrentPaymentsItem from '@/components/services/recurrentPayments/item'
import filter from '@/components/services/recurrentPayments/filter'
import pagination from '@/components/misc/pagination'

function renderCreateButton(h) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.create`, permissionPresets.meUp)) {
    return h(
      button,
      {
        props: {
          icon: icons.add,
          mode: modes.flat,
          color: colors.secondary
        },
        on: {
          click: () => {
            this.showCreateDialog = true
          }
        }
      }
    )
  }
}

function renderCreateDialog(h) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.create`, permissionPresets.meUp)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showCreateDialog,
          maxWidth: 450
        },
        on: {
          input: event => {
            this.showCreateDialog = event
            this.setShowCreateRecurrentPaymentDialog(event)
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${services.recurrentPayments}.titles.create`) },
            slot: 'header'
          }
        ),

        h(
          form,
          {
            props: {
              value: this.restData[services.recurrentPayments].create.data,
              errors: this.restData[services.recurrentPayments].create.errors,
              isNew: true
            },
            on: {
              input: event => {
                this.restData[services.recurrentPayments].create.data = event
              },
              submit: () => {
                this.createRecurrentPayment()
              },
              validation: event => {
                this.restData[services.recurrentPayments].create.isValid = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'pa-2 fjcfe facfe grid-gap--8',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  mode: modes.flat
                },
                on: {
                  click: () => {
                    this.showCreateDialog = false
                  }
                }
              }
            ),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  color: 'primary',
                  loading: this.restData[services.recurrentPayments].create.state === states.loading,
                  disabled: !this.restData[services.recurrentPayments].create.isValid || this.restData[services.recurrentPayments].create.state === states.loading
                },
                on: {
                  click: this.createRecurrentPayment
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderPaymentsDialog(h) {
  if (this.checkPermissions('advanced.payments.get', permissionPresets.meUp)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showPaymentsDialog,
          maxWidth: this.viewport.breakpoint.mdUp ? 800 : '100%'
        },
        on: {
          input: event => {
            this.showPaymentsDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${services.recurrentPayments}.titles.payments`) },
            slot: 'header'
          }
        ),

        h(list({ RecurrentPaymentId: this.RecurrentPaymentId })),

        h(
          'div',
          {
            class: 'pa-2 fjcfe facfe grid-gap--8',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: modes.flat
                },
                on: {
                  click: () => {
                    this.showPaymentsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderUnlockConfirmDialog(h) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.remove`, permissionPresets.true)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showUnlockConfirmDialog,
          maxWidth: 450
        },
        on: {
          input: event => {
            this.showUnlockConfirmDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${services.recurrentPayments}.titles.unlock`) },
            slot: 'header'
          }
        ),

        h('div', { class: 'pa-3' }, this.getTranslate(`${services.recurrentPayments}.contents.unlock`)),

        h(
          'div',
          {
            class: 'pa-2 fjcfe facfe grid-gap--8',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  mode: modes.flat,
                  disabled: this.unlockLoading
                },
                on: {
                  click: () => {
                    this.showUnlockConfirmDialog = false
                  }
                }
              }
            ),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.unlock'),
                  icon: icons.done,
                  color: colors.success,
                  mode: modes.filled,
                  loading: this.unlockLoading,
                  disabled: this.unlockLoading
                },
                on: {
                  click: () => {
                    this.unlockRecurrentPayment()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderRemoveConfirmDialog(h) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.remove`, permissionPresets.meUp)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showRemoveConfirmDialog,
          maxWidth: 450
        },
        on: {
          input: event => {
            this.showRemoveConfirmDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${services.recurrentPayments}.titles.remove`) },
            slot: 'header'
          }
        ),

        h('div', { class: 'pa-3' }, this.getTranslate(`${services.recurrentPayments}.contents.remove`)),

        h(
          'div',
          {
            class: 'pa-2 fjcfe facfe grid-gap--8',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  mode: modes.flat,
                  disabled: this.restData[services.recurrentPayments].remove.state === states.loading
                },
                on: {
                  click: () => {
                    this.showRemoveConfirmDialog = false
                  }
                }
              }
            ),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.remove'),
                  loading: this.restData[services.recurrentPayments].remove.state === states.loading,
                  disabled: this.restData[services.recurrentPayments].remove.state === states.loading,
                  color: colors.error,
                  mode: modes.filled
                },
                on: {
                  click: () => {
                    this.removeRecurrentPayment()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderDivider(h) {
  if (Array.isArray(this.restData[services.recurrentPayments].find.data) && this.restData[services.recurrentPayments].find.data.length) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.recurrentPayments].find.pagination,
            service: services.recurrentPayments
          },
          on: {
            input: event => {
              this.restData[services.recurrentPayments].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

function renderFilter(h, options) {
  return h(
    filter,
    {
      props: {
        value: this.restData[services.recurrentPayments].find.filter,
        isPage: !!options.isPage
      },
      on: {
        input: event => {
          this.restData[services.recurrentPayments].find.filter = event
        }
      },
      scopedSlots: {
        buttons: () => {
          return [ renderCreateButton.call(this, h, options) ]
        }
      }
    }
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-5'
    },
    [
      h(
        'g-progress',
        {
          props: {
            indeterminate: true
          }
        }
      )
    ]
  )
}

function renderList(h, options) {
  if (this.restData[services.recurrentPayments].find.state === states.loading) {
    return renderPreloader.call(this, h)
  } else if (this.restData[services.recurrentPayments].find.state === states.ready) {
    if (this.restData[services.recurrentPayments].find.pagination.total > 0) {
      return this.restData[services.recurrentPayments].find.data.map(item => {
        return h(
          RecurrentPaymentsItem,
          {
            props: {
              value: item,
              isPage: !!options.isPage
            },
            key: item.id,
            on: {
              payments: id => {
                this.RecurrentPaymentId = id
                this.showPaymentsDialog = true
              },
              unlockRecurrentPayment: id => {
                this.RecurrentPaymentId = id
                this.showUnlockConfirmDialog = true
              },
              removeRecurrentPayment: id => {
                this.RecurrentPaymentId = id
                this.showRemoveConfirmDialog = true
              }
            }
          }
        )
      })
    }
  }

  return h('g-empty')
}

export default function(h, options) {
  if (this.checkPermissions(`advanced.${services.recurrentPayments}.get`, permissionPresets.meUp)) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderFilter.call(this, h, options),
        renderDivider.call(this, h),
        renderPagination.call(this, h),
        renderDivider.call(this, h),
        renderList.call(this, h, options),
        renderDivider.call(this, h),
        renderPagination.call(this, h),
        renderCreateDialog.call(this, h),
        renderPaymentsDialog.call(this, h),
        renderUnlockConfirmDialog.call(this, h),
        renderRemoveConfirmDialog.call(this, h)
      ]
    )
  }
}
