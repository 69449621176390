import SingleLink from '@/components/misc/SingleLink'
import OneRowString from '@/components/misc/OneRowString'
import TwoRowString from '@/components/misc/TwoRowString'
import RefreshButton from '@/components/button/refresh'

function renderTitle(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        fw: this.viewport.breakpoint.smDown
      }
    },
    [
      this.$slots.title,

      h('div', { class: 'ff' }),

      h(
        RefreshButton,
        {
          props: {
            disabled: this.downloadable,
            label: this.getTranslate('sendings.buttons.refresh'),
            tooltip: this.getTranslate('sendings.tooltips.refresh'),
            state: this.loading,
            shortClickMethod: () => this.getReport(),
            longClickMethod: () => this.getReport()
          }
        }
      )
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderTitle.call(this, h),
      this.$slots.filter
    ]
  )
}

function renderOwnerCell(h, item) {
  if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'pl-2 pr-2 w--100'
      },
      [
        h(SingleLink, {
          props: {
            value: item.Owner
          }
        })
      ]
    )
  }
}

function renderProviderCell(h, item) {
  if (this.checkPermissions('advanced.providers.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: {
          'pl-2 pr-2': true,
          'w--100': this.checkPermissions('advanced.users.get', [ false, 'me' ])
        }
      },
      [
        h(
          SingleLink,
          {
            props: {
              value: item.Provider,
              service: 'providers'
            }
          }
        )
      ]
    )
  }
}

function renderTotalTr(h, item, index) {
  return h(
    'tr',
    {
      class: 'caption',
      style: { [`border-${index === 0 ? 'bottom' : 'top'}`]: '1px solid darkgrey' },
      key: `sendings-groups-002-total-${index === 0 ? 'up' : 'bottom'}`
    },
    [
      h(
        'th',
        {
          class: 'pl-2 pr-2 w--100',
          attrs: { colspan: this.colspan }
        },
        this.getTranslate('misc.total')
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${this.total.countDelivered}`,
                second: `${this.total.countTotal > 0 &&
                Math.round(this.total.countDelivered / this.total.countTotal * 100)}%`
              }
            }
          )
        ]
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${this.total.countSeen}`,
                second: `${this.total.countTotal > 0 &&
                Math.round(this.total.countSeen / this.total.countTotal * 100)}%`
              }
            }
          )
        ]
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${this.total.countFailed}`,
                second: `${this.total.countTotal > 0 &&
                Math.round(this.total.countFailed / this.total.countTotal * 100)}%`
              }
            }
          )
        ]
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${this.total.countSent}`,
                second: `${this.total.countTotal > 0 &&
                Math.round(this.total.countSent / this.total.countTotal * 100)}%`
              }
            }
          )
        ]
      ),

      h(
        'th',
        {
          class: 'text-right pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: `${this.total.countTotal}`
              }
            }
          )
        ]
      )
    ]
  )
}

function renderTr(h, item, index) {
  return h(
    'tr',
    {
      key: `sendings-groups-002-${index}`
    },
    [
      renderOwnerCell.call(this, h, item),
      renderProviderCell.call(this, h, item),

      h(
        'td',
        {
          class: {
            'pl-2 pr-2': true,
            'w--100':
              this.checkPermissions('advanced.users.get', [ false, 'me' ]) &&
              this.checkPermissions('advanced.providers.get', [ false, 'me' ])
          }
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: item.opgroup
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'pl-2 pr-2'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: item.type
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'pl-2 pr-2 text-right'
        },
        [
          h(
            OneRowString,
            {
              props: {
                value: `${item.count_delivered}`
              }
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'pl-2 pr-2 text-right'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${item.count_seen}`,
                second: `${item.count_total > 0 && Math.round(item.count_seen / item.count_total * 100)}%`
              },
              directives: [
                {
                  name: 'g-tooltip',
                  options: {
                    value: this.getTranslate('sendings.tooltips.count', { count: item.count_seen_whole })
                  }
                }
              ]
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'pl-2 pr-2 text-right'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${item.count_failed}`,
                second: `${item.count_total > 0 && Math.round(item.count_failed / item.count_total * 100)}%`
              },
              directives: [
                {
                  name: 'g-tooltip',
                  options: {
                    value: this.getTranslate('sendings.tooltips.count', { count: item.count_failed_whole })
                  }
                }
              ]
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'pl-2 pr-2 text-right'
        },
        [
          h(
            TwoRowString,
            {
              props: {
                first: `${item.count_sent}`,
                second: `${item.count_total > 0 && Math.round(item.count_sent / item.count_total * 100)}%`
              },
              directives: [
                {
                  name: 'g-tooltip',
                  options: {
                    value: this.getTranslate('sendings.tooltips.count', { count: item.count_sent_whole })
                  }
                }
              ]
            }
          )
        ]
      ),

      h(
        'td',
        {
          class: 'pl-2 pr-2 text-right'
        },
        [
          h(
            OneRowString,
            {
              props: { value: `${item.count_total}` },
              directives: [
                {
                  name: 'g-tooltip',
                  options: {
                    value: this.getTranslate('sendings.tooltips.count', { count: item.count_total_whole })
                  }
                }
              ]
            }
          )
        ]
      )
    ]
  )
}

function renderItems(h, item, index) {
  if (index === 0) {
    return [ renderTotalTr.call(this, h, item, index), renderTr.call(this, h, item, index) ]
  } else if (index === this.report.length - 1) {
    return [ renderTr.call(this, h, item, index), renderTotalTr.call(this, h, item, index) ]
  } else {
    return [ renderTr.call(this, h, item, index) ]
  }
}

function renderTable(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h('g-table', {
      props: {
        headers: this.headers,
        hideHead: this.report.length < 1,
        items: this.report,
        value: this.order,
        sortLocal: true,
        noDataText: this.getTranslate('misc.no.data.text')
      },
      on: {
        input: event => {
          this.order = event
        }
      },
      scopedSlots: {
        items: ({ item, index }) => {
          return renderItems.call(this, h, item, index)
        }
      }
    })
  }
}

function renderPanels(h) {
  if (this.viewport.breakpoint.smDown) {
    return h(
      'g-expansion-panels',
      {
        props: {
          flat: true
        }
      },
      this.report.map(item => {
        return h(
          'g-expansion-panel',
          {
            key: item.id
          },
          [
            h(
              'div',
              {
                class: 'default-expansion-panel-header',
                slot: 'header'
              },
              [
                h(
                  TwoRowString,
                  {
                    props: {
                      first: `${this.getTranslate('sendings.headers.count_total')}: ${item.count_total}`
                    }
                  },
                  [
                    h(SingleLink, {
                      props: {
                        value: item.Owner
                      },
                      slot: 'second'
                    })
                  ]
                ),

                h('div', { class: 'ff' }),

                h(
                  'div',
                  {
                    class: 'fc'
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'fjcfe facfe faic'
                      },
                      [
                        item.count_delivered,

                        h(
                          'g-icon',
                          {
                            class: 'ml-1',
                            props: {
                              size: 16,
                              color: 'success'
                            }
                          },
                          'done'
                        )
                      ]
                    ),
                    h(
                      'div',
                      {
                        class: 'fjcfe facfe faic'
                      },
                      [
                        item.count_seen,

                        h(
                          'g-icon',
                          {
                            class: 'ml-1',
                            props: {
                              size: 16,
                              color: 'success'
                            }
                          },
                          'done_all'
                        )
                      ]
                    )
                  ]
                ),

                h(
                  'div',
                  {
                    class: 'fc ml-1'
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'fjcfe facfe faic'
                      },
                      [
                        item.count_failed,

                        h(
                          'g-icon',
                          {
                            class: 'ml-1',
                            props: {
                              size: 16,
                              color: 'error'
                            }
                          },
                          'clear'
                        )
                      ]
                    ),
                    h(
                      'div',
                      {
                        class: 'fjcfe facfe faic'
                      },
                      [
                        item.count_sent,

                        h(
                          'g-icon',
                          {
                            class: 'ml-1',
                            props: {
                              size: 16,
                              color: 'info'
                            }
                          },
                          'trending_flat'
                        )
                      ]
                    )
                  ]
                )
              ]
            ),

            h(
              'table',
              {
                class: 'w--100 pa-2'
              },
              [
                h(
                  'thead',
                  {},
                  [
                    h(
                      'tr',
                      {
                        class: 'caption'
                      },
                      [
                        h('th', {
                          domProps: {
                            innerHTML: this.getTranslate('sendings.headers.count_delivered')
                          }
                        }),
                        h('th', {
                          domProps: {
                            innerHTML: this.getTranslate('sendings.headers.count_seen')
                          }
                        }),
                        h('th', {
                          domProps: {
                            innerHTML: this.getTranslate('sendings.headers.count_failed')
                          }
                        }),
                        h('th', {
                          domProps: {
                            innerHTML: this.getTranslate('sendings.headers.count_sent')
                          }
                        })
                      ]
                    )
                  ]
                ),

                h(
                  'tbody',
                  {},
                  [
                    h(
                      'tr',
                      {
                        class: 'caption'
                      },
                      [
                        h(
                          'td',
                          {
                            class: 'text-center'
                          },
                          [
                            item.count_delivered,
                            item.count_total > 0
                              ? '(' + Math.round(item.count_delivered / item.count_total * 100) + '%)'
                              : ''
                          ].join(' ')
                        ),
                        h(
                          'td',
                          {
                            class: 'text-center'
                          },
                          [
                            item.count_seen,
                            item.count_total > 0
                              ? '(' + Math.round(item.count_seen / item.count_total * 100) + '%)'
                              : ''
                          ].join(' ')
                        ),
                        h(
                          'td',
                          {
                            class: 'text-center'
                          },
                          [
                            item.count_failed,
                            item.count_total > 0
                              ? '(' + Math.round(item.count_failed / item.count_total * 100) + '%)'
                              : ''
                          ].join(' ')
                        ),
                        h(
                          'td',
                          {
                            class: 'text-center'
                          },
                          [
                            item.count_sent,
                            item.count_total > 0
                              ? '(' + Math.round(item.count_sent / item.count_total * 100) + '%)'
                              : ''
                          ].join(' ')
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )
      })
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderHeader.call(this, h),
      renderTable.call(this, h),
      renderPanels.call(this, h)
    ]
  )
}
