import { permissionPresets, routerQueryReplace } from '@/utils'

import ButtonReport from '@/components/button/report'
import SendingsFilter from '@/components/services/sendings/outgoing/filter'
import SendingsIncomingDetailsTemplate from '@/components/services/sendings/incoming/template'
import sendingsTasksTemplate from '@/components/services/sendings/tasks/template'

function renderSelectPresetField(h) {
  if (this.checkPermissions('advanced.reports.get', permissionPresets.meUp)) {
    return h(
      'g-select',
      {
        style: {
          'min-width': this.viewport.breakpoint.smDown ? '100%' : '308px',
          'max-width': this.viewport.breakpoint.smDown ? '100%' : '308px'
        },
        class: {
          'mr-2': this.viewport.breakpoint.mdUp,
          'mb-2': this.viewport.breakpoint.smDown
        },
        props: {
          value: this.preset,
          items: this.presets
            .filter(item => !!item.show)
            .map(preset => ({
              title: this.getTranslate(preset.title),
              value: preset.value
            })),
          required: true,
          mode: 'outline',
          rounded: true,
          dense: true,
          details: false
        },
        on: {
          input: event => {
            this.preset = event
          }
        },
        slot: 'title'
      }
    )
  }
}

function renderFilter(h) {
  return h(
    SendingsFilter,
    {
      props: {
        value: this.filter,
        preset: this.preset
      },
      on: {
        input: event => {
          this.filter = event
        }
      },
      slot: 'filter'
    }
  )
}

function renderOutgoingTabItem(h) {
  if (this.checkPermissions('advanced.sendings.find', permissionPresets.meUp)) {
    return h(
      'g-card',
      {
        props: {
          rounded: true,
          outline: true
        },
        key: 'outgoing'
      },
      [
        h(
          this.presetNameKebab,
          {
            props: { filter: this.filter },
            on: {
              input: event => {
                this.filter = event
              }
            }
          },
          [
            renderSelectPresetField.call(this, h),

            h(
              ButtonReport,
              {
                class: 'ma-0',
                props: {
                  preset: this.preset,
                  filter: this.filter
                },
                slot: 'title'
              }
            ),

            renderFilter.call(this, h)
          ]
        )
      ]
    )
  }
}

function renderIncomingTabItem(h) {
  if (this.checkPermissions('advanced.sendingsIncoming.get', permissionPresets.meUp)) {
    return h(SendingsIncomingDetailsTemplate, { key: 'incoming' })
  }
}

function renderTasksTabItem(h) {
  return h(sendingsTasksTemplate, { key: 'tasks' })
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'g-tabs-header',
        {
          class: 'pl-3',
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`sendings.tabs.${key}`)
              }
            }),
            isLink: true
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      ),
      h(
        'g-tabs',
        {
          props: { value: this.currentTab }
        },
        [
          renderOutgoingTabItem.call(this, h),
          renderIncomingTabItem.call(this, h),
          renderTasksTabItem.call(this, h)
        ]
      )
    ]
  )
}
