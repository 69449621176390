import { colors, sizes } from '@/utils'

import render from './render'

const className = 'icon-squircle'

export default {
  name: 'Squircle',

  props: {
    icon: {
      type: String,
      default: undefined
    },
    iconSize: {
      type: Number,
      default: undefined
    },

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    size: {
      type: String,
      default: sizes.small,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },

    loading: {
      type: Boolean,
      default: false
    },

    tooltip: {
      type: String,
      default: undefined
    }
  },

  computed: {
    $class() {
      return {
        [`${className}`]: true,
        [`${className}--${this.size}`]: !!this.size,
        [`${className}--${this.color}`]: !!this.color
      }
    },

    $iconSize() {
      if (this.icon) {
        if (this.iconSize) {
          return this.iconSize
        }

        switch (this.size) {
          case sizes.small: {
            return 20
          }
          case sizes.medium: {
            return 24
          }
        }
      }
    },

    $directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.tooltip,
            placement: 'top'
          }
        })
      }

      return result
    }
  },

  render
}
