import proxy from '@sigma-legacy-libs/g-proxy'

import { getISODate, isValidEmail, isValidPhone, services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    ListId: String,

    isFilter: Boolean
  },

  computed: {
    phoneValidation() {
      if (this.proxy.phone && !isValidPhone(this.proxy.phone)) {
        return this.getTranslate(`${services.contacts}.errors.phone`)
      }
    },

    emailValidation() {
      if (this.proxy.email && !isValidEmail(this.proxy.email)) {
        return this.getTranslate(`${services.contacts}.errors.email`)
      }
    },

    validation() {
      return !!(
        isValidPhone(this.proxy.phone) ||
        isValidEmail(this.proxy.email)
      )
    },

    computedDate() {
      if (this.proxy.date) {
        return getISODate(this.proxy.date)
      }
    }
  },

  watch: {
    ListId() {
      this.proxy.ListId = this.ListId
    }
  },

  mounted() {
    this.proxy.ListId = this.ListId
  },

  render
}
