import { cloneDeep, get, merge } from 'lodash'

import { DEFAULT_INVOICE_PAYLOAD, colors, getPrice, inputModes, modes, services, sizes } from '@/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import ExpandArrow from '@/components/misc/ExpandArrow'
import InvoiceDialog from '@/components/services/settings/notifications/events/billings/limit/InvoiceDialog'

const path = 'notifications.events.billings.limit'

function renderLimitField(h, { serviceName }, limit) {
  return h(
    'g-text-field',
    {
      props: {
        value: limit.balance,
        label: this.getTranslate(`${serviceName}.labels.${path}.limit`),
        defaultValue: 0,
        clearable: true,
        autofocus: true,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        suffix: this.getTranslate(`currency.${this.globalSettings.billings.currency}.symbol.unicode`),
        type: 'number',
        step: 'any'
      },
      on: {
        input: event => {
          limit.balance = event
        }
      }
    }
  )
}
function renderInvoiceAmountField(h, { serviceName }, limit) {
  if (limit.invoice) {
    if (!limit.invoice.data) {
      limit.invoice.data = {
        SERVICE_PRICE: limit.invoice.amount || 0
      }
    }

    return h(
      'g-text-field',
      {
        props: {
          value: limit.invoice.data.SERVICE_PRICE,
          label: this.getTranslate(`${serviceName}.labels.${path}.invoice.amount`),
          mode: inputModes['line-label'],
          defaultValue: 0,
          dense: true,
          rounded: true,
          clearable: true,
          details: false,
          suffix: this.getTranslate(`currency.${this.globalSettings.billings.currency}.symbol.unicode`),
          type: 'number',
          step: 'any'
        },
        on: {
          input: event => {
            limit.invoice.data.SERVICE_PRICE = event
          }
        }
      }
    )
  }
}
function renderIncludeOverdraftField(h, { serviceName }, limit) {
  return h(
    'g-switch',
    {
      props: {
        value: limit.includeOverdraft,
        label: this.getTranslate(`${serviceName}.labels.${path}.include.overdraft`)
      },
      on: {
        input: event => {
          limit.includeOverdraft = event
        }
      }
    }
  )
}
function renderAttachInvoiceButton(h, { serviceName, UserId }, limit, index) {
  const amount = get(limit, 'invoice.data.SERVICE_PRICE', get(limit, 'invoice.amount'))
  if (limit.invoice && amount) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`${serviceName}.buttons.${path}.invoice.detach`),
          mode: modes.flat,
          size: sizes.small,
          color: colors.warning
        },
        on: {
          click: () => {
            limit.invoice = false
          }
        }
      }
    )
  } else {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`${serviceName}.buttons.${path}.invoice.attach`),
          mode: modes.flat,
          size: sizes.small,
          color: colors.primary
        },
        on: {
          click: () => {
            limit.invoice = merge(cloneDeep(DEFAULT_INVOICE_PAYLOAD), { customerId: UserId })
            this.limitIndex = index
            this.showInvoiceDialog = true
          }
        }
      }
    )
  }
}
function renderRemoveButton(h, index) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.remove'),
        mode: modes.flat,
        size: sizes.small,
        color: colors.error
      },
      on: {
        click: () => {
          this.remove(index)
        }
      }
    }
  )
}
function renderFormFooter(h, options, limit, index) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8'
    },
    [
      renderAttachInvoiceButton.call(this, h, options, limit, index),
      renderRemoveButton.call(this, h, index)
    ]
  )
}
function renderForm(h, options, limit, index) {
  return h(
    'div',
    {
      class: 'grid pt-3 pb-2 px-2'
    },
    [
      h(
        'div',
        {
          class: {
            'grid faic': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          renderLimitField.call(this, h, options, limit),
          renderIncludeOverdraftField.call(this, h, options, limit)
        ]
      ),
      h(
        'div',
        {
          class: {
            'grid faic': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [ renderInvoiceAmountField.call(this, h, options, limit) ]
      ),

      renderFormFooter.call(this, h, options, limit, index)
    ]
  )
}

function renderHeaderTags(h, { serviceName }, limit) {
  const tags = []
  if (limit.includeOverdraft) {
    tags.push({
      label: this.getTranslate(`${serviceName}.labels.${path}.include.overdraft`, { overdraft: getPrice(this.overdraft) }),
      color: colors.success
    })
  } else {
    tags.push({
      label: this.getTranslate(`${serviceName}.labels.${path}.notInclude.overdraft`),
      color: colors.error
    })
  }
  if (limit.invoice) {
    const amount = get(limit, 'invoice.data.SERVICE_PRICE', get(limit, 'invoice.amount'))
    if (amount) {
      tags.push({
        label: this.getTranslate(`${serviceName}.labels.${path}.include.invoice`, { amount: getPrice(amount) }),
        color: colors.success
      })
    }
  } else {
    tags.push({
      label: this.getTranslate(`${serviceName}.labels.${path}.notInclude.invoice`),
      color: colors.error
    })
  }

  return h(
    'div',
    {
      class: 'faic fw grid-gap--4'
    },
    [
      tags.map(({ label, color }) => {
        return h(
          tag,
          {
            props: {
              label,
              color,
              size: sizes.tiny
            }
          }
        )
      })
    ]
  )
}
function renderHeaderTitle(h, { serviceName }, limit) {
  let amount = 0
  if (limit.includeOverdraft) {
    amount = getPrice(limit.balance - this.overdraft)
  } else {
    amount = getPrice(limit.balance)
  }

  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${serviceName}.hints.${path}.title`, { amount })
      }
    }
  )
}
function renderHeader(h, options, limit, { expanded }) {
  return h(
    'div',
    {
      class: 'fjcsb faic pl-2 py-2'
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--6'
        },
        [
          renderHeaderTitle.call(this, h, options, limit),
          renderHeaderTags.call(this, h, options, limit)
        ]
      ),

      h(
        ExpandArrow,
        {
          props: {
            expanded
          }
        }
      )
    ]
  )
}
function renderBody(h, options, limit, index) {
  return renderForm.call(this, h, options, limit, index)
}
function renderPanel(h, options, limit, index) {
  return h(
    'g-expansion-panel',
    {
      scopedSlots: {
        header: headerOptions => renderHeader.call(this, h, options, limit, headerOptions),
        default: () => renderBody.call(this, h, options, limit, index)
      }
    }
  )
}
function renderPanels(h, options) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      'g-expansion-panels',
      {
        props: {
          rounded: true,
          outline: true
        }
      },
      this.proxy.map((limit, index) => renderPanel.call(this, h, options, limit, index))
    )
  }
}

function renderAddLimitButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.add'),
        mode: modes.flat
      },
      on: {
        click: () => {
          this.add()
        }
      }
    }
  )
}
function renderSaveLimitButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.save'),
        mode: modes.flat,
        color: colors.primary
      },
      on: {
        click: () => {
          this.$emit('input', cloneDeep(this.proxy))
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8'
    },
    [
      renderAddLimitButton.call(this, h),
      renderSaveLimitButton.call(this, h)
    ]
  )
}

function renderInvoiceDialog(h, { UserId, ResellerId }) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    if (this.proxy[this.limitIndex].invoice) {
      return h(
        InvoiceDialog,
        {
          props: {
            value: this.proxy[this.limitIndex].invoice,
            showForm: this.showInvoiceDialog,
            OwnerId: UserId,
            ResellerId
          },
          on: {
            input: event => {
              this.proxy[this.limitIndex].invoice = event
            },
            show: event => {
              this.showInvoiceDialog = event
            }
          }
        }
      )
    }
  }
}

export default function(h, options) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        title: this.getTranslate(`${services.settingsUser}.subtitles.${path}`) + ' ' + this.getTranslate(`${services.sendings}.types.email`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      renderPanels.call(this, h, options),
      renderFooter.call(this, h),
      renderInvoiceDialog.call(this, h, options)
    ]
  )
}
