import { colors, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'

export default function(h) {
  return h(
    squircle,
    {
      props: {
        color: this.$type,
        size: sizes.medium
      }
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: this.$type,
            color: colors[this.$type]
          }
        }
      )
    ]
  )
}
