import Vue from 'vue'

import { mapActions } from 'vuex'

import { backendServerIP, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'RecoverTemplate',

  data() {
    return {
      login: undefined,
      password: undefined,

      loading: false,
      passwordVisibility: false,

      validation: undefined,
      state: undefined
    }
  },

  watch: {
    $route: {
      handler() {
        this.checkRecoveryKey(this.$route.params && this.$route.params.key)
      },
      deep: true
    }
  },

  mounted() {
    this.checkRecoveryKey(this.$route.params && this.$route.params.key)
  },

  beforeDestroy() {
    this.login = undefined
    this.password = undefined
    this.loading = false
    this.validation = undefined
    this.state = undefined
  },

  methods: {
    ...mapActions({
      setToken: 'authentication/setToken',
      setAccountFromToken: 'authentication/setAccountFromToken'
    }),

    async requestRecover() {
      if (this.login) {
        try {
          this.loading = true
          const response = await fetch(`${backendServerIP}/api/recover/`, {
            method: 'post',
            body: JSON.stringify({ id: this.login }),
            headers: { 'Content-Type': 'application/json' }
          })
          if (!response.ok) {
            throw new Error(response.status)
          }
          this.validation = true
          this.state = 'request'
        } catch (error) {
          this.login = undefined
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    async checkRecoveryKey(key) {
      if (key) {
        try {
          const response = await fetch(`${backendServerIP}/api/recover/${key}`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' }
          })
          if (!response.ok) {
            throw new Error(response.status)
          }
          const { result } = await response.json()
          this.validation = result
          this.state = 'key'
          if (this.validation === false) {
            Vue.router.push({ name: 'login' })
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    async changePassword(key) {
      if (this.password) {
        try {
          this.loading = true
          const response = await fetch(`${backendServerIP}/api/recover/${key || this.$route.params.key}`, {
            method: 'post',
            body: JSON.stringify({ password: this.password }),
            headers: { 'Content-Type': 'application/json' }
          })
          if (!response.ok) {
            throw new Error(response.status)
          }
          const { result: token } = await response.json()
          this.password = undefined
          this.setToken(token)
          await this.setAccountFromToken()
          Vue.router.push({ name: 'home' })
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
