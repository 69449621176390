import Vue from 'vue'

import { get } from 'lodash'
import { Macro } from '@sigma-legacy-libs/cache'

import { isUUID, permissionPresets } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})
const cachedGet = Cache.wrapWithCache(async (key, service, id) => {
  return await Vue.$GRequest.get(service, id)
})

export default {
  name: 'LinkService',

  props: {
    value: {
      type: [ String, Object ],
      default: () => undefined
    },

    params: {
      type: Object,
      default: () => ({})
    },

    service: {
      type: String,
      default: 'users'
    },

    pathToId: {
      type: String,
      default: 'id'
    },
    pathToTitle: {
      type: String,
      default: 'title'
    }
  },

  data() {
    return {
      data: undefined,
      loading: false
    }
  },

  computed: {
    $title() {
      return get(this.data, this.pathToTitle)
    },

    $to() {
      if (this.service && this.data) {
        const id = get(this.data, this.pathToId)
        if (id) {
          return {
            name: `${this.service}.single`,
            params: { id }
          }
        }
      }
    },

    $component() {
      return this.permissionCheck ? 'router-link' : 'div'
    },

    permissionCheck() {
      let accept = permissionPresets.resellerUp
      switch (this.service) {
        case 'contacts':
        case 'contactLists':
        case 'sendernames':
        case 'patterns':
        case 'templates':
        case 'apisender': {
          accept = permissionPresets.meUp
          break
        }
      }

      return this.checkPermissions(`advanced.${this.service}.get`, accept)
    }
  },

  watch: {
    value: {
      handler() {
        this.get()
      },
      deep: true
    }
  },

  mounted() {
    this.get()
  },

  methods: {
    async get() {
      try {
        if (this.value) {
          if (isUUID(this.value)) {
            if (this.permissionCheck) {
              this.loading = true
              const { data } = await cachedGet(`${this.service}:${this.value}`, this.service, this.value)
              if (data) {
                this.data = data
              }
              this.loading = false
            }
          } else if (typeof this.value === 'object') {
            this.data = this.value
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  },

  render
}
