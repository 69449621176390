import { modes } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    button,
    {
      props: {
        mode: modes.flat,
        label: this.label,
        icon: this.icon,
        disabled: this.disabled
      },
      on: {
        click: () => {
          const iframe = document.createElement('iframe')

          document.body.appendChild(iframe)

          const iframeContent = iframe.contentDocument ? iframe.contentDocument : iframe.contentWindow.document
          iframeContent.getElementsByTagName('body')[0].innerHTML = this.value

          iframe.contentWindow.focus()
          iframe.contentWindow.print()

          document.body.removeChild(iframe)
        }
      }
    }
  )
}
