import Vue from 'vue'

import { inputModes, services } from '@/utils'

import TariffsRules from '@/components/services/tariffs/rules'
import TariffsDefaultRule from '@/components/services/tariffs/defaultRule'
import SendingTypes from '@/components/misc/SendingTypes'
import OwnerSelect from '@/components/misc/OwnerSelect'
import buttonUpDown from '@/components/button/upDown'

function renderTypeField(h) {
  return h(
    SendingTypes,
    {
      props: {
        value: this.proxy.source,
        label: this.getTranslate(`${services.tariffs}.labels.source`),
        items: this.sourceItems,
        format: value => value.replace(/sendings\.|subscription\./g, ''),
        mode: inputModes['line-label'],
        required: true
      },
      on: {
        input: event => {
          this.proxy.source = event
        }
      }
    }
  )
}

function renderTariffRules(h) {
  return h(
    TariffsRules,
    {
      props: {
        value: this.proxy.data.rules,
        source: this.proxy.source ? this.proxy.source.split('.')[1] : undefined
      },
      on: {
        input: event => {
          this.proxy.data.rules = event
        }
      }
    }
  )
}

function renderDefaultRule(h) {
  if (this.proxy._defaultRule) {
    return h(
      TariffsDefaultRule,
      {
        props: {
          value: this.proxy._defaultRule,
          isMultiple: !!(this.proxy.data.rules.length > 0),
          source: this.proxy.source ? this.proxy.source.split('.')[1] : undefined
        },
        on: {
          input: event => {
            this.proxy._defaultRule = event
          },
          remove: () => {
            Vue.delete(this.proxy, '_defaultRule')
          }
        }
      }
    )
  }
}

function renderRefund(h) {
  if (this.proxy.source && this.proxy.source.split('.')[0] === 'sendings') {
    return h(
      'g-switch',
      {
        props: {
          value: this.proxy.data.options.refundOnFailed,
          label: this.getTranslate(`${services.tariffs}.labels.refundOnFailed`)
        },
        on: {
          input: event => {
            this.proxy.data.options.refundOnFailed = event
          }
        }
      }
    )
  }
}

function renderNoPayments(h) {
  if (this.proxy.source && this.proxy.source.split('.')[0] === 'sendings') {
    return h(
      'g-switch',
      {
        props: {
          value: this.proxy.data.options.noPayments,
          label: this.getTranslate(`${services.tariffs}.labels.noPayments`)
        },
        on: {
          input: event => {
            this.proxy.data.options.noPayments = event
          }
        }
      }
    )
  }
}

function renderCommentField(h) {
  return h(
    'g-textarea',
    {
      props: {
        value: this.proxy.data.hidden.comment,
        label: this.getTranslate(`${services.tariffs}.labels.comment`),
        mode: 'outline-label',
        rounded: true,
        details: false,
        grow: true
      },
      on: {
        input: event => {
          this.proxy.data.hidden.comment = event
        }
      }
    }
  )
}

function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        permission: `advanced.${services.tariffs}.update`,
        accept: [ true ],
        mode: 'outline-label',
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-cols--1 pa-3',
      on: {
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      renderTypeField.call(this, h),
      renderTariffRules.call(this, h),
      renderDefaultRule.call(this, h),
      renderRefund.call(this, h),
      renderNoPayments.call(this, h),
      renderCommentField.call(this, h),
      renderOwnerField.call(this, h),

      h(buttonUpDown)
    ]
  )
}
