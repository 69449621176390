import { get } from 'lodash'

import { serviceName } from '@/components/services/sendernames/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    if (this.viewport.size.width >= 640) {
      const ownerId = get(this.value, 'Owner.id', this.value.OwnerId)
      if (ownerId) {
        return h(
          sticker,
          {
            props: {
              value: ownerId,
              label: this.getTranslate(`${serviceName}.labels.owner`),
              pathToTitle: 'username',
              icon: 'person',
              color: 'grey'
            }
          }
        )
      }
    }
  }
}
