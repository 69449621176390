import { serviceName } from '@/components/services/sendings/utils'

import buttonRefresh from '@/components/button/refresh'
import sendingsOutgoingDetailsItem from '@/components/services/sendings/outgoing/details/item'
import detailsDialog from '@/components/services/sendings/outgoing/details/dialog'
import pagination from '@/components/misc/pagination'
import button from '@/components/button'
import { colors, modes } from '@/utils'

function renderReleaseBucketButton(h) {
  if (this.restData[serviceName].find.bucketLength > 0) {
    return h(
      button,
      {
        style: { 'min-width': 'auto' },
        props: {
          label: this.restData[serviceName].find.bucketLength,
          icon: 'add',
          rounded: true,
          depressed: true,
          disabled: this.restData[serviceName].find.state === 'loading',
          color: 'primary'
        },
        on: {
          click: () => {
            this.rest[serviceName].bucketRelease()
          }
        }
      }
    )
  }
}
function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        disabled: this.downloadable,
        label: this.getTranslate(`${serviceName}.buttons.refresh`),
        tooltip: this.getTranslate(`${serviceName}.tooltips.refresh`),
        state: this.restData[serviceName].find.state,
        shortClickMethod: () => this.rest[serviceName].find(),
        longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
      }
    }
  )
}
function renderTitle(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        fw: this.viewport.breakpoint.smDown
      }
    },
    [
      this.$slots.title,

      h('div', { class: 'ff' }),

      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': this.restData[serviceName].find.bucketLength > 0 ? 'repeat(2, auto)' : 'auto' }
        },
        [
          renderReleaseBucketButton.call(this, h),
          renderRefreshButton.call(this, h)
        ]
      )
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderTitle.call(this, h),
      this.$slots.filter
    ]
  )
}

function renderSourceButton(h, item) {
  return h(
    button,
    {
      props: {
        icon: 'code',
        color: colors.primary,
        mode: modes.flat
      },
      on: {
        click: () => {
          this.source = item
          this.showDialog = true
        }
      }
    }
  )
}

function renderContentByBreakpoint(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          headers: this.headers,
          hideHead: this.restData[serviceName].find.pagination.total < 1,
          items: this.restData[serviceName].find.data,
          value: this.restData[serviceName].find.order,
          noDataText: this.getTranslate(`${serviceName}.hints.empty`)
        },
        on: {
          input: event => {
            this.restData[serviceName].find.order = event
          }
        },
        scopedSlots: {
          items: ({ item }) => {
            return h(
              sendingsOutgoingDetailsItem,
              {
                props: {
                  value: item,
                  type: 'row'
                },
                scopedSlots: {
                  sourceButton: () => renderSourceButton.call(this, h, item)
                }
              }
            )
          }
        }
      }
    )
  } else {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.restData[serviceName].find.data.map(item => {
        return h(
          sendingsOutgoingDetailsItem,
          {
            props: {
              value: item,
              type: 'panel'
            },
            scopedSlots: {
              sourceButton: () => renderSourceButton.call(this, h, item)
            }
          }
        )
      })
    )
  }
}

function renderDivider(h) {
  if (Array.isArray(this.restData[serviceName].find.data) && this.restData[serviceName].find.data.length) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[serviceName].find.pagination,
            service: serviceName
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

function renderDetailsDialog(h) {
  return h(
    detailsDialog,
    {
      props: {
        value: this.source,
        show: this.showDialog
      },
      on: {
        close: () => {
          this.showDialog = false
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderHeader.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderContentByBreakpoint.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDetailsDialog.call(this, h)
    ]
  )
}
