import { moderationColors, moderationStatusIcons } from '@/utils'
import { serviceName } from '@/components/services/sendernames/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  const { id, name, moderation } = this.value

  return h(
    sticker,
    {
      props: {
        value: name,
        label: this.getTranslate(`${serviceName}.labels.name`),
        icon: moderationStatusIcons[moderation],
        color: moderationColors[moderation],
        iconTooltip: this.getTranslate(`${serviceName}.statuses.${moderation}`),
        copy: true,
        to: {
          name: `${serviceName}.single`,
          params: { id }
        }
      }
    }
  )
}
