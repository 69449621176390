import { colors, icons } from '@/utils'

import render from './render'

export default {
  name: 'Info',

  props: {
    value: String,

    icon: {
      type: String,
      default: icons.info_outline,
      validator: value => {
        return Object.values(icons).includes(value)
      }
    },
    color: {
      type: String,
      default: colors.info,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    }
  },

  computed: {
    content() {
      return this.value || this.$slots.default
    }
  },

  render
}
