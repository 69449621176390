import Vue from 'vue'

import { renderPreLoader } from '@/components/misc'

import FormTitle from '@/components/misc/FormTitle'
import ConfirmRemoveButton from '@/components/misc/ConfirmRemoveButton'
import componentNotFound from '@/components/misc/componentNotFound'

import { permissionPresets, states } from '@/utils'

function renderHeader(h, options) {
  let slotTitle = h(
    FormTitle,
    {
      props: {
        value: this.restData[options.name].get.data,
        errors: this.restData[options.name].update.errors
      },
      on: {
        input: event => {
          this.restData[options.name].get.data = event
        },
        submit: () => {
          this.rest[options.name].update(this.restData[options.name].get.data.id, this.restData[options.name].get.data)
        }
      }
    }
  )
  if (this.$scopedSlots.title) {
    slotTitle = this.$scopedSlots.title({ data: this.restData[options.name].get.data })
  } else if (this.$slots.title) {
    slotTitle = this.$slots.title
  }

  let slotHeader = this.$slots.header
  if (this.$scopedSlots.header) {
    slotHeader = this.$scopedSlots.header({ data: this.restData[options.name].get.data })
  }

  if (slotTitle || slotHeader) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp
        }
      },
      [ slotTitle, slotHeader ]
    )
  }
}

function renderAfterHeader(h, options) {
  return this.$scopedSlots.afterTitle ? this.$scopedSlots.afterTitle({ data: this.restData[options.name].get.data }) : this.$slots.afterTitle
}

function renderFormComponent(h, options) {
  try {
    return h(
      require(`@/components/services/${options.name}/form`).default,
      {
        props: {
          value: this.restData[options.name].get.data,
          errors: this.restData[options.name].update.errors
        },
        on: {
          input: event => {
            this.restData[options.name].get.data = event
          },
          submit: () => {
            this.rest[options.name].update(
              this.restData[options.name].get.data.id,
              this.restData[options.name].get.data
            )
          }
        }
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}

function renderAfterForm(h, options) {
  return this.$scopedSlots['after-form'] ? this.$scopedSlots['after-form']({ data: this.restData[options.name].get.data }) : this.$slots['after-form']
}

function renderForm(h, options) {
  return h(
    'g-card',
    {
      class: 'pb-3',
      props: { rounded: true }
    },
    [
      renderFormComponent.call(this, h, options),
      renderAfterForm.call(this, h, options)
    ]
  )
}

function renderCloseButton(h, options) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        label: this.getTranslate('misc.buttons.close'),
        flat: true,
        rounded: true
      },
      on: {
        click: () => {
          Vue.router.push({ name: options.name })
        }
      }
    }
  )
}

function renderRefreshButton(h, options) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'refresh',
        flat: true,
        rounded: true,
        loading: this.restData[options.name].get.state === states.loading,
        disabled: this.restData[options.name].get.state === states.loading || !this.checkPermissions(`advanced.${options.name}.get`, permissionPresets.resellerUp)
      },
      on: {
        click: () => {
          this.rest[options.name].get(this.restData[options.name].get.data.id)
        }
      }
    }
  )
}

function renderRemoveButton(h, options) {
  return h(
    ConfirmRemoveButton,
    {
      class: 'ma-0',
      props: {
        loading: this.restData[options.name].remove.state === states.loading,
        disabled: this.restData[options.name].remove.state === states.loading || !this.checkPermissions(`advanced.${options.name}.remove`, permissionPresets.resellerUp),
        callback: () => this.rest[options.name].remove(this.restData[options.name].get.data.id)
      }
    }
  )
}

function renderUpdateButton(h, options) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        label: this.getTranslate('misc.buttons.update'),
        rounded: true,
        depressed: true,
        loading: this.restData[options.name].update.state === states.loading,
        disabled: this.restData[options.name].update.state === states.loading || !this.checkPermissions(`advanced.${options.name}.update`, permissionPresets.resellerUp),
        color: 'primary'
      },
      on: {
        click: () => {
          this.rest[options.name].update(
            this.restData[options.name].get.data.id,
            this.restData[options.name].get.data
          )
        }
      }
    }
  )
}

function renderFooter(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcfe',
      style: { 'grid-template-columns': 'auto 1fr 36px 36px auto' }
    },
    [
      renderCloseButton.call(this, h, options),

      this.$scopedSlots.buttons ? this.$scopedSlots.buttons({ data: this.restData[options.name].get.data }) : this.$slots.buttons ? this.$slots.buttons : h('div', { class: 'ff' }),

      renderRefreshButton.call(this, h, options),
      renderRemoveButton.call(this, h, options),
      renderUpdateButton.call(this, h, options)
    ]
  )
}

function renderLeftCol(h, options) {
  if (this.checkPermissions(`advanced.${options.name}.get`, permissionPresets.resellerUp) && this.$route.params.id) {
    if (this.restData[options.name].get.state === states.ready) {
      if (this.restData[options.name].get.data) {
        return h(
          'div',
          {
            class: 'grid grid-gap--8'
          },
          [
            renderHeader.call(this, h, options),
            renderAfterHeader.call(this, h, options),
            renderForm.call(this, h, options),
            renderFooter.call(this, h, options)
          ]
        )
      } else {
        return h('g-empty')
      }
    } else {
      return h(
        'div',
        {
          class: 'ff faic fjcc pa-3'
        },
        [ renderPreLoader.call(this, h, this.restData[options.name].get.state) ]
      )
    }
  }
}

function renderRightCol(h, options) {
  if (this.$route.params.id && this.restData[options.name].get.state === 'ready' && this.restData[options.name].get.data) {
    return this.$scopedSlots.right ? this.$scopedSlots.right({ data: this.restData[options.name].get.data }) : this.$slots.right ? this.$slots.right : h('div')
  }
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid ff',
      style: {
        'grid-template-columns': this.viewport.breakpoint.xl ? `minmax(${this.minWidth}px, ${this.maxWidth}px) minmax(450px, auto)` : '1fr',
        'place-items': 'start stretch'
      }
    },
    [
      renderLeftCol.call(this, h, options),
      renderRightCol.call(this, h, options)
    ]
  )
}
