import VirtualList from 'vue-virtual-scroll-list'

import { states } from '@/utils'

import { serviceName } from '@/components/services/sendernames/utils'

import importCSVButton from '@/components/trees/importCSVButton'

import row from '@/components/misc/virtualScroll/row'

function renderSearch(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.searchValue,
        label: this.getTranslate(`${serviceName}.labels.searchAndAdd`),
        hint: this.getTranslate(`${serviceName}.hints.enterAndAdd`),
        beforeIcon: 'search',
        afterIcon: 'refresh',
        afterIconCallback: () => {
          this.get()
        },
        loading: this.restData[serviceName].find.state === states.loading,
        mode: 'outline',
        dense: true,
        rounded: true,
        clearable: true
      },
      on: {
        input: event => {
          this.search(event)
        },
        keypress: event => {
          if (event.keyCode === 13) {
            if (!~this.proxy.indexOf(this.searchValue)) {
              this.tab = 'checked'
              this.proxy.push(this.searchValue)
              this.searchValue = undefined
            }
          }
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faifs ff pb-3'
    },
    [
      renderSearch.call(this, h),

      h(
        importCSVButton,
        {
          props: {
            value: this.proxy,
            parser: result => {
              for (const array of result) {
                for (const sender of array) {
                  const name = (sender + '').trim()
                  if (!~this.proxy.indexOf(name)) {
                    this.proxy.push(name)
                  }
                }
              }
              this.tab = 'checked'
            }
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      )
    ]
  )
}

function renderPills(h) {
  return h(
    'div',
    {
      class: 'faic pb-3'
    },
    [ 'all', 'checked' ].map(tab => {
      let total = this.proxy.length
      if (tab === 'all') {
        total = this.restData[serviceName].find.pagination.total
      }

      return h(
        'g-button',
        {
          class: 'my-0 ml-0',
          props: {
            flat: this.tab !== tab,
            rounded: true,
            small: true,
            depressed: true,
            color: this.tab === tab ? 'primary' : 'grey',
            label: this.getTranslate(`${serviceName}.pills.${tab}`) + ` ${total}`
          },
          on: {
            click: () => {
              this.tab = tab
            }
          }
        }
      )
    })
  )
}

function renderItem(h, item) {
  if (item) {
    return h(
      'div',
      {
        class: 'tree__row',
        on: {
          click: () => {
            const index = this.proxy.indexOf(item)
            if (~index) {
              this.proxy.splice(index, 1)
            } else {
              this.proxy.push(item)
            }
          }
        }
      },
      [
        h(
          'div',
          {
            class: {
              ['tree__checker']: true,
              ['tree__checker--checked']: ~this.proxy.indexOf(item)
            }
          }
        ),

        h(
          'div',
          {
            class: 'tree__content'
          },
          [
            h(
              'div',
              {
                class: 'tree__title'
              },
              item
            )
          ]
        )
      ]
    )
  }
}

function renderChecked(h) {
  if (this.tab === 'checked') {
    if (Array.isArray(this.checked)) {
      if (this.checked.length) {
        return h(
          'div',
          {
            class: 'tree__level tree__level--special'
          },
          this.checked.sort((a, b) => a - b).map(item => renderItem.call(this, h, item))
        )
      } else {
        return h(
          'div',
          {
            class: 'tree__empty'
          },
          [ h('g-empty') ]
        )
      }
    }
  }
}
function renderItems(h) {
  if (this.tab === 'all') {
    if (Array.isArray(this.restData[serviceName].find.data)) {
      if (this.restData[serviceName].find.data.length) {
        return h(
          VirtualList,
          {
            class: 'tree__level tree__level--1',
            props: {
              dataKey: 'name',
              dataSources: this.restData[serviceName].find.data,
              dataComponent: row,
              itemScopedSlots: {
                item: source => renderItem.call(this, h, source.name)
              }
            },
            on: {
              tobottom: () => {
                const loadMore = this.restData[serviceName].find.pagination.limit + this.restData[serviceName].find.pagination.offset < this.restData[serviceName].find.pagination.total
                if (this.restData[serviceName].find.state === states.ready && loadMore) {
                  this.restData[serviceName].find.pagination.offset += this.restData[serviceName].find.pagination.limit
                }
              }
            }
          }
        )
      } else {
        return h(
          'div',
          {
            class: 'tree__empty'
          },
          [ h('g-empty') ]
        )
      }
    }
  }
}

function renderBody(h) {
  return h(
    'div',
    {
      class: 'tree'
    },
    [
      renderPills.call(this, h),
      renderChecked.call(this, h),
      renderItems.call(this, h)
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
