import render from './render'

export default {
  name: 'Type',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    $type() {
      switch (this.value.type) {
        case 'smsping': return 'sms'
        case 'apisender': return 'whatsapp'
        case 'whatsappchat':
        case 'viberchat':
        case 'vkchat':
        case 'telegramchat':
        case 'facebookchat': return 'chat'
        case 'hlr': return 'search-settings'
        default: return this.value.type
      }
    }
  },

  render
}
