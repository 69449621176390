import { getUrlToStorage, permissionPresets, states } from '@/utils'

import SpacerCell from '@/components/misc/SpacerCell'
import FileManager from '@/components/file/manager'
import ConfirmRemoveButton from '@/components/misc/ConfirmRemoveButton'

import { serviceName } from '@/components/services/documentTemplates/utils'

function renderAddButton(h) {
  if (this.checkPermissions(`advanced.${serviceName}.create`, [ 'reseller', true ])) {
    return h(
      'g-button',
      {
        props: {
          label: this.getTranslate(`${serviceName}.buttons.add.template`),
          flat: true,
          rounded: true,
          color: 'primary'
        },
        on: {
          click: () => {
            this.currentType = this.type
            this.showFileManager = true
          }
        }
      }
    )
  }
}

function renderEmptyState(h) {
  return h(
    'div',
    {
      class: 'document-templates__button__content'
    },
    [
      h(
        'span',
        {
          class: 'subtitle-1'
        },
        this.getTranslate(`${serviceName}.titles.empty.${this.type}`)
      ),

      h('div', { class: 'ff' }),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe'
        },
        [ renderAddButton.call(this, h) ]
      )
    ]
  )
}

function renderFileTitle(h) {
  if (this.document && this.document.File && this.document.File.title) {
    return h(SpacerCell, { class: 'caption text--grey' }, this.document.File.title)
  }
}

function renderEditFileButton(h) {
  if (this.document && this.document.File && this.document.File.id) {
    return h(
      'g-button',
      {
        props: {
          icon: 'edit',
          flat: true,
          loading: this.editFileProgress,
          disabled: !this.checkPermissions('advanced.storage.update', permissionPresets.meUp)
        },
        on: {
          click: () => {
            this.editFile()
          }
        }
      }
    )
  }
}

function renderDownloadFileButton(h) {
  if (this.document && this.document.File && this.document.File.id) {
    return h(
      'g-button',
      {
        props: {
          icon: 'file_download',
          flat: true,
          disabled: !this.checkPermissions('advanced.storage.download', permissionPresets.meUp)
        },
        on: {
          click: () => {
            window.location.href = getUrlToStorage([ this.document.File.OwnerId || this.document.File.Owner && this.document.File.Owner.id, this.document.File.id ])
          }
        }
      }
    )
  }
}

function renderContent(h) {
  if (this.document) {
    return h(
      'div',
      {
        class: 'ff faic'
      },
      [
        h(
          'div',
          {
            class: 'fc ff h--100'
          },
          [
            h(
              'div',
              {
                class: 'subtitle-1 pb-1'
              },
              this.getTranslate(`${serviceName}.titles.${this.type}`)
            ),

            renderFileTitle.call(this, h),

            h('div', { class: 'ff' }),

            h(
              'g-card-actions',
              {
                class: 'fjcfe facfe'
              },
              [
                renderEditFileButton.call(this, h),
                renderDownloadFileButton.call(this, h),

                h(
                  ConfirmRemoveButton,
                  {
                    props: {
                      size: 21,
                      callback: () => {
                        this.rest[serviceName].remove(this.document.id)
                      }
                    }
                  }
                )
              ]
            )
          ]
        )
      ]
    )
  } else {
    if (~[
      this.restData[serviceName].find.state,
      this.restData[serviceName].create.state,
      this.restData[serviceName].remove.state
    ].indexOf(states.loading)) {
      return h(
        'div',
        {
          class: 'fjcc facc faic'
        },
        [ h('g-progress', { props: { indeterminate: true } }) ]
      )
    }

    return renderEmptyState.call(this, h)
  }
}

function renderIFrame(h) {
  if (this.googleFileURL) {
    return h(
      'iframe',
      {
        attrs: {
          src: this.googleFileURL,
          frameborder: 0
        },
        class: 'position-absolute h--100 w--100'
      }
    )
  }
}

function renderEditFileDialog(h) {
  if (this.document && this.document.File && this.document.File.id) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: true,
          value: this.showEditFileDialog,
          fullscreen: true
        },
        on: {
          input: event => {
            this.showEditFileDialog = event
          }
        }
      },
      [
        renderIFrame.call(this, h),

        h(
          'g-card-actions',
          {
            class: 'fjcfe facfe',
            slot: 'footer'
          },
          [
            h(
              'g-button',
              {
                props: {
                  label: this.getTranslate('misc.buttons.save'),
                  rounded: true,
                  depressed: true,
                  loading: this.uploadFileProgress,
                  disabled: this.uploadFileProgress,
                  color: 'primary'
                },
                on: {
                  click: () => {
                    this.saveFile()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'document-templates__button': true,
        'document-templates__button--empty': !this.document
      }
    },
    [
      renderContent.call(this, h),

      h(
        FileManager,
        {
          props: {
            show: this.showFileManager,
            flat: true,
            readonly: true,
            embedded: true,
            types: [ 'doc', 'docx' ]
          },
          on: {
            show: event => {
              this.showFileManager = event
            },
            input: event => {
              this.showFileManager = false
              this.createDocumentTemplate(event.id)
            }
          }
        }
      ),

      renderEditFileDialog.call(this, h)
    ]
  )
}
