import Vue from 'vue'

import { colors, generateUniqueKey, modes, states } from '@/utils'

import sourceBox from '@/components/source/box'
import button from '@/components/button'
import refresh from '@/components/button/refresh'
import buttonCopy from '@/components/button/copy'
import cloneButton from '@/components/button/clone'
import EntityInfo from '@/components/misc/EntityInfo'
import pagination from '@/components/misc/pagination'
import componentNotFound from '@/components/misc/componentNotFound'
import viewChanger from '@/components/serviceTemplate/viewChanger'
import sizeChanger from '@/components/serviceTemplate/sizeChanger'
import overflowString from '@/components/misc/overflowString'
import preloader from '@/components/misc/preloader'

function renderRemoveDialog(h, options) {
  if (options.remove !== false) {
    return h(
      'g-dialog',
      {
        props: {
          value: this.showRemoveDialog,
          rounded: true,
          maxWidth: 400
        },
        on: {
          input: event => {
            this.showRemoveDialog = event
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'headline pa-3',
            slot: 'header'
          },
          this.getTranslate('commons.titles.confirm.remove')
        ),

        h(
          'div',
          {
            class: 'body-1 pa-3'
          },
          this.getTranslate('commons.contents.confirm.remove')
        ),

        h(
          'div',
          {
            class: 'fjcfe grid-gap--8 pa-2',
            style: { gridTemplateColumns: 'repeat(2, auto)' },
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  disabled: this.restData[options.serviceName].remove.state === states.loading,
                  mode: modes.flat
                },
                on: {
                  click: () => {
                    this.showRemoveDialog = false
                  }
                }
              }
            ),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.remove'),
                  loading: this.restData[options.serviceName].remove.state === states.loading,
                  disabled: this.restData[options.serviceName].remove.state === states.loading || !this.canRemove,
                  color: colors.error
                },
                on: {
                  click: () => {
                    this.remove()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderRemoveButton(h, options) {
  if (options.remove !== false) {
    return h(
      button,
      {
        props: {
          icon: 'delete',
          color: colors.error,
          mode: modes.flat,
          disabled: !this.canRemove
        },
        on: {
          click: () => {
            this.showRemoveDialog = true
          }
        },
        scopedSlots: {
          dialog: () => renderRemoveDialog.call(this, h, options)
        }
      }
    )
  }
}

function renderSupportButton(h, options) {
  if (options.has.support) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('misc.buttons.support'),
          color: colors.primary,
          mode: modes.flat
        },
        on: {
          click: () => {
            Vue.router.push({ name: 'support' })
          }
        }
      }
    )
  }
}

function renderCreateDialog(h, options) {
  if (options.create !== false) {
    return h(
      'g-dialog',
      {
        props: {
          value: this.showCreateDialog,
          rounded: true,
          maxWidth: options.width,

          ...options.dialog.create
        },
        on: {
          input: event => {
            this.showCreateDialog = event
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'headline pa-3',
            slot: 'header'
          },
          this.getTranslate(`${options.serviceNameViaPoint}.titles.create`)
        ),

        renderForm.call(this, h, options, this.restData[options.serviceName].create.data, 'create'),

        h(
          'div',
          {
            class: 'fjcfe grid-gap--8 pa-2',
            slot: 'footer'
          },
          [
            renderSupportButton.call(this, h, options),

            h('div', { class: 'ff' }),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  mode: modes.flat,
                  disabled: this.restData[options.serviceName].create.state === states.loading
                },
                on: {
                  click: () => {
                    this.showCreateDialog = false
                  }
                }
              }
            ),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  color: colors.primary,
                  loading: this.restData[options.serviceName].create.state === states.loading,
                  disabled: this.restData[options.serviceName].create.state === states.loading || !this.restData[options.serviceName].create.isValid || !this.canCreate
                },
                on: {
                  click: () => {
                    this.create()
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderCreateButton(h, options) {
  if (options.remove !== false) {
    return h(
      button,
      {
        props: {
          icon: 'add',
          mode: modes.flat,
          color: colors.secondary,
          disabled: !this.canCreate
        },
        on: {
          click: () => {
            this.showCreateDialog = true
          }
        },
        scopedSlots: {
          dialog: () => renderCreateDialog.call(this, h, options)
        }
      }
    )
  }
}

function renderSourceDialog(h, options) {
  if (this.showSourceDialog && options.has.source) {
    return h(
      'g-dialog',
      {
        props: {
          value: this.showSourceDialog,
          rounded: this.viewport.breakpoint.mdUp,
          maxWidth: this.viewport.breakpoint.mdUp ? 600 : '100%'
        },
        on: {
          input: event => {
            this.showSourceDialog = event
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'headline pa-3',
            slot: 'header'
          },
          this.getTranslate('misc.sourceData')
        ),
        h(
          'div',
          {
            class: 'px-2'
          },
          [
            h(
              sourceBox,
              {
                props: {
                  value: this.restData[options.serviceName].get.data,
                  rounded: false
                }
              }
            )
          ]
        ),

        h(
          'div',
          {
            class: 'pa-2 fjcfe',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: modes.flat,
                  disabled: this.restData[options.serviceName].create.state === states.loading
                },
                on: {
                  click: () => {
                    this.showSourceDialog = !this.showSourceDialog
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderSourceButton(h, options) {
  if (options.has.source) {
    return h(
      button,
      {
        props: {
          icon: 'code',
          mode: modes.flat,
          color: colors.primary
        },
        on: {
          click: () => {
            this.showSourceDialog = true
          }
        },
        scopedSlots: {
          dialog: () => renderSourceDialog.call(this, h, options)
        }
      }
    )
  }
}

function renderCloneButton(h, options) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/after/title/clone`).default,
      {
        props: {
          value: this.restData[options.serviceName].get.data
        }
      }
    )
  } catch (error) {
    if (options.has.clone) {
      return h(
        cloneButton,
        {
          props: {
            service: options.serviceName,
            value: this.restData[options.serviceName].get.data,
            clone: () => this.clone()
          }
        }
      )
    }
  }
}
function renderUpdateButton(h, options, label) {
  if (options.update !== false) {
    return h(
      button,
      {
        props: {
          icon: 'save',
          mode: !label ? modes.flat : undefined,
          color: colors.primary,
          label: label ? this.getTranslate('misc.buttons.save') : undefined,
          tooltip: label ? undefined : this.getTranslate('misc.buttons.save'),
          loading: this.restData[options.serviceName].update.state === states.loading,
          disabled: this.restData[options.serviceName].update.state === states.loading || !this.restData[options.serviceName].update.isValid || !this.canUpdate
        },
        on: {
          click: () => {
            this.update()
          }
        }
      }
    )
  }
}

function renderViewChanger(h, options) {
  if (options.has.view) {
    return h(
      viewChanger,
      {
        props: {
          value: this.view,
          views: options.views,
          serviceName: options.serviceName
        },
        on: {
          input: event => {
            this.view = event
          }
        }
      }
    )
  }
}
function renderSizeChanger(h, options) {
  if (options.has.size) {
    if (this.view === 'cards') {
      return h(
        sizeChanger,
        {
          props: {
            value: this.size,
            serviceName: options.serviceName
          },
          on: {
            input: event => {
              this.size = event
            }
          }
        }
      )
    }
  }
}
function renderSensitivityButton(h, options) {
  if (options.has.sensitivity) {
    return h(
      button,
      {
        props: {
          mode: modes.flat,
          icon: this.sensitivity ? 'visibility' : 'visibility_off',
          color: this.sensitivity ? 'success' : 'gray',
          tooltip: this.getTranslate('commons.tooltips.sensitivity')
        },
        on: {
          click: () => {
            this.toggleSensitivity()
          }
        }
      }
    )
  }
}

function renderPagination(h, options) {
  if (!this.$route.params.id) {
    if (this.restData[options.serviceName].find.pagination.total) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8 faic',
          style: { 'grid-template-columns': '1fr auto 36px' }
        },
        [
          h('div', { class: 'ff' }),

          h(
            pagination,
            {
              props: {
                value: this.restData[options.serviceName].find.pagination,
                rowsPerPage: [ 24, 48, 96 ],
                service: options.serviceName
              },
              on: {
                input: event => {
                  this.restData[options.serviceName].find.pagination = event
                }
              }
            }
          ),

          h(
            refresh,
            {
              class: 'ma-0',
              props: {
                state: this.restData[options.serviceName].find.state,
                shortClickMethod: () => this.rest[options.serviceName].find(),
                longClickMethod: () => this.rest[options.serviceName].find({}, { noCache: true })
              }
            }
          )
        ]
      )
    }
  }
}

function renderSearch(h, options) {
  if (options.has.search) {
    return h(
      'g-text-field',
      {
        props: {
          value: this.restData[options.serviceName].find.filter.$search,
          label: this.getTranslate(`${options.serviceNameViaPoint}.labels.search`),
          suffix: this.restData[options.serviceName].find.pagination.total,
          mode: 'outline',
          afterIcon: 'search',
          afterIconCallback: () => this.rest[options.serviceName].find(),
          rounded: true,
          dense: true,
          details: false,
          clearable: true
        },
        on: {
          input: event => {
            this.restData[options.serviceName].find.filter.$search = event
          }
        }
      }
    )
  }
}

function renderFilter(h, options) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/filter`).default,
      {
        props: {
          value: this.restData[options.serviceName].find.filter
        },
        on: {
          input: event => {
            this.restData[options.serviceName].find.filter = event
          }
        }
      }
    )
  } catch (error) {
    return
  }
}

function renderHeader(h, options) {
  if (!this.$route.params.id) {
    return h(
      'div',
      {
        class: 'service-template-header'
      },
      [
        h(
          'div',
          {
            class: 'service-template-header__content'
          },
          [
            h(
              'div',
              {
                class: 'service-template-header__fields',
                style: { gridTemplateColumns: this.headerFieldsGridTemplateColumns }
              },
              [
                renderSearch.call(this, h, options),
                renderFilter.call(this, h, options)
              ]
            ),

            h(
              'div',
              {
                class: 'service-template-header__actions',
                style: { gridTemplateColumns: this.headerActionsGridTemplateColumns }
              },
              [
                renderCreateButton.call(this, h, options),
                renderSensitivityButton.call(this, h, options),
                renderViewChanger.call(this, h, options),
                renderSizeChanger.call(this, h, options)
              ]
            )
          ]
        ),

        renderPagination.call(this, h, options)
      ]
    )
  }
}

function renderItemsByView(h, options, items) {
  let component = null
  try {
    component = require(`@/components/services/${options.serviceName}/item`).default
  } catch (error) {
    component = require('@/components/serviceTemplate/item').default(options)
  }

  switch (this.view) {
    case 'table': {
      return h(
        'g-card',
        {
          props: {
            outline: true,
            rounded: true
          }
        },
        [
          h(
            'g-table',
            {
              props: {
                items,
                noDataText: this.getTranslate(`${options.serviceNameViaPoint}.hints.notFound`)
              },
              scopedSlots: {
                items: ({ item }) => {
                  return h(
                    component,
                    {
                      props: {
                        value: item,
                        view: 'row',
                        size: this.size
                      },
                      key: item.id || generateUniqueKey() || Date.now()
                    }
                  )
                }
              }
            }
          )
        ]
      )
    }
    case 'cards':
    case 'panels': {
      return h(
        'div',
        {
          class: `service-template-${this.view === 'cards' ? 'cards' : 'panels'}`
        },
        items.map(item => {
          return h(
            component,
            {
              props: {
                value: item,
                view: this.view === 'cards' ? 'card' : 'panel',
                size: this.size,
                sensitivity: this.sensitivity
              },
              key: item.id || generateUniqueKey() || Date.now()
            }
          )
        })
      )
    }
  }
}

function renderBackButton(h) {
  return h(
    button,
    {
      props: {
        icon: 'keyboard_arrow_left',
        mode: modes.flat
      },
      on: {
        click: () => {
          this.$router.go(-1)
        }
      }
    }
  )
}

function renderCopyLinkForSlackButton(h, { serviceNameViaPoint }) {
  if (this.checkPermissions(`advanced.${serviceNameViaPoint}.get`)) {
    return h(
      buttonCopy,
      {
        props: {
          value: this.titleURLSlack,
          marginless: true,
          icon: 'slack',
          iconLibrary: 'icofont'
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate('commons.tooltips.copyLinkForSlack')
            }
          }
        ]
      }
    )
  }
}

function renderAfterTitleComponent(h, options) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/after/title`).default,
      {
        props: {
          value: this.restData[options.serviceName].get.data,
          get: () => this.rest[options.serviceName].get(this.restData[options.serviceName].get.data.id)
        }
      }
    )
  } catch (error) {
    // ignore
  }
}

function renderAfterTitle(h, options) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [
      renderAfterTitleComponent.call(this, h, options),
      renderCloneButton.call(this, h, options),
      renderCopyLinkForSlackButton.call(this, h, options)
    ]
  )
}

function renderTitle(h, options) {
  return h(
    overflowString,
    {
      class: { 'link link--passive': options.has.copy.title && this.$title },
      props: {
        value: this.title,
        color: !this.$title ? colors.grey : undefined,
        font: { size: 18 }
      },
      on: {
        click: () => {
          if (this.$title) {
            this.copy('title', this.$title)
          }
        }
      }
    }
  )
}
function renderId(h, options) {
  const id = this.restData[options.serviceName].get.data.id
  if (id) {
    return h(
      overflowString,
      {
        class: { 'link link--passive': options.has.copy.id },
        props: {
          value: id,
          color: colors.grey,
          font: { size: 10 }
        },
        on: {
          click: () => this.copy('id', id)
        }
      }
    )
  }
}
function renderTitleBlock(h, options) {
  if (this.restData[options.serviceName].get.state === states.ready) {
    if (this.restData[options.serviceName].get.data) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': '1fr auto' }
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--0 faic'
            },
            [
              renderTitle.call(this, h, options),
              renderId.call(this, h, options)
            ]
          ),

          h(
            'div',
            {
              class: 'faic fjcfe'
            },
            [ renderAfterTitle.call(this, h, options) ]
          )
        ]
      )
    }
  } else if (this.restData[options.serviceName].get.state === states.loading) {
    return h(
      'div',
      {
        class: 'fjcc facc faic square--36 ml-3'
      },
      [ h('g-progress', { props: { indeterminate: true } }) ]
    )
  }
}

function renderFormHeader(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '38px 1fr' }
    },
    [
      renderBackButton.call(this, h, options),
      renderTitleBlock.call(this, h, options)
    ]
  )
}
function renderForm(h, options, data, method) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/form`).default,
      {
        class: options.formPaddles ? '' : 'pa-3',
        props: {
          value: data,
          isNew: method === 'create',
          updatedData: this.updatedData
        },
        on: {
          input: event => {
            data = event
          },
          submit: () => {
            if (typeof this[method] === 'function') {
              this[method]()
            }
          },
          validation: event => {
            if (
              this.restData &&
              this.restData[options.serviceName] &&
              typeof this.restData[options.serviceName][method] !== 'undefined'
            ) {
              this.restData[options.serviceName][method].isValid = event
            }
          }
        }
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}
function renderFormContent(h, options) {
  let { accent, accentColor, accentColorName, accentColorNameSelector, accentSize, accentPosition } = options.form || {}

  accentColorNameSelector ? accentColorName = accentColorNameSelector(this.restData[options.serviceName].get.data) : accentColorName

  return h(
    'g-card',
    {
      props: {
        outline: true,
        rounded: true,
        maxWidth: options.width,
        width: '100%',
        accent,
        accentColor,
        accentColorName,
        accentSize,
        accentPosition
      }
    },
    [
      renderForm.call(this, h, options, this.restData[options.serviceName].get.data, 'update'),

      h('g-divider'),

      h(
        EntityInfo,
        {
          class: 'my-3',
          props: { value: this.restData[options.serviceName].get.data }
        }
      )
    ]
  )
}
function renderRefreshButton(h, options) {
  return h(
    refresh,
    {
      class: 'ma-0',
      props: {
        state: this.restData[options.serviceName].get.state,
        shortClickMethod: () => this.rest[options.serviceName].get(this.restData[options.serviceName].get.data.id),
        longClickMethod: () => this.rest[options.serviceName].get(this.restData[options.serviceName].get.data.id, { noCache: true })
      }
    }
  )
}
function renderFooterActions(h, options) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/footer/actions`).default,
      {
        props: {
          value: this.restData[options.serviceName].get.data,
          get: () => this.rest[options.serviceName].get(this.restData[options.serviceName].get.data.id)
        }
      }
    )
  } catch (error) {
    // ignore
  }
}
function renderFormFooter(h, options) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8',
      style: {
        'max-width': `${options.width}px`,
        width: '100%'
      }
    },
    [
      renderRemoveButton.call(this, h, options),

      h('div', { class: 'ff' }),

      renderFooterActions.call(this, h, options),
      renderSourceButton.call(this, h, options),
      renderRefreshButton.call(this, h, options),
      renderUpdateButton.call(this, h, options, true)
    ]
  )
}

function renderFirstCol(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderFormHeader.call(this, h, options),
      renderFormContent.call(this, h, options),
      renderFormFooter.call(this, h, options)
    ]
  )
}
function renderSecondCol(h, options, data) {
  try {
    return h(
      require(`@/components/services/${options.serviceName}/cols/second`).default,
      {
        props: { value: data }
      }
    )
  } catch (error) {
    return h('div')
  }
}
function renderThirdCol(h, options) {
  try {
    return h(require(`@/components/services/${options.serviceName}/cols/third`).default)
  } catch (error) {
    // ignore
  }
}

function renderItems(h, options) {
  if (this.restData[options.serviceName].find.state === states.ready) {
    if (this.restData[options.serviceName].find.pagination.total > 0) {
      return renderItemsByView.call(this, h, options, this.restData[options.serviceName].find.data)
    } else {
      return h(
        'div',
        {
          class: 'fjcc facc pa-3'
        },
        [ h('g-empty') ]
      )
    }
  } else if (this.restData[options.serviceName].find.state === states.loading) {
    return h(
      'div',
      {
        class: 'fjcc facc pa-5'
      },
      [ h(preloader, { props: { value: true } }) ]
    )
  }
}

function renderContent(h, options) {
  if (this.$route.params.id) {
    switch (this.restData[options.serviceName].get.state) {
      case states.ready: {
        let cols = 1
        if (typeof renderThirdCol.call(this, h, options) === 'object') {
          cols = 2
        }
        if (this.restData[options.serviceName].get.data) {
          return h(
            'div',
            {
              class: 'fc ff'
            },
            [
              h(
                'div',
                {
                  class: 'grid faifs',
                  style: {
                    'grid-template-columns': this.viewport.breakpoint.mdUp ? `${options.width}px repeat(${cols}, minmax(400px, 600px))` : undefined
                  }
                },
                [
                  renderFirstCol.call(this, h, options),
                  renderSecondCol.call(this, h, options, this.restData[options.serviceName].get.data),
                  renderThirdCol.call(this, h, options)
                ]
              )
            ]
          )
        } else {
          return h('g-empty')
        }
      }
      case states.loading: {
        return h(
          'g-progress',
          {
            props: {
              indeterminate: true
            }
          }
        )
      }
      case states.error: {
        return h('g-empty')
      }
    }
  } else {
    return renderItems.call(this, h, options)
  }
}

function renderFooter(h, options) {
  return renderPagination.call(this, h, options)
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderHeader.call(this, h, options),
      renderContent.call(this, h, options),
      renderFooter.call(this, h, options)
    ]
  )
}
