import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep } from 'lodash'
import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

const defaultPrice = 0.1
const defaultValue = {
  price: [ defaultPrice ],
  action: 'decrease',
  rules: []
}

export default {
  name: 'TariffsRules',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    source: String
  },

  data() {
    return {
      groupClass: {
        'text--primary': false
      }
    }
  },

  methods: {
    add() {
      const data = cloneDeep(defaultValue)
      if (this.source === SENDING_TYPES.voice) {
        if (data.price.length < 2) {
          data.price.push(defaultPrice)
        }
      }
      this.proxy.push(data)
    }
  },

  render
}
