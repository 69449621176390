import { PAYMENTS_METHODS, PAYMENTS_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { icons, inputModes, modes, services } from '@/utils'

import button from '@/components/button'
import OwnerSelect from '@/components/misc/OwnerSelect'
import dateTimeFilter from '@/components/filter/date'
import createdAtPreset from '@/components/misc/createdAtPreset'

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderStatusSelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.status,
        label: this.getTranslate(`${services.payments}.labels.status`),
        items: PAYMENTS_STATUSES.map(status => {
          return {
            title: this.getTranslate(`${services.payments}.statuses.${status}`),
            value: status
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.status = event
        }
      }
    }
  )
}

function renderMethodSelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.method,
        label: this.getTranslate(`${services.payments}.labels.method`),
        items: PAYMENTS_METHODS.map(method => {
          return {
            title: this.getTranslate(`${services.payments}.methods.${method}`),
            value: method
          }
        }),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.method = event
        }
      }
    }
  )
}

function renderDatePicker(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      h(
        'g-menu',
        {
          props: {
            rounded: true,
            placement: 'bottom-end',
            closeOnContentClick: false,
            minWidth: 300
          }
        },
        [
          h(
            button,
            {
              props: {
                icon: icons.date_range,
                mode: modes.flat
              },
              slot: 'activator'
            }
          ),

          h(
            'div',
            {
              class: 'grid grid-gap--8 pa-2'
            },
            [
              h(
                createdAtPreset,
                {
                  props: {
                    value: this.proxy.createdAtPreset
                  },
                  on: {
                    input: event => {
                      if (event) {
                        this.proxy.createdAt = {
                          $gt: undefined,
                          $lt: undefined
                        }
                      }

                      this.proxy.createdAtPreset = event
                    }
                  }
                }
              ),

              h(
                dateTimeFilter,
                {
                  props: {
                    value: this.proxy.createdAt
                  },
                  on: {
                    input: event => {
                      if (event) {
                        this.proxy.createdAtPreset = undefined
                      }

                      this.proxy.createdAt = event
                    }
                  }
                }
              )
            ]
          )
        ]
      ),

      this.$scopedSlots.buttons ? this.$scopedSlots.buttons() : this.$slots.buttons
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'faic grid-gap--8 pa-2': true,
        grid: this.viewport.breakpoint.xs
      }
    },
    [
      renderOwnerSelect.call(this, h),
      renderStatusSelect.call(this, h),
      renderMethodSelect.call(this, h),
      renderDatePicker.call(this, h)
    ]
  )
}
