import squircle from '@/components/icon/squircle'

export default function(h) {
  if (this.content) {
    return h(
      'div',
      {
        class: {
          ['info']: true,
          [`info--${this.color}`]: !!this.color
        }
      },
      [
        h(
          squircle,
          {
            props: {
              icon: this.icon,
              color: this.color
            }
          }
        ),

        h(
          'div',
          {
            class: 'info__text',
            domProps: { innerHTML: this.content }
          }
        )
      ]
    )
  }
}
