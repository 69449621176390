import { services } from '@/utils'

import messageRulesForm from '@/components/services/messageRules/form'
import messageRuleGroupsForm from '@/components/services/messageRuleGroups/form'

function renderMessageRuleCreateDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showMessageRuleCreateDialog,
        rounded: true,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showMessageRuleCreateDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic pt-3 px-3 text--grey headline',
          slot: 'header'
        },
        this.getTranslate('messageRules.titles.create')
      ),

      h(
        'div',
        {
          class: 'pa-3'
        },
        [
          h(
            messageRulesForm,
            {
              props: {
                value: this.messageRulePayload
              },
              on: {
                input: event => {
                  this.messageRulePayload = event
                },
                validation: event => {
                  this.messageRuleValidation = event
                }
              }
            }
          )
        ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.showMessageRuleCreateDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                disabled: !this.messageRuleValidation,
                color: 'primary',
                label: this.getTranslate('misc.buttons.create')
              },
              on: {
                click: () => {
                  this.createMessageRule()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderButtonCreateMessageRule(h) {
  return h(
    'g-button',
    {
      props: {
        rounded: true,
        depressed: true,
        loading: this.loading,
        disabled: this.disabled,
        icon: 'add',
        color: 'secondary',
        label: this.getTranslate(`${services.messageRuleGroups}.buttons.messageRuleCreate`)
      },
      scopedSlots: {
        dialog: () => renderMessageRuleCreateDialog.call(this, h)
      },
      on: {
        click: () => {
          this.showMessageRuleCreateDialog = true
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic pt-3 px-3 text--grey headline',
      slot: 'header'
    },
    this.getTranslate(`${services.messageRuleGroups}.dialogs.title.full`)
  )
}

function renderBody(h) {
  return h(
    'div',
    {
      class: 'pa-3'
    },
    [
      h(
        messageRuleGroupsForm,
        {
          props: {
            value: this.messageRuleGroupPayload
          },
          on: {
            input: event => {
              this.messageRuleGroupPayload = event
            }
          }
        }
      )
    ]
  )
}

function renderFooter(h) {
  return h(
    'g-card-actions',
    {
      class: 'fjcfe facfe',
      slot: 'footer'
    },
    [
      h(
        'g-button',
        {
          props: {
            flat: true,
            rounded: true,
            disabled: this.loading,
            label: this.getTranslate('misc.buttons.cancel')
          },
          on: {
            click: () => {
              this.$emit('show', false)
            }
          }
        }
      ),

      h('div', { class: 'ff' }),

      renderButtonCreateMessageRule.call(this, h),

      h(
        'g-button',
        {
          props: {
            rounded: true,
            depressed: true,
            loading: this.loading,
            disabled: this.disabled || !this.validation,
            color: 'primary',
            label: this.getTranslate('misc.buttons.create')
          },
          on: {
            click: () => {
              this.create()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.show,
        rounded: true,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
