import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep } from 'lodash'
import { WEBHOOK_EVENTS } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default function(options) {
  return {
    name: 'Webhooks',

    mixins: [ proxy({ type: 'object' }) ],

    props: {
      type: String
    },

    data() {
      const show = {}
      for (const event of WEBHOOK_EVENTS) {
        show[event] = false
      }

      return {
        show,

        payload: undefined
      }
    },

    mounted() {
      this.payload = cloneDeep(this.proxy)
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
