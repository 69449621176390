import { REPORTS_ENCODINGS } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, modes, permissionPresets } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import info from '@/components/misc/info'
import button from '@/components/button'

const delimiters = [
  {
    title: 'misc.delimiters.comma',
    value: ','
  },
  {
    title: 'misc.delimiters.semicolon',
    value: ';'
  }
]

function renderInfo(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [ 'beforeRequest', 'repeatRequest', 'afterRequest' ].map(item => {
      let color = colors.info
      switch (item) {
        case 'repeatRequest': {
          color = 'error'
          break
        }
        case 'afterRequest': {
          color = 'warning'
          break
        }
      }

      return h(
        info,
        {
          props: {
            value: this.getTranslate(`reports.warnings.${item}`),
            color
          }
        }
      )
    })
  )
}

function renderFlagByPreset(h) {
  switch (this.preset) {
    case 'sendings.details': {
      return h(
        'g-switch',
        {
          props: {
            value: this.flags.includeRefunded,
            label: this.getTranslate('reports.labels.includeRefunded'),
            disabled: this.loading
          },
          on: {
            input: event => {
              this.flags.includeRefunded = event
            }
          }
        }
      )
    }
  }
}
function renderFlags(h) {
  if (this.checkPermissions('advanced.reports.generate', permissionPresets.true)) {
    return h(
      'div',
      {
        class: 'grid grid-cols--2 grid-gap--8 pa-2'
      },
      [ renderFlagByPreset.call(this, h) ]
    )
  }
}

function renderForm(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--2 grid-gap--8 pa-2'
    },
    [
      h(
        'g-select',
        {
          props: {
            value: this.encoding,
            hint: this.getTranslate('reports.labels.encoding'),
            items: REPORTS_ENCODINGS.map(encoding => ({
              title: this.getTranslate(`misc.encodings.${encoding}`),
              value: encoding
            })),
            disabled: this.loading,
            mode: 'outline',
            dense: true,
            rounded: true
          },
          on: {
            input: event => {
              this.encoding = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.delimiter,
            hint: this.getTranslate('reports.labels.delimiter'),
            items: delimiters.map(({ title, value }) => ({
              title: this.getTranslate(title),
              value
            })),
            disabled: this.loading,
            mode: 'outline',
            dense: true,
            rounded: true
          },
          on: {
            input: event => {
              this.delimiter = event
            }
          }
        }
      )
    ]
  )
}

function renderFooter(h) {
  return h(
    'g-card-actions',
    {
      class: 'flex-end',
      slot: 'footer'
    },
    [
      h(
        'g-button',
        {
          props: {
            label: this.getTranslate('misc.buttons.cancel'),
            flat: true,
            rounded: true,
            disabled: this.loading
          },
          on: {
            click: () => {
              this.proxy = false
            }
          }
        }
      ),
      h(
        'g-button',
        {
          props: {
            label: this.getTranslate('misc.buttons.goto.requestAndFiles'),
            color: 'primary',
            outline: true,
            rounded: true
          },
          on: {
            click: async () => {
              await this.getReport()
              this.$router.push({ name: 'files' })
            }
          }
        }
      ),
      h(
        'g-button',
        {
          props: {
            label: this.getTranslate('misc.buttons.reports.request'),
            rounded: true,
            depressed: true,
            loading: this.loading,
            disabled: this.loading,
            color: 'primary'
          },
          on: {
            click: () => {
              this.getReport()
            }
          }
        }
      )
    ]
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.proxy,
        rounded: true,
        maxWidth: 600
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    },
    [
      renderInfo.call(this, h),

      h(
        HeadTitle,
        {
          props: { value: this.getTranslate('reports.title') },
          slot: 'header'
        }
      ),

      renderForm.call(this, h),
      renderFlags.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  switch (this.type) {
    case 'list-item': {
      return h(
        'g-list-item',
        {
          props: {
            dense: true,
            label: this.getTranslate('misc.buttons.reports.get'),
            disabled: !this.checkPermissions('advanced.reports.generate', permissionPresets.meUp)
          },
          on: {
            click: () => {
              this.proxy = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    }

    case 'button':
    default: {
      return h(
        button,
        {
          props: {
            icon: 'cloud_download',
            mode: modes.flat,
            label: !this.icon && this.viewport.breakpoint.mdUp ? this.getTranslate('misc.buttons.reports.get') : undefined,
            disabled: !this.checkPermissions('advanced.reports.generate', permissionPresets.meUp)
          },
          on: {
            click: () => {
              this.proxy = true
            }
          },
          scopedSlots: {
            dialog: () => renderDialog.call(this, h)
          }
        }
      )
    }
  }
}
