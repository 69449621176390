import { routerQueryReplace } from '@/utils'

function renderCards(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--2 faifs'
    },
    this.tabs.map(tab => {
      return h(
        'div',
        {
          class: 'fc ff'
        },
        [
          h(
            'div',
            {
              class: 'faic headline pa-3'
            },
            [ this.getTranslate(`payments.tabs.${tab}`) ]
          ),

          h(
            'g-card',
            {
              class: 'ff',
              props: {
                rounded: true,
                outline: true
              }
            },
            [ h(require(`@/components/services/${tab}/list`).default({ isPage: true })) ]
          )
        ]
      )
    })
  )
}

function renderTabs(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'g-tabs-header',
        {
          class: 'pl-3',
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`payments.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      ),

      h(
        'g-card',
        {
          props: {
            rounded: true,
            outline: true
          }
        },
        [
          h(
            'g-tabs',
            {
              props: { value: this.currentTab }
            },
            this.tabs.map(tab => {
              return h(
                require(`@/components/services/${tab}/list`).default({ isPage: true }),
                {
                  key: tab,
                  scopedSlots: {
                    filter: ({ filter }) => {
                      return h(
                        require(`@/components/services/${tab}/filter`).default,
                        {
                          props: { value: filter },
                          on: {
                            input: event => {
                              filter = event
                            }
                          }
                        }
                      )
                    }
                  }
                }
              )
            })
          )
        ]
      )
    ]
  )
}

export default function(h) {
  if (this.viewport.breakpoint.xl) {
    return renderCards.call(this, h)
  } else {
    return renderTabs.call(this, h)
  }
}
