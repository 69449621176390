import { cloneDeep } from 'lodash'

import { inputModes, routerQueryReplace } from '@/utils'
import { serviceName } from '@/components/services/users/utils'

import SettingsUser from '@/components/services/settingsUser/template'
import usersTokens from '@/components/services/users/tokens/template'
import permissions from '@/components/services/permissions/template'
import userChannels from '@/components/services/users/userChannels'
import UsersRequisites from '@/components/services/users/requisites/template'
import tariffsGenerator from '@/components/services/tariffs/generator'
import associationsByType from '@/components/services/users/associationsByType'
import RecurrentPaymentsList from '@/components/services/recurrentPayments/list'
import ReferralPayments from '@/components/misc/ReferralPayments'
import routesTree from '@/components/trees/routes'
import usersBasicForm from '@/components/services/users/form/basic'
import buttonUpDown from '@/components/button/upDown'
import messageProcessing from '@/components/services/users/messageProcessing'
import Tab from '@/components/services/eventsHistory/tab'

function renderResellerAndManagerFields(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`, [ 'manager', true ])) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--8': true,
          'grid-cols--2': this.viewport.breakpoint.mdUp
        }
      },
      [
        h(
          'g-select',
          {
            props: {
              value: this.proxy.ResellerId,
              label: this.getTranslate(`${serviceName}.labels.reseller`),
              service: serviceName,
              query: { isActive: true },
              itemTitle: 'username',
              mode: inputModes['line-label'],
              dense: true,
              rounded: true,
              clearable: true,
              autocomplete: true,
              details: false,
              tabindex: 9
            },
            on: {
              input: event => {
                this.proxy.ResellerId = event
              }
            }
          }
        ),

        h(
          'g-select',
          {
            props: {
              value: this.proxy.data.partnership.ManagerId,
              label: this.getTranslate(`${serviceName}.labels.manager`),
              service: serviceName,
              query: { isActive: true },
              itemTitle: 'username',
              mode: inputModes['line-label'],
              dense: true,
              rounded: true,
              clearable: true,
              autocomplete: true,
              details: false,
              tabindex: 10
            },
            on: {
              input: event => {
                this.proxy.data.partnership.ManagerId = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderTabItem(h, key) {
  return h('div', { key }, [ renderTabItemContent.call(this, h, key) ])
}

function renderTabItemContent(h, tab) {
  switch (tab) {
    case 'general': {
      return h(
        usersBasicForm,
        {
          props: {
            value: this.proxy,
            errors: this.errors,
            isNew: this.isNew
          },
          on: {
            input: event => {
              this.proxy = event
            },
            submit: () => {
              this.$emit('submit', this.proxy)
            },
            validation: event => {
              this.$emit('validation', event)
            }
          }
        },
        [ renderResellerAndManagerFields.call(this, h) ]
      )
    }

    case 'settingsUser': {
      const props = {
        rounded: true,
        outline: true
      }

      return h(
        SettingsUser(
          {
            payload: cloneDeep(this.proxy),
            UserId: this.proxy.id,
            ResellerId: this.proxy.ResellerId || this.proxy.Reseller && this.proxy.Reseller.id,
            card: { props },
            panels: { props }
          }
        ),
        {
          class: 'pa-2'
        }
      )
    }

    case 'tokens': {
      return h(
        usersTokens,
        {
          class: 'pb-2',
          props: {
            OwnerId: this.proxy.id
          }
        }
      )
    }

    case 'permissions': {
      return h(
        permissions(
          {
            UserId: this.proxy.id,
            ResellerId: this.proxy.ResellerId || this.proxy.Reseller && this.proxy.Reseller.id
          }
        )
      )
    }

    case 'userChannels': {
      return h(
        userChannels,
        {
          props: {
            UserId: this.proxy.id
          }
        }
      )
    }

    case 'requisites': {
      return h(
        UsersRequisites,
        {
          class: 'py-3',
          props: {
            OwnerId: this.proxy.id,
            User: cloneDeep(this.proxy)
          }
        }
      )
    }

    case 'recurrentPayments': {
      return h(
        RecurrentPaymentsList(),
        {
          class: 'mb-2',
          style: {
            'border-bottom': '1px solid var(--border)'
          },
          props: {
            OwnerId: this.proxy.id
          }
        }
      )
    }

    case 'referral': {
      return h(
        ReferralPayments,
        {
          class: 'pt-3 pb-3',
          props: { OwnerId: this.proxy.id }
        }
      )
    }

    case 'tariffs': {
      return h(
        tariffsGenerator,
        {
          props: { value: this.proxy.Tariffs },
          on: {
            input: event => {
              this.proxy.Tariffs = event
            }
          }
        }
      )
    }

    case 'routingGroups': {
      return h(
        routesTree,
        {
          props: {
            value: this.proxy.RoutingGroups
          },
          on: {
            input: event => {
              this.proxy.RoutingGroups = event
            }
          }
        }
      )
    }

    case 'processMessages': {
      return h(
        'div',
        {
          class: {
            'grid faifs pa-3': true,
            'grid-cols--1': this.viewport.breakpoint.smDown,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            associationsByType,
            {
              props: { value: this.proxy },
              on: {
                input: event => {
                  this.proxy = event
                }
              }
            }
          ),

          h(
            messageProcessing,
            {
              props: { value: this.proxy },
              on: {
                input: event => {
                  this.proxy = event
                }
              }
            }
          )
        ]
      )
    }

    case 'eventsHistoryTab': {
      return h(
        Tab, {
          props: {
            service: serviceName,
            entityId: this.$route.params.id
          }
        }
      )
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-form`
    },
    [
      h(
        'g-tabs',
        {
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${serviceName}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              if (this.isNew) {
                this.currentTab = event
              } else {
                routerQueryReplace({
                  currentTab: event,
                  settingKey: undefined
                })
              }
            }
          }
        },
        this.tabs.map(tab => renderTabItem.call(this, h, tab))
      ),

      h(buttonUpDown)
    ]
  )
}
