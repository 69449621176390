import proxy from '@sigma-legacy-libs/g-proxy'

import { permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'RecurrentPaymentsFilter',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isPage: Boolean
  },

  computed: {
    gridTemplateColumns() {
      const result = []
      if (this.viewport.breakpoint.mdUp) {
        if (this.isPage) {
          if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
            result.push('auto')
          }
        }

        result.push('repeat(3, auto)')
      } else {
        result.push('1fr')
      }

      return result.join(' ')
    }
  },

  render
}
