import { get } from 'lodash'

import { colors, icons, inputModes, modes, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/misc/info'

const maxWidth = 400

function renderPlatformTitle(h) {
  const title = get(this.globalSettings, 'frontend.title')
  if (title) {
    return h(
      'div',
      {
        class: 'headline text--grey'
      },
      [ title ]
    )
  }
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 px-3',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      h(
        'div',
        {
          class: 'headline'
        },
        [ this.getTranslate(`${services.recover}.title`) ]
      ),

      renderPlatformTitle.call(this, h)
    ]
  )
}

function renderValidationResult(h) {
  if (this.validation === true && this.state === 'request') {
    return h(
      info,
      {
        props: {
          icon: icons.check_circle,
          color: colors.success,
          content: this.getTranslate('recover.contents.validation.request')
        }
      }
    )
  }
}

function renderLoginForm(h) {
  if (this.validation === undefined) {
    return h(
      'g-form',
      {
        on: {
          submit: () => {
            this.requestRecover()
          },
          validation: event => {
            this.$emit('validation', event)
          }
        }
      },
      [
        h(
          'g-text-field',
          {
            props: {
              value: this.login,
              label: this.getTranslate('recover.labels.login'),
              mode: inputModes['line-label'],
              rounded: true,
              clearable: true,
              details: false,
              autofocus: true
            },
            on: {
              input: event => {
                this.login = event
              }
            }
          }
        )
      ]
    )
  }
}
function renderPasswordForm(h) {
  if (this.validation === true && this.state === 'key') {
    return h(
      'g-form',
      {
        on: {
          submit: () => {
            this.changePassword(this.$route.params.key)
          },
          validation: event => {
            this.$emit('validation', event)
          }
        }
      },
      [
        h(
          'g-text-field',
          {
            props: {
              value: this.password,
              label: this.getTranslate('recover.labels.password'),
              hint: this.getTranslate('recover.hints.password'),
              name: 'password',
              afterIcon: this.passwordVisibility ? 'visibility_off' : 'visibility',
              afterIconCallback: () => {
                this.passwordVisibility = !this.passwordVisibility
              },
              mode: inputModes['line-label'],
              rounded: true,
              autofocus: true,
              clearable: true,
              type: this.passwordVisibility ? 'text' : 'password'
            },
            on: {
              input: event => {
                this.password = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderResetButton(h) {
  if (this.validation === undefined || this.state === 'key') {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('recover.buttons.reset'),
          mode: modes.filled,
          color: colors.primary,
          loading: this.loading,
          disabled: this.loading || this.state === 'key' ? !this.password : !this.login
        },
        on: {
          click: () => {
            if (this.$route.params.key) {
              this.changePassword(this.$route.params.key)
            } else {
              this.requestRecover()
            }
          }
        }
      }
    )
  }
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [ renderResetButton.call(this, h) ]
  )
}

function renderBody(h) {
  return h(
    'g-card',
    {
      class: 'pa-3',
      props: {
        outline: true,
        rounded: true,
        maxWidth,
        width: '100%'
      }
    },
    [
      renderLoginForm.call(this, h),
      renderPasswordForm.call(this, h),
      renderValidationResult.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: {
        'max-width': `${maxWidth}px`,
        width: '100%'
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
