import { backendServerIP } from '../constants'

export function getUrlToStorage(strings = []) {
  const prefix = [ 'api', 'storage', ...strings ].filter(v => !!v).join('/')
  const url = new URL(prefix, backendServerIP)

  return url.toString()
}

export default { getUrlToStorage }
