import Vue from 'vue'
import mime from 'mime'

import { mapActions, mapGetters } from 'vuex'

import { globalErrorHandler, globalErrorProcessor, isStringNotEmpty } from '@/utils'

import render from './render'

export default {
  name: 'FileInput',

  computed: {
    ...mapGetters({
      token: 'authentication/token',
      attributes: 'file/attributes',
      file: 'file/file',
      newFile: 'file/newFile'
    })
  },

  watch: {
    newFile: {
      handler(value) {
        this.handlerFiles(value)
      },
      deep: true
    }
  },

  methods: {
    ...mapActions({
      setUploadState: 'file/setUploadState',
      setProgress: 'file/setProgress',
      setUploadedFile: 'file/setUploadedFile',
      setFile: 'file/setFile',
      setNewFile: 'file/setNewFile',
      setError: 'file/setError'
    }),

    async handlerFiles(file) {
      this.setUploadState('ready')

      try {
        if (!file) {
          file = document.getElementById('file').files
        }

        const files = []
        const headers = {
          Accept: 'application/json',
          Authorization: this.token
        }

        for (let index = 0; index < file.length; index++) {
          if (file[index].size <= this.attributes.maxFileSize) {
            files.push(file[index])
          } else {
            this.addSnackbar({
              text: this.getTranslate('errors.types.fileMaxSize', { expected: `${this.attributes.maxFileSize}b` }),
              type: 'error'
            })

            return
          }
        }

        if (files.length) {
          this.setFile(file[0])

          const params = {}

          let data

          if (isStringNotEmpty(this.attributes.name)) {
            data = new FormData()

            files.forEach(file => {
              data.append(this.attributes.name, file, file.name)
            })
          } else {
            const file = files[0]
            let type = file.type
            data = file

            params.filename = file.name

            const match = file.name.match(/.*\.(.+)/)
            if (match) {
              type = mime.getType(match[1])
            }

            headers['Content-Type'] = type
          }

          if (this.attributes.isPublic) {
            params.isPublic = true
          }

          document.getElementById('file').value = ''

          const result = await Vue.$GRequest._request({
            url: this.attributes.url,
            method: this.attributes.method,
            data,
            headers,
            params,
            onUploadProgress: event => {
              this.setUploadState('loading')
              this.setProgress(event)
            }
          })

          if (result) {
            this.setProgress(0)
            this.setUploadState('success')
            this.setUploadedFile(result)

            return result
          } else {
            throw new Error('Server did not respond')
          }
        }
      } catch (error) {
        this.setProgress(0)
        this.setUploadState('error')
        this.setError(error)

        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
