import { RECURRENT_PAYMENTS_METHODS, RECURRENT_PAYMENTS_STATUSES, RECURRENT_PAYMENTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'

function renderOwnerSelect(h) {
  if (this.isPage) {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.OwnerId,
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false,
          clearable: true
        },
        on: {
          input: event => {
            this.proxy.OwnerId = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2',
      style: { gridTemplateColumns: this.viewport.breakpoint.mdUp ? '1fr 38px' : '1fr' }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { gridTemplateColumns: this.gridTemplateColumns }
        },
        [
          renderOwnerSelect.call(this, h),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.type,
                label: this.getTranslate(`${services.recurrentPayments}.labels.type`),
                items: RECURRENT_PAYMENTS_TYPES.map(type => {
                  return {
                    title: this.getTranslate(`${services.recurrentPayments}.types.${type}`),
                    value: type
                  }
                }),
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.proxy.type = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.status,
                label: this.getTranslate(`${services.recurrentPayments}.labels.status`),
                items: RECURRENT_PAYMENTS_STATUSES.map(status => {
                  return {
                    title: this.getTranslate(`${services.recurrentPayments}.statuses.${status}`),
                    value: status
                  }
                }),
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.proxy.status = event
                }
              }
            }
          ),

          h(
            'g-select',
            {
              props: {
                value: this.proxy.method,
                label: this.getTranslate(`${services.recurrentPayments}.labels.method`),
                items: RECURRENT_PAYMENTS_METHODS.map(method => {
                  return {
                    title: this.getTranslate(`${services.recurrentPayments}.methods.${method}`),
                    value: method
                  }
                }),
                mode: 'outline',
                dense: true,
                rounded: true,
                details: false,
                clearable: true
              },
              on: {
                input: event => {
                  this.proxy.method = event
                }
              }
            }
          )
        ]
      ),

      this.$scopedSlots.buttons ? this.$scopedSlots.buttons() : this.$slots.buttons
    ]
  )
}
