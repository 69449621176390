import Vue from 'vue'

import { generateServices, globalErrorHandler, globalErrorProcessor, states } from '@/utils'

import render from './render'

const serviceName = 'providersPrices'

const _ = { debounce: require('lodash/debounce') }

export default function(ProviderId) {
  return {
    name: serviceName,

    mixins: [
      generateServices({
        name: serviceName,

        find: {
          defaultFilter: {
            ProviderId,
            createdAt: undefined,
            $search: undefined,
            $scope: [ 'OperatorGroups', 'PreviousPrice' ],
            $limit: 100
          },
          defaultOrder: { mcc: 'asc' },
          appendMode: true
        },

        get: false,
        create: false,
        update: false,
        remove: false
      })
    ],

    data() {
      return {
        version: undefined,
        versions: []
      }
    },

    mounted() {
      this.getVersions()
    },

    created() {
      this.onScroll = _.debounce(this.onScroll, 500, {
        leading: false,
        trailing: true
      })
    },

    methods: {
      async getVersions() {
        // Не ловим здесь ошибки чтобы лишний раз не пугать пользователей ошибками о том что просто нет версий.
        const { data } = await Vue.$GRequest.get(`${serviceName}/versions`, ProviderId)
        if (Array.isArray(data)) {
          if (data.length) {
            this.versions = data
            this.version = data[0]
            this.getVersion(data[0])
          } else {
            this.versions = []
            this.version = undefined
            this.restData[serviceName].find.filter.createdAt = undefined
          }
        }
      },
      async getVersion(version) {
        version = version || this.version
        if (version) {
          this.version = version
          this.restData[serviceName].find.filter.createdAt = version
          this.scrollListTop()
        } else {
          this.getVersions()
        }
      },

      async removeVersion() {
        try {
          await Vue.$GRequest.remove(`${serviceName}/versions`, ProviderId, { query: { createdAt: this.version } })
          this.getVersions()
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      },

      onScroll(event) {
        const targetState = this.restData[serviceName].find.state === states.ready
        const targetScrollPosition = event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop <= 5 * 20
        const targetLimit = this.restData[serviceName].find.pagination.limit + this.restData[serviceName].find.pagination.offset < this.restData[serviceName].find.pagination.total
        if (targetState && targetScrollPosition && targetLimit) {
          this.restData[serviceName].find.pagination.offset += this.restData[serviceName].find.filter.$limit
        }
      },

      scrollListTop() {
        const list = this.$refs.list
        if (list) {
          list.scrollTop = 0
        }
      }
    },

    render(h) {
      return render.call(this, h, ProviderId)
    }
  }
}
