import { cloneDeep } from 'lodash'

import { CONTACTS_FILTER, modes } from '@/utils'

import ContactsForm from '@/components/services/contacts/form'
import button from '@/components/button'

export default function(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      h(
        ContactsForm,
        {
          props: {
            value: this.proxy,
            isFilter: true
          },
          on: {
            input: event => {
              this.proxy = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('filter.clear'),
                mode: modes.flat
              },
              on: {
                click: () => {
                  this.proxy = Object.assign({}, cloneDeep(CONTACTS_FILTER), { ListId: this.$route.params.id })
                }
              }
            }
          )
        ]
      )
    ]
  )
}
