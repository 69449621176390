import Vue from 'vue'
import stringify from 'json-stable-stringify'

import * as EMPTY from '@sigma-legacy-libs/essentials/lib/constants/empty'

import { cloneDeep, debounce, flatten, isEqual, isPlainObject, merge } from 'lodash'

import { getCreatedAtByPreset, mergeReplacingArrays, nestjsServices, projectName, states } from '@/utils'

import { globalErrorHandler, globalErrorProcessor } from './errorProcessors'
import { generateDefaultWebsocketFindEvents } from './webSocketsEvents'

const getEmptyPayload = (serviceName = '') => {
  if (EMPTY) {
    const name = serviceName.replace('-', '_').split('/').join('_')
      .toUpperCase()

    return cloneDeep(EMPTY[`EMPTY_${name}`])
  }
}

function defaultGlobalErrorProcessor(errors) {
  return globalErrorProcessor.call(this.ctx, errors)
}
function defaultLocalErrorProcessor(errors) {
  return errors
}
function generateDefaultLocalErrorProcessor() {
  return defaultLocalErrorProcessor
}

function defaultGlobalErrorHandler(errors) {
  return errors.map(error => globalErrorHandler.call(this.ctx, error))
}
function defaultLocalErrorHandler(restMethod = '', errors = []) {
  if (!Array.isArray(errors)) {
    errors = [ errors ]
  }

  if (restMethod && this[`${restMethod}Data`]) {
    this[`${restMethod}Data`].errors = errors.reduce((result, error) => {
      if (error.type === 'field') {
        result[error.field] = error.translate
      }

      return result
    }, {})
  }
}
function generateDefaultLocalErrorHandler(restMethod = '') {
  return function generatedDefaultLocalErrorHandler(errors) {
    return defaultLocalErrorHandler.call(this, restMethod, errors)
  }
}

function processRedirect(redirect, params = {}, defaultPath = false) {
  switch (typeof redirect) {
    case 'function':
      redirect.call(this.ctx)
      break
    case 'string':
    case 'boolean': {
      if (typeof redirect === 'boolean') {
        redirect = defaultPath || this.nameViaPoint
      }
      if (this.ctx && this.ctx.$router) {
        this.ctx.$router.push({
          name: redirect.replace(/\/n\//g, ''),
          params
        })
      }
      break
    }
  }
}

const restMethods = [ 'get', 'find', 'update', 'patch', 'create', 'remove' ]

class Service {
  constructor(options = {}) {
    this.options = {
      name: options.name,
      nameViaPoint: options.name.split('/').join('.'),
      as: options.as || options.name,
      path: options.path || options.name,

      backendGeneration: options.backendGeneration || 'legacy',

      version: options.version || 1,

      idField: options.id || options.idField || 'id',

      emptyPayload: options.emptyPayload || getEmptyPayload(options.name) || {},

      inputFilter: options.inputFilter || (v => v),
      outputFilter: options.outputFilter || (v => v),

      disableWatchers: options.disableWatchers || false,

      cacher: options.cacher || new Map(),

      errorProcessor: options.errorProcessor || defaultGlobalErrorProcessor,
      errorHandler: options.errorHandler || defaultGlobalErrorHandler,

      context: options.context || this
    }

    if (!this.options.name) {
      throw new Error('Service name is mandatory')
    }

    if (isPlainObject(options.get)) {
      this.options.get = merge(
        {
          method: () => {
            throw new Error(`Method get for service ${this.options.name} is not defined`)
          },
          abort: () => {},
          params: {},
          useCache: true,
          errorProcessor: generateDefaultLocalErrorProcessor('get'),
          errorHandler: generateDefaultLocalErrorHandler('get'),
          manipulateData: true,
          manipulateState: true,
          paramsSanitizer: v => v
        },
        options.get
      )

      this.options.get.useCache = !!this.options.get.useCache
      this.options.get.manipulateData = !!this.options.get.manipulateData

      this.getData = {
        state: states.empty,
        data: undefined,
        errors: {}
      }
    } else if (options.get === false) {
      this.options.get = false
    }

    if (isPlainObject(options.find)) {
      const setMeta = () => {
        this.findData.filterIsEqualToDefault = isEqual(this.options.find.defaultFilter, this.findData.filter)
        this.findData.filterIsEqualToLastFilter = isEqual(this.options.find.lastFilter, this.findData.filter)
        this.findData.paginationIsEqualToLastPagination = this.options.find.lastPagination.offset === this.findData.pagination.offset && this.options.find.lastPagination.limit === this.findData.pagination.limit
        this.options.find.lastFilter = cloneDeep(this.findData.filter)
        this.options.find.lastPagination = cloneDeep(this.findData.pagination)
      }
      const debouncedFind = debounce(
        () => {
          this.find()
        },
        500,
        {
          leading: false,
          trailing: true
        }
      )

      this.options.find = merge(
        {
          method: () => {
            throw new Error(`Method find for service ${this.options.name} is not defined`)
          },
          abort: () => {},
          params: {},
          useCache: true,
          errorProcessor: generateDefaultLocalErrorProcessor('find'),
          errorHandler: generateDefaultLocalErrorHandler('find'),
          manipulateData: true,
          manipulateState: true,
          websocketEvents: generateDefaultWebsocketFindEvents(options),
          paramsSanitizer: v => v,
          defaultFilter: {},
          defaultOrder: options.find.defaultOrder || { createdAt: 'desc' },
          defaultPagination: {},
          lastFilter: {},
          lastPagination: {},
          disableWatcherPagination: options.disableWatcherPagination || false,
          disableWatcherFilter: options.disableWatcherFilter || false,
          disableWatcherOrder: options.disableWatcherOrder || false,
          watchers: [
            [
              function() {
                return (
                  this.restData[options.as || options.name].find.pagination.offset +
                  '-' +
                  this.restData[options.as || options.name].find.pagination.limit
                )
              },
              () => {
                setMeta()
                if (!this.options.find.disableWatcherPagination) {
                  debouncedFind()
                }
              },
              {
                deep: true
              }
            ],
            [
              function() {
                return this.restData[options.as || options.name].find.filter
              },
              () => {
                setMeta()
                if (!this.options.find.disableWatcherFilter) {
                  if (this.options.find.lastPagination.offset !== 0) {
                    this.ctx.restData[options.as || options.name].find.pagination.offset = 0
                  } else {
                    debouncedFind()
                  }
                }
              },
              {
                deep: true
              }
            ],
            [
              function() {
                return this.restData[options.as || options.name].find.order
              },
              () => {
                setMeta()
                if (!this.options.find.disableWatcherOrder) {
                  debouncedFind()
                }
              },
              {
                deep: true
              }
            ]
          ],
          appendMode: false,
          bucketEnabled: false,
          bucketMaxLength: 25,
          alwaysCreateFromWebSocket: false,
          alwaysUpdateFromWebSocket: false,
          alwaysRemoveFromWebSocket: false
        },
        options.find
      )

      this.options.find.useCache = !!this.options.find.useCache
      this.options.find.manipulateData = !!this.options.find.manipulateData

      this.bucket = []

      let limitFromLocalStorage = 25
      try {
        limitFromLocalStorage = JSON.parse(window.localStorage.getItem(`${projectName}:pagination:limit:${options.name}`))
      } catch (error) {
        // ignore
      }

      this.findData = {
        state: states.empty,
        filter: cloneDeep(this.options.find.defaultFilter),
        filterIsEqualToDefault: true,
        filterIsEqualToLastFilter: true,
        paginationIsEqualToLastPagination: true,
        createdAtPreset: undefined,
        bucketLength: 0,
        data: [],
        pagination: Object.assign(
          {
            offset: 0,
            limit: limitFromLocalStorage || 25,
            total: 0
          },
          cloneDeep(this.options.find.defaultPagination)
        ),
        order: cloneDeep(this.options.find.defaultOrder),
        errors: {}
      }

      this.options.find.lastFilter = cloneDeep(this.findData.filter)
      this.options.find.lastPagination = cloneDeep(this.findData.pagination)
    } else if (options.find === false) {
      this.options.find = false
    }

    if (isPlainObject(options.update)) {
      this.options.update = merge(
        {
          method: () => {
            throw new Error(`Method update for service ${this.options.name} is not defined`)
          },
          abort: () => {},
          params: {},
          redirect: false,
          errorProcessor: generateDefaultLocalErrorProcessor('update'),
          errorHandler: generateDefaultLocalErrorHandler('update'),
          manipulateData: true,
          paramsSanitizer: v => v
        },
        options.update
      )

      this.options.update.manipulateData = !!this.options.update.manipulateData

      this.updateData = {
        state: states.ready,
        isValid: false,
        errors: {}
      }
    } else if (options.update === false) {
      this.options.update = false
    }

    if (isPlainObject(options.create)) {
      this.options.create = merge(
        {
          method: () => {
            throw new Error(`Method create for service ${this.options.name} is not defined`)
          },
          abort: () => {},
          after: () => {},
          params: {},
          redirect: false,
          errorProcessor: generateDefaultLocalErrorProcessor('create'),
          errorHandler: generateDefaultLocalErrorHandler('create'),
          manipulateData: true,
          paramsSanitizer: v => v
        },
        options.create
      )

      this.options.create.manipulateData = !!this.options.create.manipulateData

      this.createData = {
        state: states.ready,
        data: undefined,
        isValid: false,
        errors: {}
      }
    } else if (options.create === false) {
      this.options.create = false
    }

    if (isPlainObject(options.remove)) {
      this.options.remove = merge(
        {
          method: () => {
            throw new Error(`Method remove for service ${this.options.name} is not defined`)
          },
          abort: () => {},
          params: {},
          redirect: true,
          errorProcessor: generateDefaultLocalErrorProcessor('remove'),
          errorHandler: generateDefaultLocalErrorHandler('remove'),
          manipulateData: true,
          paramsSanitizer: v => v
        },
        options.remove
      )

      this.options.remove.manipulateData = !!this.options.remove.manipulateData

      this.removeData = {
        state: states.ready,
        errors: {}
      }
    } else if (options.remove === false) {
      this.options.remove = false
    }

    if (this.options.cacher && typeof this.options.cacher.wrapWithCache === 'function') {
      if (this.options.get && this.options.get.useCache) {
        const oldGet = this.options.get.method
        const cachedGet = this.options.cacher.wrapWithCache((key, id, params) => {
          return oldGet.call(this, id, params)
        })
        this.options.get.method = (id, params = {}, options = {}) => {
          const key = `get:${this.options.as}:${id}:${stringify(params)}`

          if (options && options.noCache && this.options.cacher.delete) {
            this.options.cacher.delete(key)
          }

          return cachedGet(key, id, params)
        }
      }

      if (this.options.find && this.options.find.useCache) {
        const oldFind = this.options.find.method
        const cachedFind = this.options.cacher.wrapWithCache((key, params) => {
          return oldFind.call(this, params)
        })
        this.options.find.method = (params = {}, options = {}) => {
          const key = `find:${this.options.as}:${stringify(params)}`

          if (options && options.noCache && this.options.cacher.delete) {
            this.options.cacher.delete(key)
          }

          return cachedFind(key, params)
        }
      }
    }
  }

  get emptyPayload() {
    return cloneDeep(this.options.emptyPayload)
  }

  get name() {
    return this.options.name
  }

  get nameViaPoint() {
    return this.options.name.split('/').join('.')
  }

  get version() {
    return this.options.version
  }

  get as() {
    return this.options.as
  }

  get path() {
    switch (this.options.backendGeneration) {
      case 'nest': return `/n/${this.options.path}`
      case 'legacy':
      default: return this.options.path
    }
  }

  get idField() {
    return this.options.idField
  }

  get ctx() {
    return this.options.context
  }

  set ctx(value) {
    this.options.context = value
  }

  get websocketEvents() {
    const events = []

    restMethods.forEach(restMethod => {
      if (this.options[restMethod] && Array.isArray(this.options[restMethod].websocketEvents)) {
        this.options[restMethod].websocketEvents.forEach(e => {
          if (e.event && typeof e.handler === 'function') {
            events.push(e)
          }
        })
      }
    })

    return events
  }

  get watchers() {
    const watchers = []

    restMethods.forEach(restMethod => {
      if (this.options[restMethod] && Array.isArray(this.options[restMethod].watchers)) {
        this.options[restMethod].watchers.forEach(([ what, handler, params ]) => {
          if (
            what &&
            handler &&
            (typeof what === 'string' || typeof what === 'function') &&
            typeof handler === 'function' &&
            !this.options.disableWatchers
          ) {
            const watcher = [ typeof what === 'string' ? what : what.bind(this.ctx), handler.bind(this.ctx) ]
            if (params && params.deep) {
              watcher.push({ deep: true })
            }
            watchers.push(watcher)
          }
        })
      }
    })

    return watchers
  }

  async get(id, params = {}, options = {}) {
    if (this.options.get === false) {
      return
    }

    options = mergeReplacingArrays({}, this.options.get, options || {})

    try {
      if (options && options.manipulateState) {
        this.getData.state = states.loading
      }

      this.getData.errors = {}

      if (typeof options.paramsSanitizer === 'function') {
        params = options.paramsSanitizer.call(this, params)
      }

      const result = await options.method.call(this, id, params, options)
      const cleanResult = await this.options.inputFilter.call(this.ctx, cloneDeep(result))

      if (options.manipulateData) {
        this.getData.data = cleanResult
      }

      return cleanResult
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, flatten([ error ]))
      processedErrors = await options.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      options.errorHandler.call(this, processedErrors)
    } finally {
      if (options && options.manipulateState) {
        this.getData.state = states.ready
      }
    }

    return false
  }

  async find(params = {}, options = {}) {
    if (this.options.find === false) {
      return
    }

    options = mergeReplacingArrays({}, this.options.find, options || {})

    try {
      if (options && options.manipulateState) {
        this.findData.state = states.loading
      }

      this.findData.errors = {}

      if (typeof options.paramsSanitizer === 'function') {
        params = options.paramsSanitizer.call(this, params)
      }

      if (this.findData.filter.createdAtPreset) {
        const createdAt = getCreatedAtByPreset(this.findData.filter.createdAtPreset)
        if (createdAt) {
          if (Object.values(nestjsServices).includes(this.options.name)) {
            params.query.createdAt = {
              gte: createdAt.$gt,
              lt: createdAt.$lt
            }
          } else {
            params.query.createdAt = createdAt
          }
          delete params.query.createdAtPreset
        }
      }

      let { data: result, total } = await options.method.call(this, params, options)

      result = await Promise.all(
        result.map(async item => {
          if (this.options && this.options.inputFilter) {
            item = await this.options.inputFilter.call(this.ctx, item)
          }
          if (options && options.inputFilter) {
            item = await options.inputFilter.call(this.ctx, item)
          }

          return item
        })
      )

      if (options.manipulateData) {
        if (
          options.appendMode &&
          this.findData.filterIsEqualToLastFilter &&
          !this.findData.paginationIsEqualToLastPagination
        ) {
          this.findData.data.push(...result)
        } else {
          this.findData.data = result
        }

        this.findData.pagination.total = total
        this.bucket = []
        this.findData.bucketLength = 0
      }

      return result
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, flatten([ error ]))
      processedErrors = await options.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      options.errorHandler.call(this, processedErrors)
    } finally {
      if (options && options.manipulateState) {
        this.findData.state = states.ready
      }
    }

    return false
  }

  async bucketRelease() {
    if (this.findData.state === states.loading || this.options.find === false || this.bucket.length === 0) {
      return
    }

    if (this.findData.filterIsEqualToDefault) {
      this.findData.state = states.loading
      Promise.all(this.bucket.map(item => this.options.inputFilter.call(this.ctx, item))).then(filteredBucket => {
        this.findData.data = [ ...filteredBucket, ...this.findData.data ].slice(0, this.findData.pagination.limit)
        this.findData.state = states.ready
      })
    }

    this.bucket = []
    this.findData.bucketLength = 0
  }

  async create(data = {}, params = {}) {
    if (this.options.create === false) {
      return
    }

    try {
      this.createData.state = states.loading
      this.createData.errors = {}

      if (typeof this.options.create.paramsSanitizer === 'function') {
        params = this.options.create.paramsSanitizer.call(this, params)
      }

      const filtered = await this.options.outputFilter.call(this.ctx, cloneDeep(data))
      const response = await this.options.create.method.call(this, filtered, params)

      if (this.options.create.redirect) {
        processRedirect.call(
          this,
          this.options.create.redirect,
          { [this.idField]: response[this.idField] },
          `${this.nameViaPoint}.single`
        )
      }
      if (this.options.create.manipulateData) {
        this.createData.data = await this.options.inputFilter.call(this.ctx, this.emptyPayload)
      }
      if (this.options.create.after && typeof this.options.create.after === 'function') {
        this.options.create.after.call(this, response)
      }

      Vue.$bus.emit(`rest.${this.nameViaPoint}.created`, this.nameViaPoint, 'created', filtered)

      return response
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, flatten([ error ]))
      processedErrors = await this.options.create.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      this.options.create.errorHandler.call(this, processedErrors)
    } finally {
      this.createData.state = states.ready
    }

    return false
  }

  async update(id, data = {}, params) {
    if (this.options.update === false) {
      return
    }

    if (typeof id !== 'string' && !params) {
      params = data
      data = id
      id = data && data[this.idField]
    }

    try {
      this.updateData.state = states.loading
      this.updateData.errors = {}

      if (typeof this.options.update.paramsSanitizer === 'function') {
        params = this.options.update.paramsSanitizer.call(this, params)
      }

      let cleanData = cloneDeep(data)
      cleanData = await this.options.outputFilter.call(this.ctx, cleanData)

      const result = await this.options.update.method.call(this, id, cleanData, params)
      const cleanResult = await this.options.inputFilter.call(this.ctx, result)

      if (this.options.update.manipulateData) {
        this.getData.data = cleanResult
      }

      Vue.$bus.emit(`rest.${this.nameViaPoint}.updated`, this.nameViaPoint, 'updated', cleanResult)

      return cleanResult
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, flatten([ error ]))
      processedErrors = await this.options.update.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      this.options.update.errorHandler.call(this, processedErrors)
    } finally {
      this.updateData.state = states.ready
    }

    return false
  }

  async patch(id, data = {}, params = {}) {
    if (this.options.update === false) {
      return
    }

    if (typeof id !== 'string' && !params) {
      params = data
      data = id
      id = data && data[this.idField]
    }

    try {
      this.updateData.state = states.loading
      this.updateData.errors = {}

      if (typeof this.options.update.paramsSanitizer === 'function') {
        params = this.options.update.paramsSanitizer.call(this, params)
      }

      const cleanData = cloneDeep(data)
      const result = await this.options.update.method.call(this, id, cleanData, params)

      return result
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, flatten([ error ]))
      processedErrors = await this.options.update.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      this.options.update.errorHandler.call(this, processedErrors)
    } finally {
      this.updateData.state = states.ready
    }

    return false
  }

  async remove(id, params = {}) {
    if (this.options.remove === false) {
      return
    }

    try {
      this.removeData.state = states.loading
      this.removeData.errors = {}

      if (typeof this.options.remove.paramsSanitizer === 'function') {
        params = this.options.remove.paramsSanitizer.call(this, params)
      }

      const result = await this.options.remove.method.call(this, id, params)

      if (this.options.remove.redirect) {
        processRedirect.call(this, this.options.remove.redirect)
      } else if (this.options.remove.manipulateData) {
        this.getData.data = undefined
      }

      Vue.$bus.emit(`rest.${this.nameViaPoint}.removed`, this.nameViaPoint, 'removed', id)

      return result
    } catch (error) {
      let processedErrors = await this.options.errorProcessor.call(this, flatten([ error ]))
      processedErrors = await this.options.remove.errorProcessor(processedErrors)
      this.options.errorHandler.call(this, processedErrors)
      this.options.remove.errorHandler.call(this, processedErrors)
    } finally {
      this.removeData.state = states.ready
    }

    return false
  }

  init(ctx) {
    this.ctx = ctx

    const setEmptyPayload = emptyPayload => {
      if (this.options.create) {
        this.createData.data = emptyPayload
      }
    }

    if (this.options.inputFilter) {
      const filteredEmptyPayload = this.options.inputFilter.call(this.ctx, this.emptyPayload)
      if (typeof filteredEmptyPayload.then === 'function') {
        filteredEmptyPayload.then(emptyPayload => {
          setEmptyPayload(emptyPayload)
        })
      } else {
        setEmptyPayload(filteredEmptyPayload)
      }
    }
  }
}

export default Service
