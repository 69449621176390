import { get } from 'lodash'

import { components, generateUniqueKey } from '@/utils'

function renderTabIndexInput(h) {
  return h(
    'input', {
      attrs: { tabindex: this.tabindex },
      class: `${components.select}__tabindex`,
      on: {
        focus: this._clickOnMain,
        blur: this._clickOutside
      }
    }
  )
}

function renderLabel(h) {
  if (this.hasLabel) {
    return h(
      'div',
      {
        class: {
          [`${components.select}__label`]: true,
          [this.focusedClass]: this.isFocused
        }
      },
      [ this.label, this.required && '*' ].filter(item => !!item).join(' ')
    )
  }

  return h('div')
}

function renderGroup(h, nodes = []) {
  return h(
    'div',
    {
      class: {
        [`${components.select}__group`]: true,
        [`${components.select}__group--filled`]: !this.proxyIsEmpty && this.isFocused,
        [`${components.select}__group--one-row`]: this.hasInput && this.hideSelections && this.isFocused
      }
    },
    [ ...nodes ]
  )
}

function renderInput(h) {
  if (!this.isDisabled && this.hasInput) {
    return h(
      'g-input',
      {
        class: {
          [`${components.select}__input`]: true,
          [`${components.select}__input--hidden`]: !this.isFocused
        },
        props: {
          value: this.search,
          placeholder: this.placeholder,
          disabled: this.disabled,
          readonly: this.readonly,
          tabindex: this.tabindex,
          mask: this.mask,
          maskOptions: this.maskOptions,
          processValue: this.processValue
        },
        on: {
          click: this._preventClick,
          keyup: this._onKeyup,
          keydown: this._onKeydown,
          input: this._inputHandler,
          paste: this._pasteHandler
        },
        ref: 'input'
      }
    )
  }

  return h('div')
}

function renderClearable(h) {
  if (this.isClearable) {
    return h(
      'g-icon', {
        class: { [this.focusedClass]: this.isFocused },
        props: { value: 'clear' },
        on: {
          click: () => {
            this.proxy = [ ...this.proxy.splice(0, -1) ]
          }
        }
      }
    )
  }
}

function renderLoading(h, type = 'linear') {
  if (this.isLoading) {
    switch (type) {
      case 'circular': {
        return h(
          'g-progress', {
            props: {
              type,
              size: 24,
              width: 1,
              color: this.isDisabled ? 'grey' : 'primary',
              indeterminate: true
            }
          }
        )
      }

      case 'linear':
      default: {
        return h(
          'g-progress', {
            style: {
              position: 'absolute',
              top: '-1px',
              left: 0
            },
            props: {
              type,
              color: this.isDisabled ? 'grey' : 'primary',
              indeterminate: true
            }
          }
        )
      }
    }
  }
}

function renderSeparator(h, index) {
  if (index !== this.selectedValues.length - 1) {
    return h(
      'span',
      {
        class: 'mr-1'
      },
      [ this.separator ]
    )
  }
}

function renderSelectedChipIcon(h, value) {
  if (this.multiple) {
    return h(
      'div',
      {
        class: `${components.select}__chip-icon`,
        on: {
          click: () => {
            this.removeByValue(value)
          }
        }
      }
    )
  }
}
function renderSelectedChip(h, value, title) {
  return h(
    'div',
    {
      class: {
        [`${components.select}__chip`]: true,
        [`${components.select}__chip--multiple`]: this.multiple
      }
    },
    [
      h(
        'div',
        {
          class: `${components.select}__chip-title`
        },
        title
      ),

      renderSelectedChipIcon.call(this, h, value)
    ]
  )
}
function renderSelectedText(h, title, index) {
  return h(
    'div',
    {
      class: `${components.select}__text`
    },
    [
      h('span', { class: 'text-overflow text--black' }, title),

      renderSeparator.call(this, h, index)
    ]
  )
}
function renderSelectedItem(h, item, index) {
  const value = get(item, this.$itemValue, item)
  const title = get(item, this.itemTitle, this._findTitleInSelectedItemsByValue(item))

  switch (this.selectionType) {
    case 'chip': return renderSelectedChip.call(this, h, value, title)
    case 'text': return renderSelectedText.call(this, h, title, index)
  }
}
function renderSelectionSlot(h, item, index) {
  if (this.$scopedSlots.selection) {
    return this.$scopedSlots.selection({
      item,
      index,
      addByValue: this.addByValue,
      removeByValue: this.removeByValue,
      toggleByValue: this.toggleByValue
    })
  } else {
    return renderSelectedItem.call(this, h, item, index)
  }
}
function renderSelection(h) {
  if (!this.proxyIsEmpty) {
    return h(
      'div',
      {
        class: {
          [`${components.select}__selection`]: true,
          [`${components.select}__selection--fill`]: !this.hasInput,
          [`${components.select}__selection--hidden`]: this.hasInput && this.hideSelections && this.isFocused && !this.isMobile
        }
      },
      [ this.selectedItems.map((item, index) => renderSelectionSlot.call(this, h, item, index)) ]
    )
  }

  return h('div')
}

function renderBeforeSlot(h) {
  if (this.hasBeforeSlot) {
    return h(
      'div',
      {
        class: `${components.select}__before-holder`
      },
      [ this.$scopedSlots.before ? this.$scopedSlots.before() : this.$slots.before ]
    )
  }
}
function renderBeforeIcon(h) {
  if (this.beforeIcon) {
    return h(
      'div',
      {
        class: `${components.select}__before-holder`
      },
      [
        h(
          'g-icon',
          {
            class: { [this.focusedClass]: this.isFocused },
            props: { value: this.beforeIcon },
            on: {
              click: () => {
                if (!this.isDisabled && typeof this.beforeIconCallback === 'function') {
                  this.beforeIconCallback()
                }
              }
            }
          }
        )
      ]
    )
  }
}
function renderBeforeContent(h) {
  return renderBeforeIcon.call(this, h)
}
function renderBefore(h) {
  if (this.hasBefore) {
    return h(
      'div',
      {
        class: {
          [`${components.select}__before`]: true,
          [`${components.select}__before--pointer`]: this.beforeIcon && typeof this.beforeIconCallback === 'function'
        }
      },
      [ renderBeforeSlot.call(this, h) || renderBeforeContent.call(this, h) ]
    )
  }
}

function renderAfterSlot(h) {
  if (this.hasAfterSlot) {
    return h(
      'div',
      {
        class: `${components.select}__after-holder`
      },
      [ this.$scopedSlots.after ? this.$scopedSlots.after() : this.$slots.after ]
    )
  }
}
function renderAfterIcon(h) {
  if (this.afterIcon) {
    return h(
      'div',
      {
        class: `${components.select}__after-holder`
      },
      [
        h(
          'g-icon',
          {
            class: { [this.focusedClass]: this.isFocused },
            props: { value: this.afterIcon },
            on: {
              click: () => {
                if (!this.isDisabled && typeof this.afterIconCallback === 'function') {
                  this.afterIconCallback()
                }
              }
            }
          }
        )
      ]
    )
  }
}
function renderAfterContent(h) {
  if (this.isLoading) {
    if (~[ 'solo', 'outline', 'outline-label', 'line-label' ].indexOf(this.mode)) {
      return h(
        'div',
        {
          class: `${components.select}__after-holder`
        },
        [ renderLoading.call(this, h, 'circular') ]
      )
    }
  } else {
    return renderClearable.call(this, h) || renderAfterIcon.call(this, h)
  }
}
function renderAfter(h) {
  if (this.hasAfter) {
    return h(
      'div',
      {
        class: {
          [`${components.select}__after`]: true,
          [`${components.select}__after--pointer`]: this.afterIcon && typeof this.afterIconCallback === 'function'
        }
      },
      [ renderAfterSlot.call(this, h) || renderAfterContent.call(this, h) ]
    )
  }
}

function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components.select}__holder`,
      style: { 'background-color': this.backgroundColor }
    },
    [
      renderBefore.call(this, h),
      renderGroup.call(this, h, [ renderSelection.call(this, h), renderInput.call(this, h) ]),
      renderAfter.call(this, h)
    ]
  )
}

function renderItemSlot(h, item, index) {
  const title = get(item, this.itemTitle, this._findTitleInSelectedItemsByValue(item))

  if (typeof this.$scopedSlots.item === 'function') {
    return this.$scopedSlots.item({
      item,
      index
    })
  }

  return h(
    'g-text-overflow',
    {
      props: {
        value: title,
        tooltip: this.tooltip
      }
    }
  )
}
function renderItem(h, item, index) {
  if (typeof this.$scopedSlots.listItem === 'function') {
    return this.$scopedSlots.listItem({
      item,
      index,
      addByValue: this.addByValue,
      removeByValue: this.removeByValue,
      toggleByValue: this.toggleByValue,
      selectedItems: this.selectedItems
    })
  }

  return h(
    'g-list-item',
    {
      props: {
        active: item._selected && !this.multiple,
        hovered: item._hovered,
        disabled: item._disabled,
        icon: this.multiple ? item._selected ? 'check_box' : 'check_box_outline_blank' : undefined,
        iconColor: item._selected ? 'primary' : undefined,
        dense: true
      },
      on: {
        click: event => {
          this._itemClickHandler(event, item)
        }
      },
      key: generateUniqueKey()
    },
    [ renderItemSlot.call(this, h, item, index) ]
  )
}
function renderItems(h) {
  return h(
    'g-list',
    {
      key: generateUniqueKey()
    },
    [ this.filteredItems.map((item, index) => renderItem.call(this, h, item, index)) ]
  )
}

function renderMenu(h) {
  if (!this.isDisabled && !this.isMobile) {
    return h(
      'g-menu',
      {
        props: this.$menuProps,
        on: {
          input: event => {
            this._data._show.dropdown = event
          }
        },
        ref: 'menu'
      },
      [ renderItems.call(this, h) ]
    )
  }
}

function renderDialogInput(h) {
  if (this.hasInput) {
    return h(
      'g-text-field',
      {
        props: {
          value: this.search,
          label: this.label,
          disabled: this.disabled,
          readonly: this.readonly,
          mask: this.mask,
          maskOptions: this.maskOptions,
          mode: 'outline',
          dense: true,
          rounded: true,
          autofocus: true,
          details: false
        },
        on: {
          click: this._preventClick,
          keyup: this._onKeyup,
          keydown: this._onKeydown,
          input: this._inputHandler,
          paste: this._pasteHandler
        },
        ref: 'dialog-input'
      }
    )
  }

  return h(
    'div',
    {
      class: `${components.select}__dialog-header-label`
    },
    [ this.label ]
  )
}
function renderDialogHeader(h) {
  return h(
    'div',
    {
      class: `${components.select}__dialog-header`,
      slot: 'header'
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            icon: 'arrow_back',
            flat: true
          },
          on: {
            click: () => {
              this._clickOutside()
            }
          }
        }
      ),

      renderDialogInput.call(this, h),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            icon: 'clear',
            flat: true
          },
          on: {
            click: () => {
              this._clickOutside()
            }
          }
        }
      )
    ]
  )
}
function renderDialogBody(h) {
  return h(
    'div',
    {
      class: `${components.select}__dialog-body`
    },
    [
      h(
        'div',
        {
          class: `${components.select}__dialog-body-selection`
        },
        [ renderSelection.call(this, h) ]
      ),
      renderItems.call(this, h)
    ]
  )
}
function renderDialog(h) {
  if (!this.isDisabled && this.isMobile) {
    return h(
      'g-dialog',
      {
        props: {
          value: this._data._show.dialog,
          fullscreen: this.isMobile,
          closeOnClick: false,
          closeOnEsc: false
        },
        on: {
          input: event => {
            this._data._show.dialog = event
          }
        },
        ref: 'dialog'
      },
      [
        renderDialogHeader.call(this, h),
        renderDialogBody.call(this, h)
      ]
    )
  }
}

function renderBorder(h) {
  if (!this.isLoading) {
    return h(
      'div',
      {
        class: {
          [`${components.select}__border`]: true,
          [this.focusedClass]: this.isFocused
        }
      }
    )
  }
}
function renderFooter(h) {
  if (this.mode === 'default') {
    return h(
      'div',
      {
        class: `${components.select}__footer`
      },
      [
        renderLoading.call(this, h),
        renderBorder.call(this, h)
      ]
    )
  }
}

function renderAttach(h) {
  return h(
    'div',
    {
      class: `${components.select}__attach`,
      ref: 'attach'
    }
  )
}

function renderDetails(h) {
  if (this.details) {
    return h(
      'div',
      {
        class: `${components.select}__details`
      },
      [
        h(
          'div',
          {
            class: `${components.select}__details--left`,
            domProps: { innerHTML: this.isError ? this._data._error : this.hint }
          }
        ),

        h(
          'div',
          {
            class: `${components.select}__details--right`
          },
          this.count
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${components.select}`]: true,
        [`${components.select}--${this.mode}`]: true,
        [`${components.select}--labeled`]: !!this.label,
        [`${components.select}--disabled`]: this.isDisabled,
        [`${components.select}--autocomplete`]: !!this.autocomplete,
        [`${components.select}--filled`]: !this.proxyIsEmpty,
        [`${components.select}--active`]: this.isActive,
        [`${components.select}--flat`]: this.flat,
        [`${components.select}--dense`]: this.dense,
        [`${components.select}--rounded`]: this.rounded,
        [`${components.select}--search`]: !!this.search,
        [`${components.select}--error`]: this.isError,
        [`${components.select}--multiple`]: this.multiple,
        [`${components.select}--has-before`]: this.hasBefore,
        [`${components.select}--has-after`]: this.hasAfter
      },
      on: { click: this._clickHandler }
    },
    [
      renderTabIndexInput.call(this, h),
      renderLabel.call(this, h),
      renderHolder.call(this, h),
      renderFooter.call(this, h),
      renderAttach.call(this, h),
      renderMenu.call(this, h),
      renderDialog.call(this, h),
      renderDetails.call(this, h)
    ]
  )
}
