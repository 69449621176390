function renderIcon(h) {
  if (this.icon) {
    return h(
      'g-icon',
      {
        props: {
          value: this.icon,
          color: this.color,
          size: this.$iconSize
        }
      }
    )
  }

  return this.$slots.default
}

function renderPreLoader(h) {
  return h(
    'g-progress',
    {
      props: {
        indeterminate: true,
        color: this.color,
        size: this.$iconSize
      }
    }
  )
}

function renderContent(h) {
  if (this.loading) {
    return renderPreLoader.call(this, h)
  }

  return renderIcon.call(this, h)
}

export default function(h) {
  return h(
    'div',
    {
      class: this.$class,
      directives: this.$directives
    },
    [ renderContent.call(this, h) ]
  )
}
