module.exports = [
  'account',
  'apisender',
  'billings',
  'colors',
  'commons',
  'companies',
  'conditions',
  'contactLists',
  'contacts',
  'contracts',
  'create',
  'credentials',
  'currency',
  'dashboard',
  'debug',
  'dictionaries',
  'documentation',
  'documentTemplates',
  'documents',
  'errors',
  'eventsHistory',
  'files',
  'filter',
  'grafana',
  'health',
  'hlr',
  'inactiveProviders',
  'invoices',
  'keywordLists',
  'keywords',
  'login',
  'messageProcessing',
  'messageRuleGroups',
  'messageRules',
  'mobilePanel',
  'operatorGroups',
  'other',
  'otp-settings',
  'misc',
  'pay',
  'patterns',
  'permissions',
  'payments',
  'paymentLogs',
  'playground',
  'providerGroups',
  'providers',
  'providersPrices',
  'recover',
  'recurrentPayments',
  'registration',
  'replacement',
  'reports',
  'requisites',
  'routes',
  'routingGroups',
  'routingRules',
  'rules',
  'sendernames',
  'sendings',
  'sendingsCreate',
  'sendingsModeration',
  'servers',
  'services',
  'settings',
  'settingsSite',
  'settingsUser',
  'support',
  'storage',
  'tariffs',
  'tasks',
  'templates',
  'tokens',
  'userComments',
  'users',
  'referral',
  'ws',
  'yclients'
]
