import providersLogs from '@/components/services/providers/logs'
import providersPrices from '@/components/services/providers/prices'
import eventsHistoryTab from '@/components/services/eventsHistory/tab'

import { serviceName } from '@/components/services/providers/utils'

function renderTab(h) {
  switch (this.currentTab) {
    case 'rates': {
      return h(providersPrices(this.$route.params.id))
    }
    case 'logs': {
      return h(providersLogs, { props: { ProviderId: this.$route.params.id } })
    }
    case 'eventsHistory': {
      return h(
        eventsHistoryTab, {
          props: {
            service: serviceName,
            entityId: this.$route.params.id
          }
        }
      )
    }
  }
}

function renderTabItem(h, key) {
  return h(
    'g-card',
    {
      props: {
        rounded: true,
        width: '100%'
      },
      key
    },
    [ renderTab.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'g-tabs-header',
        {
          class: 'pl-3',
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${serviceName}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              this.currentTab = event
            }
          }
        }
      ),

      h(
        'g-tabs',
        {
          props: { value: this.currentTab },
          scopedSlots: {
            body: () => this.tabs.map(tab => renderTabItem.call(this, h, tab))
          }
        }
      )
    ]
  )
}
