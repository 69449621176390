import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { permissionPresets, processFilter } from '@/utils'

import render from './render'

export default {
  name: 'ButtonReport',

  mixins: [ proxy() ],

  props: {
    preset: {
      type: String,
      default: ''
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    icon: Boolean,

    type: {
      type: String,
      default: 'button'
    }
  },

  data() {
    const flags = {}
    switch (this.preset) {
      case 'sendings.details': {
        flags.includeRefunded = false
        break
      }
    }

    return {
      loading: false,

      encoding: 'win1251',

      delimiter: ';',

      flags
    }
  },

  methods: {
    getReport() {
      this.loading = true

      const params = {
        preset: this.preset,
        encoding: this.encoding,
        delimiter: this.delimiter,
        where: processFilter(this.filter, {}, [ 'createdAtPreset' ])
      }

      if (this.checkPermissions('advanced.reports.generate', permissionPresets.true)) {
        switch (this.preset) {
          case 'sendings.details': {
            params.flags = this.flags
            break
          }
        }
      }

      Vue.$GRequest.create('reports', params)

      this.addSnackbar({
        text: this.getTranslate('reports.snackbars.requested'),
        type: 'success'
      })

      this.loading = false
      this.proxy = false
    }
  },

  render
}
