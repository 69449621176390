import { colors, components, modes, sizes } from '@/utils'

import render from './render'

export default {
  name: 'ButtonConfirmRemove',

  props: {
    callback: {
      type: Function,
      required: true
    },

    icon: {
      type: String,
      default: 'delete'
    },
    color: {
      type: String,
      default: colors.error
    },

    labeled: {
      type: Boolean,
      default: true
    },

    component: {
      type: String,
      default: 'btn',
      validator: value => {
        return ~[ components.button, components['list-item'], 'btn' ].indexOf(value)
      }
    },

    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },

    disabled: Boolean
  },

  data() {
    return {
      loading: false,
      showDialog: false
    }
  },

  computed: {
    $props() {
      const result = {
        label: this.labeled ? this.getTranslate('misc.buttons.remove') : undefined,
        icon: this.icon,
        color: this.disabled || this.loading ? colors.grey : this.color,
        disabled: this.disabled || this.loading
      }

      switch (this.component) {
        case components.button: {
          result.flat = true
          result.rounded = true
          break
        }
        case components['list-item']: {
          result.icon = undefined
          result.dense = true
          break
        }

        case 'btn': {
          result.mode = modes.flat,
          result.size = this.size
          break
        }
      }

      return result
    }
  },

  methods: {
    onClick(event) {
      if (this.component !== components['list-item']) {
        event.stopPropagation()
      }
      this.showDialog = true
    }
  },

  render
}
