import { get } from 'lodash'
import { createPopper } from '@popperjs/core'

const defaultOptions = {
  placement: 'top',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'offset',
      options: { offset: [ 0, 16 ] }
    },
    {
      name: 'preventOverflow',
      options: { padding: 16 }
    }
  ]
}

let popperInstance
let timeoutInstance
let intervalInstance

const destroyTimeout = () => {
  if (timeoutInstance) {
    clearTimeout(timeoutInstance)
    timeoutInstance = undefined
  }
}
const destroyInterval = () => {
  if (intervalInstance) {
    clearInterval(intervalInstance)
    intervalInstance = undefined
  }
}

const destroyPopper = () => {
  if (popperInstance) {
    popperInstance.destroy()
    popperInstance = undefined
  }
}
const hide = (el, binding) => {
  if (el && binding) {
    binding.innerHTML = ''
    binding.style.display = 'none'
    binding.style.visibility = 'hidden'
    binding.dataset.popperReferenceHidden = 'true'

    destroyTimeout()
    destroyInterval()
    destroyPopper()
  }
}
const destroy = (el, binding) => {
  if (el && binding) {
    if (timeoutInstance) {
      setupTimeout(el, binding)
    } else {
      hide(el, binding)
    }
  }
}

const setupTimeout = (el, binding, timeout) => {
  if (timeout) {
    destroyTimeout()
    destroyInterval()

    let remainingTime = timeout / 1000
    let countdownElement = binding.querySelector('.g-tooltip-countdown')
    if (!countdownElement) {
      countdownElement = document.createElement('div')
      countdownElement.className = 'g-tooltip-countdown'
      binding.appendChild(countdownElement)
    }
    countdownElement.style.width = '0%'

    const step = 100 / (timeout / 1000)

    intervalInstance = setInterval(() => {
      remainingTime -= 1
      if (remainingTime <= 0) {
        destroyInterval()
        countdownElement.style.width = '100%'
        setTimeout(() => hide(el, binding), 1000)
      } else {
        countdownElement.style.width = `${100 - remainingTime * step}%`
      }
    }, 1000)

    timeoutInstance = setTimeout(() => {
      destroyInterval()
      countdownElement.style.width = '100%'
      setTimeout(() => hide(el, binding), 1000)
    }, timeout)
  }
}

const create = (el, binding) => {
  destroyTimeout()

  if (el && binding) {
    if (popperInstance) {
      destroy(el, binding)
    }

    const value = get(el, '$tooltipOptions.value')
    const timeout = get(el, '$tooltipOptions.timeout')
    if (value) {
      binding.innerHTML = value
      binding.style.display = 'inline-block'
      binding.style.visibility = 'visible'
      binding.dataset.popperReferenceHidden = 'false'

      if (/\S{30,}/.test(value)) {
        binding.style.wordBreak = 'break-all'
      }

      if (timeout) {
        setupTimeout(el, binding, timeout)

        binding.addEventListener('mouseenter', () => {
          destroyTimeout()
          destroyInterval()
        })
        binding.addEventListener('mouseleave', () => {
          if (!timeoutInstance) {
            setupTimeout(el, binding, timeout)
          }
        })
      }
    }

    popperInstance = createPopper(el, binding, el.$tooltipOptions)
  }
}

function getOptionsFromBinding(binding) {
  const options = { modifiers: defaultOptions.modifiers }

  if (binding) {
    if (binding.value && typeof binding.value === 'string') {
      options.value = binding.value
    }

    if (binding.options && typeof binding.options === 'object') {
      const { placement, strategy, value, timeout, offsetDistance, offsetSkidding } = binding.options

      options.placement = placement || defaultOptions.placement
      options.strategy = strategy || defaultOptions.strategy

      if (value && typeof value === 'string') {
        options.value = value
      }
      if (timeout && typeof timeout === 'number') {
        options.timeout = timeout
      }

      if (offsetDistance || offsetSkidding) {
        options.modifiers.push({
          name: 'offset',
          options: {
            offset: [ offsetSkidding || 0, offsetDistance || 0 ]
          }
        })
      }
    }
  }

  return options
}

export default {
  install: Vue => {
    Vue.directive(
      'g-tooltip',
      {
        bind(el) {
          const showEvents = [ 'mouseenter', 'focus' ]
          const hideEvents = [ 'mouseleave', 'blur' ]

          const binding = document.getElementById('g-tooltip')
          const { timeout } = getOptionsFromBinding(binding)

          const showListener = () => create(el, binding)
          const hideListener = () => destroy(el, binding, timeout)

          showEvents.forEach(event => el.addEventListener(event, showListener))
          hideEvents.forEach(event => el.addEventListener(event, hideListener))
        },

        inserted(el, binding) {
          el.$tooltipOptions = getOptionsFromBinding(binding)
        },

        update(el, binding) {
          el.$tooltipOptions = getOptionsFromBinding(binding)
        },

        unbind(el) {
          destroy(el, document.getElementById('g-tooltip'))
          el.$tooltipOptions = undefined
        }
      }
    )
  }
}
