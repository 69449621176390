import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import OwnerSelect from '@/components/misc/OwnerSelect'
import DocumentTemplatesSelector from '@/components/services/documentTemplates/selector'

import { serviceName } from '@/components/services/companies/utils'

function renderTitleField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${serviceName}.labels.title`),
        mode: 'outline',
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}
function renderVatSwitch(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.vat,
        label: this.getTranslate(`${serviceName}.titles.vat`)
      },
      on: {
        input: event => {
          this.proxy.vat = event
        }
      }
    }
  )
}
function renderFirstRow(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: {
        'grid-template-columns': '1fr auto'
      }
    },
    [
      renderTitleField.call(this, h),
      renderVatSwitch.call(this, h)
    ]
  )
}

function renderDocumentButtons(h) {
  if (this.proxy.id) {
    return h(
      'div',
      {
        class: 'grid grid-cols--2 grid-gap--8'
      },
      DOCUMENT_TEMPLATES_TYPES.map(type => {
        return h(
          DocumentTemplatesSelector,
          {
            props: {
              CompanyId: this.proxy.id,
              type
            }
          }
        )
      })
    )
  }
}

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        dense: true,
        required: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8 pa-2',
      on: {
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      renderFirstRow.call(this, h),
      renderDocumentButtons.call(this, h),
      renderOwnerSelect.call(this, h)
    ]
  )
}
