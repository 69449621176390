import { get } from 'lodash'

import { backendServerIP } from '@/utils'

export default {
  namespaced: true,

  state: {
    methods: []
  },

  mutations: {
    setPaymentsMethods(state, methods) {
      state.methods = methods
    }
  },

  actions: {
    async getPaymentsMethods({ commit, rootState }) {
      try {
        const { authentication, account } = rootState
        const { token } = authentication

        const UserId = get(account, 'account.id')
        const headers = {}
        if (token) {
          headers.Authorization = token
        }

        if (UserId) {
          const query = new URLSearchParams({ UserId }).toString()
          const response = await fetch(`${backendServerIP}/api/payments/methods?${query}`, {
            method: 'get',
            headers
          })

          if (response.ok && response.status === 200) {
            const result = await response.json()
            commit('setPaymentsMethods', result)

            return result
          }
        }
      } catch (error) {
        console.error(error)
      }
    },

    setPaymentsMethods({ commit }, methods) {
      commit('setPaymentsMethods', methods)
    }
  },

  getters: {
    methods: state => state.methods
  }
}
