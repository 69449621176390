import { get } from 'lodash'

import { colors } from '@/utils'

import render from './render'

export default {
  name: 'Owner',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    label: String,

    copy: Boolean,

    pathToData: {
      type: String,
      default: 'Owner'
    }
  },

  computed: {
    Id() {
      return get(this.value, this.pathToData + 'Id')
    },
    id() {
      return get(this.value, this.pathToData.toLowerCase() + 'Id')
    },
    $id() {
      return this.Id || this.id
    },
    Owner() {
      return get(this.value, this.pathToData, this.Id)
    },
    owner() {
      return get(this.value, this.pathToData.toLowerCase(), this.id)
    },
    $owner() {
      return this.Owner || this.owner
    },
    hasOwner() {
      return !!this.$owner
    },

    $props() {
      const verified = get(this.$owner, '$verified', false)

      return {
        value: this.$owner,
        pathToTitle: 'username',
        label: this.label || this.getTranslate('commons.labels.owner'),
        iconSVG: verified ? 'user-verified' : 'user',
        iconTooltip: verified ? this.getTranslate('commons.has.contract') : undefined,
        color: verified ? colors.info : colors.grey,
        copy: this.copy
      }
    }
  },

  render
}
