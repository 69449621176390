import proxy from '@sigma-legacy-libs/g-proxy'

import { components } from '@/utils'
import { basic, core } from '@/components/g/input/mixins'

import render from './render'

export default {
  name: components.textarea,

  mixins: [ core('textarea'), proxy(), basic('textarea') ],

  props: {
    resize: {
      type: String,
      default: 'none',
      validator: value => {
        return !!~[
          'none',
          'both',
          'horizontal',
          'vertical'
        ].indexOf(value)
      }
    },

    grow: {
      type: Boolean,
      default: true
    }
  },

  mounted() {
    this._setRequired()
    this._autoFocus()
    this._delayedResize()
  },

  methods: {
    _resizeHeight() {
      if (this.grow) {
        const textarea = document.getElementById(this.$id)
        if (textarea) {
          let height = 'auto'
          if (this.rows) {
            height = 18 * this.rows
          }
          textarea.style.height = typeof height === 'number' ? height + 'px' : height

          let scrollHeight = textarea.scrollHeight
          if (scrollHeight) {
            if (this.rows === 1 && scrollHeight === 19) {
              scrollHeight = 18
            }
            textarea.style.height = typeof scrollHeight === 'number' ? scrollHeight + 'px' : scrollHeight
          }
        }
      }
    },

    _delayedResize() {
      this.$nextTick(this._resizeHeight)
    }
  },

  render
}
