import HeadTitle from '@/components/misc/HeadTitle'

import { services, states } from '@/utils'

import tokensForm from '@/components/services/tokens/form'

function renderHeader(h) {
  if (this.type) {
    return h(
      HeadTitle,
      {
        props: { value: this.getTranslate(`${services.tokens}.titles.${this.type}`) },
        slot: 'header'
      }
    )
  }
}

function renderBody(h) {
  switch (this.type) {
    case 'create': {
      return h(
        tokensForm,
        {
          props: {
            value: this.restData[services.tokens].create.data,
            OwnerId: this.OwnerId,
            isNew: true
          },
          on: {
            input: event => {
              this.restData[services.tokens].create.data = event
            },
            submit: () => {
              this.create()
            },
            validation: event => {
              this.restData[services.tokens].create.isValid = event
            }
          }
        }
      )
    }
    case 'update': {
      if (this.restData[services.tokens].get.state === states.ready) {
        return h(
          tokensForm,
          {
            props: {
              value: this.restData[services.tokens].get.data
            },
            on: {
              input: event => {
                this.restData[services.tokens].get.data = event
              },
              submit: () => {
                this.update()
              },
              validation: event => {
                this.restData[services.tokens].update.isValid = event
              }
            }
          }
        )
      }

      break
    }
    case 'remove': {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate(`${services.tokens}.contents.confirm.remove`)
      )
    }
  }
}
function renderFooter(h) {
  if (this.type) {
    return h(
      'g-card-actions',
      {
        class: 'fjcfe facfe',
        slot: 'footer'
      },
      [
        h(
          'g-button',
          {
            props: {
              label: this.getTranslate('misc.buttons.cancel'),
              flat: true,
              rounded: true,
              disabled: this.restData[services.tokens][this.type].state === states.loading
            },
            on: {
              click: () => {
                this.$emit('show', false)
              }
            }
          }
        ),

        h(
          'g-button',
          {
            props: {
              label: this.getTranslate(`misc.buttons.${this.type}`),
              rounded: true,
              depressed: true,
              loading: this.restData[services.tokens][this.type].state === states.loading,
              disabled: this.restData[services.tokens][this.type].state === states.loading
              || this.type !== 'remove' && !this.restData[services.tokens][this.type].isValid,
              color: this.type === 'remove' ? 'error' : 'primary'
            },
            on: {
              click: () => {
                this[this.type](services.tokens)
              }
            }
          }
        )
      ]
    )
  }
}

function renderContent(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.$emit('show', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  if (this.show) {
    return renderContent.call(this, h)
  }
}
