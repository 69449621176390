import proxy from '@sigma-legacy-libs/g-proxy'

import { RECURRENT_PAYMENTS_TYPES, TASK_FREQUENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'RecurrentPaymentsForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    validation() {
      let result = true

      if (!this.proxy.acceptOffer) {
        result = false
      }

      if (!this.proxy.method) {
        result = false
      }

      if (!this.proxy.amount || this.proxy.amount <= 0 || this.localErrors.amount) {
        result = false
      }

      if (this.proxy.type) {
        switch (this.proxy.type) {
          case RECURRENT_PAYMENTS_TYPES.threshold: {
            if (this.localErrors.threshold) {
              result = false
            }
            break
          }
          case RECURRENT_PAYMENTS_TYPES.frequency: {
            if (!this.proxy.frequency || !~TASK_FREQUENCY.indexOf(this.proxy.frequency)) {
              result = false
            }
            break
          }
        }
      } else {
        result = false
      }

      return result
    },

    localErrors() {
      const errors = {}
      const amount = parseFloat(this.proxy.amount)
      const threshold = parseFloat(this.proxy.threshold)
      if (isNaN(amount)) {
        errors.amount = this.getTranslate(`${services.recurrentPayments}.errors.amount.required`)
      } else {
        if (amount <= 0) {
          errors.amount = this.getTranslate(`${services.recurrentPayments}.errors.amount.min`)
        }
      }
      if (isNaN(threshold)) {
        errors.threshold = this.getTranslate(`${services.recurrentPayments}.errors.threshold.required`)
      } else {
        if (threshold <= 0) {
          errors.threshold = this.getTranslate(`${services.recurrentPayments}.errors.threshold.min`)
        }
      }

      return errors
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.proxy.acceptOffer = true
    this.proxy.type = RECURRENT_PAYMENTS_TYPES[0]
  },

  render
}
