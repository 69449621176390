import { cloneDeep, defaultsDeep } from 'lodash'

import { leadZero } from '@/utils'

export const timeParams = {
  $gt: '00:00:00',
  $lt: '23:59:59'
}

export const dateOptionsTime = {
  hour12: false,
  hour: '2-digit',
  minute: '2-digit'
}
export const dateOptionsTimeWithSeconds = {
  ...dateOptionsTime,
  second: '2-digit'
}
export const dateOptionsTimeWithMilliseconds = {
  ...dateOptionsTime,
  fractionalSecondDigits: 3
}

export function isSingleDate(value) {
  const dates = cloneDeep(value)
  if (dates.length === 2) {
    return dates.map(item => {
      item = new Date(item)

      return item.setHours(0, 0, 0, 0)
    }).reduce((a, b) => a === b)
  } else if (dates.length === 1) {
    return true
  }

  return false
}

export function getDiffRangeMs(data) {
  const d = cloneDeep(data)
  d.date.sort()
  const gt = d.date[0] ? new Date(d.date[0]) : new Date()
  const lt = d.date[1] ? new Date(d.date[1]) : new Date(gt)

  gt.setHours(...d.time.$gt.split(':').map(n => parseInt(n) || 0))
  lt.setHours(...d.time.$lt.split(':').map(n => parseInt(n) || 0))

  return lt.getTime() - gt.getTime()
}

export function getWeek() {
  const today = new Date()
  const day = today.getDay()
  const diff = today.getDate() - day + (day === 0 ? -6 : 1)

  const start = new Date(today.setDate(diff))
  start.setHours(0, 0, 0, 0)

  const end = new Date(start)
  end.setDate(end.getDate() + 6)
  end.setHours(23, 59, 59, 999)

  const previousStart = new Date(start)
  previousStart.setDate(previousStart.getDate() - 7)
  previousStart.setHours(0, 0, 0, 0)

  const previousEnd = new Date(end)
  previousEnd.setDate(previousEnd.getDate() - 7)
  previousEnd.setHours(23, 59, 59, 999)

  return {
    current: {
      start,
      end
    },
    previous: {
      start: previousStart,
      end: previousEnd
    }
  }
}

export function getMonth() {
  const today = new Date()
  const month = today.getMonth()
  const year = today.getFullYear()

  const start = new Date(year, month, 1)
  start.setHours(0, 0, 0, 0)
  const end = new Date(year, month + 1, 0)
  end.setHours(23, 59, 59, 999)

  const previousStart = new Date(year, month - 1, 1)
  previousStart.setHours(0, 0, 0, 0)
  const previousEnd = new Date(year, month, 0)
  previousEnd.setHours(23, 59, 59, 999)

  return {
    current: {
      start,
      end
    },
    previous: {
      start: previousStart,
      end: previousEnd
    }
  }
}

export function getToday() {
  const lt = new Date()
  const gt = new Date(lt)
  gt.setHours(0, 0, 0, 0)

  const result = {
    $lt: lt.toISOString(),
    $gt: gt.toISOString()
  }

  return result
}

export function getLastFiveMinutes() {
  const lt = new Date()
  const gt = new Date(lt)
  gt.setSeconds(gt.getSeconds() - 300)

  const result = {
    $lt: lt.toISOString(),
    $gt: gt.toISOString()
  }

  return result
}

export function getPastHammerTime(ms) {
  const date = new Date()

  if (ms) {
    date.setMilliseconds(date.getMilliseconds() - ms)
  }

  return date.toLocaleTimeString(this.locale, dateOptionsTimeWithSeconds)
}

export function getChosenDay($gt, $lt) {
  const gt = new Date()
  gt.setHours(0, 0, 0, 0)
  const lt = new Date(gt)
  if ($gt) {
    gt.setDate(gt.getDate() + $gt)
  }
  lt.setDate(lt.getDate() + 1)
  lt.setHours(0, 0, 0, 0)
  if ($lt) {
    lt.setDate(lt.getDate() + $lt)
  }

  return {
    $lt: lt.toISOString(),
    $gt: gt.toISOString()
  }
}
export function getChosenMonth($gt, $lt) {
  const gt = new Date()
  gt.setHours(0, 0, 0, 0)
  const lt = new Date(gt)
  if ($gt) {
    gt.setMonth(gt.getMonth() + $gt)
  }
  lt.setDate(lt.getDate() + 1)
  lt.setHours(0, 0, 0, 0)
  if ($lt) {
    lt.setMonth(lt.getMonth() + $lt)
  }

  return {
    $lt: lt.toISOString(),
    $gt: gt.toISOString()
  }
}

export function getHammerTime(date = new Date().setHours(0, 0, 0, 0)) {
  const parseDate = new Date(date)

  return [ leadZero(parseDate.getHours()), leadZero(parseDate.getMinutes()) ].join(':')
}

export function getISODate(date) {
  if (date) {
    const parseDate = new Date(date)

    return [
      parseDate.getFullYear(),
      leadZero(parseDate.getMonth() + 1),
      leadZero(parseDate.getDate())
    ].join('-')
  }
}

export function getUnix(date, inSeconds) {
  if (date) {
    const parseDate = new Date(date)
    if (parseDate) {
      if (inSeconds) {
        return parseInt((parseDate.getTime() / 1000).toString())
      }

      return parseInt(parseDate.getTime().toString())
    }
  }
}

export function getRUDate(date) {
  if (date) {
    const parseDate = new Date(date)

    return [
      leadZero(parseDate.getDate()),
      leadZero(parseDate.getMonth() + 1),
      parseDate.getFullYear()
    ].join('.')
  }
}

export function getLocaleDateString(
  date,
  locale = 'ru',
  options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
) {
  return new Date(date).toLocaleDateString(locale, options)
}
export function getLocaleTimeString(date, options = {}) {
  const params = {
    hour: options.hour || '2-digit',
    minute: options.minute || '2-digit',
    second: options.second || '2-digit'
  }
  const locale = options.locale || 'ru'
  const source = new Date(date)
  const time = source.toLocaleTimeString(locale, params)
  const ms = `${source.getMilliseconds()}`.padStart(3, '0')

  return options.milliseconds ? time + `.${ms}` : time
}

export function computeDateDate(plusDate = 0, relativeDate = new Date()) {
  return relativeDate.setDate(relativeDate.getDate() + plusDate)
}

export function getYesterday() {
  return computeDateDate(-1)
}
export function getTomorrow() {
  return computeDateDate(1)
}

export function computeDateHours(plusHours = 0, relativeDate = new Date()) {
  return relativeDate.setHours(relativeDate.getHours() + plusHours)
}

export function appendFormattedDates(object = {}, fieldsForFormatting = [ 'createdAt', 'updatedAt' ], options = {}) {
  for (let i = 0; i < fieldsForFormatting.length; i++) {
    if (object[fieldsForFormatting[i]]) {
      const parseDate = new Date(object[fieldsForFormatting[i]])
      let time = ''
      if (!options.noTime) {
        time += parseDate.toLocaleTimeString(this.locale, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })
      }
      if (!options.noTime && !options.noDate) {
        time += '\n'
      }
      if (!options.noDate) {
        time += parseDate.toLocaleDateString(this.locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      }
      object[`$formatted${fieldsForFormatting[i][0].toUpperCase() + fieldsForFormatting[i].substring(1)}`] = time
    }
  }

  return object
}

export function getUnixSeconds() {
  return parseInt((Date.now() / 1000).toString())
}
export function getUnixMilliseconds() {
  return parseInt(Date.now().toString())
}

export const getCreatedAtByPreset = preset => {
  const timeOptions = {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }

  const getTodayDates = (shift = 0) => {
    const date = new Date()

    return [ date.setHours(0, 0, 0, 0) - shift, date.setHours(23, 59, 59, 999) - shift ]
  }
  const getDatesWeek = (current = false) => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)

    let first = new Date(date.setDate(date.getDate() - date.getDay() - 6)).setHours(0, 0, 0, 0)
    let last = new Date(date.setDate(date.getDate() + 6)).setHours(23, 59, 59, 999)
    if (current) {
      first = new Date(date.setDate(date.getDate() - date.getDay() + 1)).setHours(0, 0, 0, 0)
      last = new Date(date.setDate(date.getDate() - date.getDay() + 7)).setHours(23, 59, 59, 999)
    }

    return [ first, last ]
  }
  const getDatesMonth = (current = false) => {
    const date = new Date()
    date.setHours(0, 0, 0, 0)

    let first = new Date(date.getFullYear(), date.getMonth() - 1, 1).setHours(0, 0, 0, 0)
    let last = new Date(date.getFullYear(), date.getMonth(), 0).setHours(23, 59, 59, 999)
    if (current) {
      first = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
      last = new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 999)
    }

    return [ first, last ]
  }
  const getHummerTime = date => {
    return date.toLocaleTimeString('ru-RU', timeOptions)
  }
  const getTime = () => {
    const result = {
      $gt: '00:00:00',
      $lt: '23:59:59'
    }
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    result.$gt = getHummerTime(date)
    date.setHours(23, 59, 59, 999)
    result.$lt = getHummerTime(date)

    return result
  }
  const getCurrentTime = (shift = 0) => {
    const date = new Date()
    const result = {
      $gt: getHummerTime(date),
      $lt: getHummerTime(date)
    }
    date.setMilliseconds(date.getMilliseconds() - shift)
    result.$gt = getHummerTime(date)

    return result
  }

  const shortPresets = {
    'minutes.5': {
      date: getTodayDates(),
      time: getCurrentTime(300000)
    },
    'minutes.15': {
      date: getTodayDates(),
      time: getCurrentTime(900000)
    },
    'minutes.30': {
      date: getTodayDates(),
      time: getCurrentTime(1800000)
    },
    'hours.1': {
      date: getTodayDates(),
      time: getCurrentTime(3600000)
    },
    'hours.3': {
      date: getTodayDates(),
      time: getCurrentTime(10800000)
    },
    'hours.6': {
      date: getTodayDates(),
      time: getCurrentTime(21600000)
    }
  }
  const longPresets = {
    yesterday: {
      date: getTodayDates(86400000),
      time: getTime()
    },
    today: {
      date: getTodayDates(),
      time: getTime()
    },
    'last.week': {
      date: getDatesWeek(),
      time: getTime()
    },
    'current.week': {
      date: getDatesWeek(true),
      time: getTime()
    },
    'last.month': {
      date: getDatesMonth(),
      time: getTime()
    },
    'current.month': {
      date: getDatesMonth(true),
      time: getTime()
    }
  }

  const generateCreatedAtFromPreset = value => {
    const { date, time } = value

    const $gt = date[0] ? new Date(date[0]) : new Date()
    const $lt = date[1] ? new Date(date[1]) : new Date($gt)

    $gt.setHours(...timeToArrayOfNumbers(time.$gt))
    $lt.setHours(...timeToArrayOfNumbers(time.$lt))

    return {
      $gt: $gt.toISOString(),
      $lt: $lt.toISOString()
    }
  }

  switch (preset) {
    case 'minutes.5':
    case 'minutes.15':
    case 'minutes.30':
    case 'hours.1':
    case 'hours.3':
    case 'hours.6': {
      return generateCreatedAtFromPreset(shortPresets[preset])
    }
    case 'yesterday':
    case 'today':
    case 'last.week':
    case 'current.week':
    case 'last.month':
    case 'current.month': {
      return generateCreatedAtFromPreset(longPresets[preset])
    }
  }
}

export const processFilter = (filter, params = {}, propsToRemove = [ 'createdAtPreset', '$scope' ]) => {
  filter = cloneDeep(filter)
  if (filter.createdAtPreset) {
    filter.createdAt = getCreatedAtByPreset(filter.createdAtPreset)

    for (const prop of propsToRemove) {
      delete filter[prop]
    }
  }

  for (const key in params) {
    const value = params[key]
    if (value !== undefined) {
      filter[key] = value
    }
  }

  defaultsDeep(filter, { meta: { user: {} } })

  return filter
}

export const timeToArrayOfNumbers = value => {
  return value.split(':').map((string, index) => {
    let number = parseInt(string) || 0
    if (index === 0 && number === 24) {
      number = 0
    }

    return number
  })
}
export const timeToArrayStringsWithLeadZero = value => {
  return timeToArrayOfNumbers(value).map(item => leadZero(item))
}

export const calculateDateRange = date => {
  const maxDateRange = 186 // Максимальный диапазон в днях
  const currentDate = new Date(date)

  // Рассчитываем минимальную дату (186 дней назад от выбранной даты)
  const min = new Date(currentDate)
  min.setDate(min.getDate() - maxDateRange)

  // Рассчитываем максимальную дату (186 дней вперед от выбранной даты)
  const max = new Date(currentDate)
  max.setDate(max.getDate() + maxDateRange)

  return {
    min,
    max
  }
}

export default {
  getToday,
  getChosenDay,
  getChosenMonth,
  getHammerTime,
  getISODate,
  getRUDate,
  getLocaleDateString,
  getLocaleTimeString,
  computeDateDate,
  computeDateHours,
  getYesterday,
  getTomorrow,
  getUnix,
  getUnixSeconds,
  getUnixMilliseconds,
  getCreatedAtByPreset,
  processFilter,
  timeToArrayOfNumbers,
  timeToArrayStringsWithLeadZero,
  calculateDateRange
}
